<template>
    <div class = "container">
  
      <h1 class = "text-center">Some Page 1</h1>
      <br/>
      <h3 class = "text-center"> Log-in token: {{$store.state.token}}</h3>
      <br/>
      <table class = "table table-striped">
        <tbody>
            <tr>
              <td><router-link to="/logon">Log On</router-link></td>
              <td><router-link to="/somePage2">Some Page 2</router-link></td>
            </tr>
        </tbody>
      </table>
      <br/>
      <table class = "table table-striped">
        <thead>
            <tr>
              <th>사업자등록번호</th>
              <th>사업자등록증</th>
            </tr>
        </thead>
        <tbody>
            <tr>
              <td><input type="text" v-model="CRN"></td>
              <td><input type="file" @change="fileHandling"/></td>
            </tr>
            <tr>
                <td></td>
                <td>
                    <div style="align-content:center;">
                        <button @click="upload()">Upload</button>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <img :src="imgfile"/>
                </td>
                <td>
                    <a :href="fileLink" download>파일 다운로드</a>
                </td>
            </tr>
        </tbody>
      </table>
      <br/>
      <table class = "table table-striped">
        <tbody>
            <tr>
                <td>
                    <input type="text" placeholder="새 비밀번호" v-model="newPassword"/>
                </td>
                <td>
                <div style="align-content:center;">
                    <button @click="changePassword()">비밀번호 변경</button>
                </div>
                </td>
            </tr>
        </tbody>
      </table>
      <br/>
      <table class = "table table-striped">
        <tbody>
            <tr>
                <td>
                    <div style="align-content:center;">
                        <button @click="logout()">Log out</button>
                    </div>
                </td>
                <td>
                    <div style="align-content:center;">
                        <button @click="membershipCancel()">탈퇴하기</button>
                    </div>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import Auth from "@/api/auth"
  import Api from "@/api";
  
  export default {
    name: 'SomePage',
    data(){
      return {
        fileLink: '',
        imgfile: null,
        CRN: '',
        file: null,
        newPassword: ''
      }
    },
    async mounted() {
      if(!await Auth.isLogedIn()){
        this.$router.push('/');
      }
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
      logout() {
        Auth.logOut();
        this.$router.push('/');
      },
      fileHandling(e) {
        this.file = e.target.files[0];
        console.log(this.file);
      },
      async upload() {
        let rtnval = await Api.postFiles("/api/v1/user/fileUpload", {CRN: this.CRN, file: this.file});
        console.log(rtnval);
        if(rtnval.result){
            var tokens = rtnval.data.ntnlOrgNm.split('.');
            var mimeType = tokens[tokens.length-1];
            var fileType = 'image/' + mimeType;
            this.imgfile = "data:"+fileType+";base64,"+rtnval.data.ntnlImg

            const byteCharacters = atob(rtnval.data.ntnlImg);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: fileType});
            this.fileLink = URL.createObjectURL(blob);
            this.imgfile = this.fileLink;
        }
      },
      async changePassword() {
        console.log( await Api.post("/api/v1/user/update/password", {email: this.user.email, authType: 'KAKAO', psswrd: this.newPassword}));
      },
      async membershipCancel() {
        let rtnval = await Api.post("/api/v1/user/delete/user", {id: this.user.id})
        if(rtnval.result){
            Auth.logOut();
            this.$router.push('/');
        }
      }
    },
    created() {
    }
  }
  </script>
  
  <style scoped>
  img {
    max-width: 50%;
    height: auto;
  }
  </style>