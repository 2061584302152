<template>
    <div>
        <div style="height:80px"></div>
        <div class="p-2"><span class="fkr-set2 fs-16 fc-w0"><strong>Start your own building</strong></span></div>
        <div class="x_div_center">
            <div class="iw-1 p-2">
                <div class="iw-3">
                    <a href="https://buildmarket.kr/plan" target="_blank">
                    <div class="if_mbox1 x_div_center p-4">
                        <div class="w-100">
                            <div class="fk-set1 fs-14"><strong>Get to Know</strong></div>
                            <div class="fk-set1 fs-095">Premium modular architecture one-stop service<br>Start now</div>
                        </div>
                        <div class="px-2"><i class="fa-solid fa-circle-right" style="font-size:2rem"></i></div>
                    </div>
                    </a>
                </div>
                <div class="iw-b"></div>
                <div class="iw-3">
                    <a href="https://buildmarket.kr/cs/inquiry" target="_blank">
                    <div class="if_mbox1 x_div_center p-4">
                        <div class="w-100">
                            <div class="fk-set1 fs-14"><strong>Contact us</strong></div>
                            <div class="fk-set1 fs-095">Ask anything. A professional consultant will provide personalized advice</div>
                        </div>
                        <div class="px-2"><i class="fa-solid fa-circle-right" style="font-size:2rem"></i></div>
                    </div>
                    </a>
                </div>
            </div>
        </div>
        <div style="height:80px"></div>
    </div>
</template>

<script>
export default {
    name: 'MenuFooter'
}
</script>