<template>
  <div class = "container">

    <h1 class = "text-center"> Log In</h1>
    <br/>
    <h3 class = "text-center"> Log-in token: {{$store.state.token}}</h3>

    <!-- <table class = "table table-striped">
      <thead>
      <tr>
        <th> col1</th>
        <th> col2</th>
      </tr>

      </thead>
      <tbody>
      <tr v-for="sample in samples" v-bind:key="sample.col1">
        <td> {{sample.col1 }}</td>
        <td> {{sample.col2 }}</td>
      </tr>
      </tbody>
    </table>
    <br/> -->
    <table class = "table table-striped">
      <thead>
      <tr>
        <th> email</th>
        <th> password</th>
        <th> </th>
      </tr>

      </thead>
      <tbody>
      <tr>
        <td> <input type="text" v-model="email"></td>
        <td> <input type="text" v-model="password" @keydown.enter="login()"></td>
        <td></td>
      </tr>
      <tr>
        <td> <input type="text" v-model="postCode">&nbsp;<button @click="searchAddr()">주소찾기</button> </td>
        <td> <input type="text" v-model="addr1">&nbsp;<input type="text" v-model="addr2"> </td>
        <td></td>
      </tr>
      <tr>
        <td>
          <select v-model="usrType">
            <option value="CU">고객</option>
            <option value="PA">파트너</option>
          </select>
        </td>
        <td>
          <select v-model="usrRoles">
            <option value="OW" v-if="usrType == 'CU'">발주자</option>
            <option value="DS" v-if="usrType == 'PA'">설계사</option>
            <option value="MF" v-if="usrType == 'PA'">제작사</option>
            <option value="CT" v-if="usrType == 'PA'">시공사</option>
            <!-- <option value="SN">감리사</option>
            <option value="CM">CM사</option>
            <option value="SL">영업사</option> -->
          </select>
        </td>
        <td>
          
        </td>
      </tr>
      <tr v-if="usrType == 'PA'">
        <td> 사업자등록증 </td>
        <td> 지명원 </td>
        <td> 자격증 </td>
      </tr>
      <tr v-if="usrType == 'PA'">
        <td><input type="file" @change="fileHandling($event, 0)"/></td>
        <td><input type="file" @change="fileHandling($event, 1)"/></td>
        <td><input type="file" @change="fileHandling($event, 2)"/></td>
      </tr>
      <tr>
        <td>
          <div style="align-content:center;">
            <button @click="login()">Log in</button>
          </div>
        </td>
        <td>
          <div style="align-content:center;">
            <button @click="signin()">Register</button>
          </div>
        </td>
        <td>
          <router-link to="/kakao">Kakao Log-in</router-link>
        </td>
      </tr>
      <tr>
        <td>
          <div style="align-content:center;">
            <button @click="setCookie()">set Cookie</button>
          </div>
        </td>
        <td>
          <div style="align-content:center;">
            <button @click="removeCookie()">remove Cookie</button>
          </div>
        </td>
        <td>
          <button @click="callApi()">call Api</button>
        </td>
      </tr>
      </tbody>
    </table>
    <div id="appleid-signin" class="signin-button" data-color="black" data-border="true" data-type="sign in"></div>
  </div>
</template>

<script>

// import SampleService from "@/services/SampleService";
import Api from "@/api";
import Auth from "@/api/auth"
import { mapMutations } from "vuex";

export default {
  name: 'MySamples',
  data(){
    return {
      email: '',
      password: '',
      samples: [],
      daumObj: null,
      postCode: '',
      addr1: '',
      addr2: '',
      usrType: 'CU',
      usrRoles: 'OW',
      file: []
    }
  },
  watch: {
    usrType(val) {
      if(val == 'PA'){
        this.usrRoles = 'DS'
      }
      else{
        this.usrRoles = 'OW'
      }
    }
  },
  computed: {
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    // getSamples() {
    //   SampleService.getSamples().then((response) => {
    //     this.samples = response.data;
    //   })
    // },
    async login() {
      var result = await Auth.logIn(this.email, this.password);
      console.log(result);
      if(result.result){
        if(this.$route.query.fromSuggestion){
          this.$router.push('/use-case/suggestion');
        }
        else{
          this.$router.push('/LogOn');
        }
      }
      else{
        alert(result.errormsg);
      }
    },
    async signin() {
      var request = {
        email: this.email,
        psswrd: this.password,
        oauthType: 'NONE',
        usrType: this.usrType,
        usrRoles: this.usrRoles,
      }
      const formData = new FormData();
      formData.append(
        "request",
        new Blob([JSON.stringify(request)], { type: "application/json" })
      );
      formData.append("bsnssImg", this.file[0]);
      formData.append("profImg", this.file[1]);
      formData.append("ntnlImg", this.file[2]);

      var result = await Api.postFiles('/api/v1/user/create', formData);
      if(result.result){
        alert('회원가입을 축하합니다!')
      }
      else{
        alert(result.errormsg);
      }
    },
    searchAddr() {
      this.daumObj.open();
    },
    fileHandling(e, index) {
        this.file[index] = e.target.files[0];
    },
    async appleLoginHandler() {
      try {
          const data = await window.AppleID.auth.signIn()
          console.log(data);
      } catch ( error ) {
          // Handle error.
      }
    },
    async setCookie() {
      var result = await Api.get('/api/v1/note/gen/cookie');
      if(result.result){
        console.log(result.data);
      }
      else{
        alert(result.errormsg);
      }
    },
    async removeCookie() {
      var result = await Api.get('/api/v1/note/del/cookie');
      if(result.result){
        console.log(result.data);
      }
      else{
        alert(result.errormsg);
      }
    },
    async callApi() {
      // var result = await Api.post('https://buildmarket.kr/api/v1/note/save', ["cookie key: test"]);
      var result = await Api.get('https://buildmarket.kr/api/v1/note/list');
      if(result.result){
        console.log(result.data);
      }
      else{
        alert(result.errormsg);
      }
    }
  },
  created() {
    console.log(process.env)
  },
  async mounted() {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    if(urlParams.has('code')){
      var rtnval = await Api.post('/api/v1/user/kakao/oauth/token', {code: urlParams.get('code')});
      if(rtnval.result){
        var rtnValue = await Api.post('/api/v1/user/kakao/oauth/user/me', {access_token: rtnval.data.access_token});
          var returnValue = await Api.post('/api/v1/user/kakao/oauth/login', {email: rtnValue.data.kakao_account.email, phone: rtnValue.data.kakao_account.phone_number, nickname: rtnValue.data.kakao_account.profile.nickname});
          if(returnValue.result){
            this.setToken(returnValue.data.token);
            this.setUserInfo({email: rtnValue.data.kakao_account.email, id: returnValue.data.id});
            window.close() 
          }
      }
    }
    if(await Auth.isLogedIn()){
      this.$router.push('/LogOn');
    }

    window.AppleID.auth.init({
        clientId : 'M3systems',
        scope : 'name email',
        redirectURI : 'https://buildmarket.kr',
        state : 'test',
        nonce : 'nonce',
        usePopup : true
    });

    document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    // Handle successful response.
        console.log(event.detail.authorization);
        this.appleLoginHandler();
    });

    // Listen for authorization failures.
    document.addEventListener('AppleIDSignInOnFailure', (event) => {
        // Handle error.
        console.log(event.detail.error);
    });

    this.daumObj = new window.daum.Postcode({
        oncomplete: (data) => {
          var addr = ''; // 주소 변수
          var extraAddr = ''; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
              addr = data.roadAddress;
          } else { // 사용자가 지번 주소를 선택했을 경우(J)
              addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if(data.userSelectedType === 'R'){
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
                extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraAddr !== ''){
                extraAddr = ' (' + extraAddr + ')';
            }
          }
          this.postCode = data.zonecode;
          this.addr1 = addr + extraAddr;
        }
      });
    // this.getSamples();
  }
}
</script>

<style scoped>
.signin-button {
    width: 183px;
    height: 45px;
}
</style>