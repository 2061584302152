<template>
    <div>
        <div class="dh_p2a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-if="lang == 'ko'">
                    <span class="dh_p1b">M3 파트너 소개</span><br>
                    <span class="dh_p1c">모듈러 건축 비즈니스를 바라보는 새로운 시각<br/>M3는 파트너의 성장을 지원합니다.</span>
                </div>
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-else-if="lang == 'en'">
                    <span class="dh_p1b">Introduction to M3 Partners</span><br>
                    <span class="dh_p1c">A New Perspective on Modular Architecture Business<br/>M3 supports partners' growth.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- 파트너 소개 -->
                <div class="w-100 py-3 pt-5" style="border-bottom:1px solid #e1e1e1">
                    <div v-if="lang == 'ko'">
                        <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>파트너 소개</strong></span></div>
                        <div class="text-left py-2"><span class="fkr-set2 fs-13"><strong>M3와 함께하는 파트너를 소개합니다.</strong></span></div>
                    </div>
                    <div v-else-if="lang == 'en'">
                        <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>About Partners</strong></span></div>
                        <div class="text-left py-2"><span class="fkr-set2 fs-13"><strong>Introducing our partners with M3.</strong></span></div>
                    </div>

                    <!-- 파트너기관 구분 탭 메뉴 -->
                    <div v-if="lang == 'ko'">
                        <div v-for="(item, index) in showListCtgry" :key="index" class="x_div_center" style="border:0px solid #e1e1e1">
                            <div v-for="(it, i) in item" :key="i" :class="'pa_mbox1' + (tap == it.id ? '_on' : '') + ' py-3 text-center bt_3 br_3 bb_3' + (i==0? ' bl_3' : '')" style="width:25%;" @click="tap = it.id"><span class="fkr-set2">{{ it.ctgryName? it.ctgryName : '&nbsp;' }}</span></div>
                        </div>
                    </div>

                    <div v-else-if="lang == 'en'">
                        <div v-for="(item, index) in showListCtgryEn" :key="index" class="x_div_center" style="border:0px solid #e1e1e1">
                            <div v-for="(it, i) in item" :key="i" :class="'pa_mbox1' + (tap == it.id ? '_on' : '') + ' py-3 text-center bt_3 br_3 bb_3' + (i==0? ' bl_3' : '')" style="width:33.3333%;" @click="tap = it.id"><span class="fkr-set2">{{ it.ctgryNameEn? it.ctgryNameEn : '&nbsp;' }}</span></div>
                        </div>
                    </div>

                    <div v-for="(item, i) in showListPartners" :key="i" class="sw-1 py-2">
                        <div v-for="(jtem, j) in item" :key="j" class="sw-2">
                            <div v-for="(ktem, k) in jtem" :key="k" class="sw-2">
                                <a :href="ktem.hmpg? ktem.hmpg : 'javascript:void(0)'" :target="ktem.hmpg? '_blank' : ''">
                                    <div class="p-4">
                                        <div class="x_div_center">
                                            <img v-if="lang == 'ko'" :src="ktem.logoFl.attchWebPath? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + ktem.logoFl.attchWebPath : ''" width="133">
                                            <img v-else-if="lang == 'en'" :src="ktem.logoFl.attchWebPath? ('https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + (ktem.logoFlEn? ktem.logoFlEn.attchWebPath : ktem.logoFl.attchWebPath)) : ''" width="133">
                                        </div>
                                        <div class="x_div_center py-3" v-if="lang == 'ko'"><span class="fkr-set1 fs-095">{{ ktem.companyName }}</span></div>
                                        <div class="x_div_center py-3" v-else-if="lang == 'en'"><span class="fkr-set1 fs-095">{{ ktem.companyNameEn }}</span></div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>                    
                </div>

                <div style="height:80px"></div>

                <div style="height:50px"></div>

            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api'

export default {
    name: 'MenuPartners',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    data() {
        return {
            tap: 0,
            fetching: false,
            ctgryList: [],
            partnerList: [],
            showListCtgry: [[]],
            showListCtgryEn: [[]],
            showListPartners: [[[]]],
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET
        }
    },
    watch: {
        async tap(val) {
            await this.fetchPartners(true, val == 0? null : val);
            this.showListPartners = this.renderListPartners(this.partnerList);
        }
    },
    async mounted() {
        this.fetching = true;
        const rtnval = await Api.get('https://buildmarket.kr/api/v1/partners/ctgry/list');
        if(rtnval.result) {
            this.ctgryList = rtnval.data;
            this.ctgryList = [{id: 0, ctgryName: '전체', ctgryNameEn: 'All', crtDt: null}].concat(this.ctgryList);
            await this.fetchPartners(true, null);
            this.showListCtgry = this.renderListCtgry(this.ctgryList, 4);
            this.showListCtgryEn = this.renderListCtgry(this.ctgryList, 3);
            this.showListPartners = this.renderListPartners(this.partnerList);
        }
        else {
            alert(rtnval.errormsg);
        }
        this.fetching = false;
    },
    methods: {
        async fetchPartners(asc, ctgryId) {
            this.partnerList = [];
            const returnvalue = await Api.post('https://buildmarket.kr/api/v1/partners/partner/list', {asc: asc, ctgryId: ctgryId});
            if(returnvalue.result) {
                returnvalue.data.forEach((data) => {
                    var exists = false;
                    this.partnerList.forEach((partner) => {
                        if(partner.companyName == data.companyName) {
                            exists = true;
                            return;
                        }
                    })
                    if(!exists) {
                        this.partnerList.push(data);        
                    }
                })
            }
            else {
                alert(returnvalue.errormsg);
            }
        },
        renderListCtgry(fetchedList, chunk) {
            var showList = [];
            var row = [];
            for(var j=0; j < fetchedList.length; j++) {
                row.push(fetchedList[j])
                if(j%chunk == chunk-1) {
                    showList.push(row);
                    row = [];
                }
            }
            while(j%chunk != 0) {
                row.push({id: null, name: null, nameEn: null, fileLink: null, hmpg: null})
                j++
            }
            showList.push(row);

            return showList;
        },
        renderListPartners(fetchedList) {
            var showList = [];
            var how = [];
            var row = [];
            for(var j=0; j < fetchedList.length; j++) {
                row.push(fetchedList[j])
                if(j%4 == 1) {
                    how.push(row);
                    row = [];
                }
                else if(j%4 == 3) {
                    how.push(row);
                    showList.push(how);
                    row = [];
                    how = []
                }
            }
            while(j%4 != 0) {
                row.push({id: null, companyName: null, companyNameEn: null, logoFl: {attchWebPath: null}, logoFlEn: {attchWebPath: null}, hmpg: null})
                if(j%4 == 1) {
                    how.push(row);
                    row = [];
                }
                else if(j%4 == 3) {
                    how.push(row);
                    showList.push(how);
                    row = [];
                    how = []
                }
                j++
            }
            return showList;
        },
    }
}
</script>