import Api from "./index"
import store from "@/store"

const partnerRoleTypes = ['DS', 'MF', 'CT', 'IN'];

async function logIn(email, password) {
    const result = await Api.post('/api/v1/user/login', {email: email, psswrd: password});
    if(result.result){
        store.commit('setToken', result.data.token);
        localStorage.setItem("loginFlag", 1);
        const rtnval = await Api.get('/api/v1/user/find/usr/info');
        if(rtnval.result){
            store.commit('setUserInfo', {id: result.data.id, info: rtnval.data})
            return {result: true, msg: 'log in success!'};
        }
    }
    else{
        return {result: false, errorcode: result.errorcode, errormsg: result.errormsg};
    }
}

async function isLogedIn() {
    // if(!store.state.token){
    //     return false;
    // }
    // else{
        const result = await Api.get('/api/v1/user/find/usr/info');
        if(result.result){
            if(result.data.usrRole == 'M3') {
                result.data.usrRole = 'OW'
            }
            store.commit('setUserInfo', {id: store.state.user.id, info: result.data})
            return true;
        }
        else{
            const loginFlag = localStorage.getItem("loginFlag");
            if(loginFlag) {
                alert('로그인 세션이 만료되었습니다.\n다시 로그인해주세요.');
                localStorage.removeItem("loginFlag");
            }
            return false;
        }
    // }
}

async function logOut() {
    store.state.token = '';
    localStorage.removeItem('vuex');
    localStorage.removeItem("loginFlag");
    sessionStorage.clear();
    await Api.get('/api/v1/user/logout');
}

export default {logIn, isLogedIn, logOut, partnerRoleTypes};