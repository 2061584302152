<template>
    <div>
        <div class="dh_p3a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">Service introduction</span><br>
                    <span class="dh_p1c">With digital and modular technology, we offer premium modular architecture services for you.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- 서비스 소개 -->
                <div class="py-3 pt-5" style="border-bottom:1px solid #e1e1e1">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">

                            <!-- 간편 맞춤 서비스 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/spl2_1.png" height="120"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Easy-to-use customized service</span></div>
                                    <div class="text-center"><span class="fkr-set2">Assembling various architectural modules, create your design plan.</span></div>
                                    <div class="text-center py-3"><a href="#v1"><img src="@/assets/img/s_plan/s_arrow_d.png" width="50"></a></div>
                                </div>
                            </div>
                            <!-- 설계 서비스 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/spl2_2.png" height="120"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Design service</span></div>
                                    <div class="text-center"><span class="fkr-set2 pb-2">With professional support, save your effort and time for design.</span></div>
                                    <div class="text-center py-3"><a href="#v2"><img src="@/assets/img/s_plan/s_arrow_d.png" width="50"></a></div>
                                </div>
                            </div>

                        </div>
                        <div class="iw-3 d-flex" style="align-items:start;">

                            <!-- 시공 서비스 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/spl2_3.png" height="120"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Construction service</span></div>
                                    <div class="text-center"><span class="fkr-set2 pb-2">When the design is completed, we'll carry on construction safely with reasonable cost</span></div>
                                    <div class="text-center py-3"><a href="#v3"><img src="@/assets/img/s_plan/s_arrow_d.png" width="50"></a></div>
                                </div>
                            </div>
                            <!-- 유지보수 서비스 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/spl2_4.png" height="120"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Maintenance service</span></div>
                                    <div class="text-center"><span class="fkr-set2 pb-2">After the construction is completed, there will be no inconvenience. We will provide maintenance support.</span></div>
                                    <div class="text-center py-3"><a href="#v4"><img src="@/assets/img/s_plan/s_arrow_d.png" width="50"></a></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div style="height:80px"></div>

                <!-- 간편 맞춤 서비스 -->
                <div id="v1" style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w10"><strong>Planning service</strong></span></div>
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-13"><strong>Realistic personal architecture</strong></span></div>
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-5 align-items-center">
                            <div class="p-3">
                                <div class="pl2_bx">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Finding a building that's perfect for you</span></div>
                                        <div class="py-3"><span class="fkr-set2">Based on intelligent algorithms and accumulated information, we automatically recommend feasible designs by analyzing the taste of client.</span></div>
                                    </div>
                                </div>
                                <div class="pl2_bl"></div>
                                <div class="pl2_bx">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Site and legal review</span></div>
                                        <div class="py-3"><span class="fkr-set2">By automatically conducting a preliminary review of the site and regulations, you can quickly determine the possibility of construction.</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="iw-5 align-items-center">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/spl2_5.png" width="100%"></div>
                            </div>
                        </div>
                        <div class="iw-5 align-items-center">
                            <div class="p-3">
                                <div class="pl2_br">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Provide a design plan that meets the requirements</span></div>
                                        <div class="py-3"><span class="fkr-set2">If you choose the size of the building and various functions, we will automatically generate a design that meets all requirements.</span></div>
                                    </div>
                                </div>
                                <div class="pl2_bl"></div>
                                <div class="pl2_br">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Total predicted cost</span></div>
                                        <div class="py-3"><span class="fkr-set2">We provide possible designs within a preset budget, and you can predict and compare total construction costs. You can also adjust the details to meet your budget.</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div id="v2" class="x_div_center py-4" style="background-color:#000137">

            <!-- 설계 서비스 -->
            <div class="rp_set">
                <!--div class="x_div_center"><span class="fkr-set2 fs-16 fc-w10"><strong>설계 서비스</strong></span></div>
                <div class="x_div_center px-3 py-2"><span class="fkr-set2 fs-13 fc-w1"><strong>전문가와 함께하는 설계</strong></span></div-->
                <div class="iw-1 py-2 w-100 align-items-center">
                    <div class="iw-3 align-items-center">
                        <div class="p-3">
                            <div class="pl2_br">
                                <div>
                                    <div class="text-left"><span class="fkr-set2 fs-16 fc-w10"><strong>Design service</strong></span></div>
                                    <div class="text-left"><span class="fkr-set1 fs-20 fc-w1">Design with experts</span></div>
                                    <div class="py-3 text-left"><span class="fkr-set2 fc-w1">Based on the optimized architectural plan presented as a simple customized service, our service provides a plan at the level of the approved drawing included design proposal according to plan, elevation, cross-sectional plan, perspective view, layout.</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="iw-3 align-items-center">
                        <div class="p-2">
                            <div class="x_div_center">
                                <div class="p-2 w-50"><a href="#v-s1" data-toggle="modal"><img src="@/assets/img/s_plan/spl2_ss1.jpg" width="100%" height="180" style="border-radius:8px"></a></div>
                                <div class="p-2 w-50"><a href="#v-s2" data-toggle="modal"><img src="@/assets/img/s_plan/spl2_ss2.jpg" width="100%" height="180" style="border-radius:8px"></a></div>
                            </div>
                            <div class="x_div_center">
                                <div class="p-2 w-50"><a href="#v-s3" data-toggle="modal"><img src="@/assets/img/s_plan/spl2_ss3.jpg" width="100%" height="180" style="border-radius:8px"></a></div>
                                <div class="p-2 w-50"><a href="#v-s4" data-toggle="modal"><img src="@/assets/img/s_plan/spl2_ss4.jpg" width="100%" height="180" style="border-radius:8px"></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 1 -->
            <div id="v-s1" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="w-100">
                            <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w1">Design service</span></div>
                                <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                            </div>

                            <div><img src="@/assets/img/s_plan/spl2_ss1.jpg" width="100%"></div>

                        </div>

                    </div>
                </div>
            </div>

            <!-- 2 -->
            <div id="v-s2" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="w-100">
                            <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w1">Design service</span></div>
                                <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                            </div>

                            <div><img src="@/assets/img/s_plan/spl2_ss2.jpg" width="100%"></div>

                        </div>

                    </div>
                </div>
            </div>

            <!-- 3 -->
            <div id="v-s3" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="w-100">
                            <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w1">Design service</span></div>
                                <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                            </div>

                            <div><img src="@/assets/img/s_plan/spl2_ss3.jpg" width="100%"></div>

                        </div>

                    </div>
                </div>
            </div>

            <!-- 4 -->
            <div id="v-s4" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="w-100">
                            <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w1">Design service</span></div>
                                <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                            </div>

                            <div><img src="@/assets/img/s_plan/spl2_ss4.jpg" width="100%"></div>

                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div style="height:50px"></div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- 시공 서비스 -->
                <div id="v3" style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w10"><strong>Construction service</strong></span></div>
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-13"><strong>DfMA platform-based factory production and installation</strong></span></div>
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-5 align-items-center">
                            <div class="p-3">
                                <div class="pl2_bx">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Construction cost maintenance</span></div>
                                        <div class="py-3"><span class="fkr-set2">Contracts are carried out for each process and material.<br>We will manage it so that the construction cost does not rise and is maintained.</span></div>
                                    </div>
                                </div>
                                <div class="pl2_bl"></div>
                                <div class="pl2_bx">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Modular manufacturing management</span></div>
                                        <div class="py-3"><span class="fkr-set2">Construction project manager with sufficient experience and knowledge between the modular manufacturing plant and the installation site through the production managemetn method. We will take care of it carefully.</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="iw-5 align-items-center">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/spl2_7.png" width="100%"></div>
                            </div>
                        </div>
                        <div class="iw-5 align-items-center">
                            <div class="p-3">
                                <div class="pl2_br">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Construction delay prevention</span></div>
                                        <div class="py-3"><span class="fkr-set2">Through a thorough and clear contract with the contractor, we will prevent delays in construction.</span></div>
                                    </div>
                                </div>
                                <div class="pl2_bl"></div>
                                <div class="pl2_br">
                                    <div>
                                        <div><span class="fkr-set1 fs-11">Prevention of construction disputes</span></div>
                                        <div class="py-3"><span class="fkr-set2">We monitor the construction status and professional guides, participate in all construction processes
        together with the owner to protect them.</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

                <!-- 하자보수 서비스 -->
                <div id="v4" style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w10"><strong>Defect repair service</strong></span></div>
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-13"><strong>Repair of all defects in modular buildings</strong></span></div>

                    <div class="x_div_center">

                        <section class="main-card">
                            <div class="card-content">
                                <div class="content-left">
                                    <img v-if="select == 1" class="full-img" src="@/assets/img/s_plan/spl2_af1.png">
                                    <img v-if="select == 2" class="full-img" src="@/assets/img/s_plan/spl2_af2.png">
                                    <img v-if="select == 3" class="full-img" src="@/assets/img/s_plan/spl2_af3.png">
                                    <img v-if="select == 4" class="full-img" src="@/assets/img/s_plan/spl2_af4.png">
                                </div>
                                <div class="content-right">
                                    <!--div><span class="fkr-set1 fs-11">모듈러 건물의 모든 하자보수</span></div-->
                                    <div class="py-3"><span class="fkr-set2 fs-095">If a defect occurs within a certain period of time after construction,<br>the contractor shall proceed with the repair promptly.<br>In the event of a dispute over defects or failure to repair defects <br>in M3 Systems, we will promptly support you to deal with the contract legally.</span></div>
                                    <div class="mini-imgs">
                                        <img class="mini-img" src="@/assets/img/s_plan/spl2_af1.png" @click="select = 1">
                                        <img class="mini-img" src="@/assets/img/s_plan/spl2_af2.png" @click="select = 2">
                                        <img class="mini-img" src="@/assets/img/s_plan/spl2_af3.png" @click="select = 3">
                                        <img class="mini-img" src="@/assets/img/s_plan/spl2_af4.png" @click="select = 4">
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                </div>

                <MenuFooter/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooterEn.vue'
export default {
    name: 'MenuService',
    components: {
        MenuFooter
    },
    data() {
        return {
            select: 1
        }
    }
}
</script>