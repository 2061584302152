<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-if="lang == 'ko'">
                    <span class="dh_p1b">임원진 소개</span><br>
                    <span class="dh_p1c">M3systems 를 이끌어 갈 핵심 임원진을 소개합니다.</span>
                </div>
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-else-if="lang == 'en'">
                    <span class="dh_p1b">Executive Team Introduction</span><br>
                    <span class="dh_p1c">Here are some of the key executives who will lead M3systems.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- 임원진소개 pc -->
                <div class="wr1_p py-3 pt-5" style="border-bottom:1px solid #e9e9e9">
                    <div class="">
                        <span class="fkr-set2 fs-16 fc-w10">
                            <strong v-if="lang == 'ko'">임원진소개</strong>
                            <strong v-else-if="lang == 'en'">Executive Team Introduction</strong>
                        </span>
                    </div>
                    <div>
                        <div v-for="(bucket, i) in showListFullSpecs" :key="i" class="iw-1 py-2 w-100">
                            <div v-for="(member, j) in bucket" :key="j" class="iw-5">
                                <a href="javascript:void(0)" @click="openProfile(member)" width="100%">
                                    <div v-if="member.thumbnailWebPath" class="p-4" width="100%">
                                        <div class="x_div_center" width="100%"><img :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + member.thumbnailWebPath" height="300px"></div>
                                        <div class="x_div_center py-3">
                                            <span v-if="lang == 'ko'" class="fkr-set1 fs-11">{{ member.memberName.split('').join(' ') + (member.memberTitle? ' | ' + member.memberTitle : '') }}</span>
                                            <span v-else-if="lang == 'en'" class="fkr-set1 fs-11">{{ member.memberNameEn + (member.memberTitleEn? ' | ' + member.memberTitleEn : '') }}</span>
                                            <i style="margin-left:8px" class="fas fa-external-link-alt"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div v-for="(bucket, k) in showListShortSpecs" :key="k" class="iw-1 py-2 w-100">
                            <div v-for="(member, l) in bucket" :key="l" class="iw-5">
                                <a href="javascript:void(0)" @click="openProfile(member)">
                                    <div v-if="member.memberName" class="p-4">
                                        <div class="x_div_center py-3">
                                            <span v-if="lang == 'ko'" class="fkr-set1 fs-11">{{ member.memberName.split('').join(' ') + (member.memberTitle? ' | ' + member.memberTitle : '') }}</span>
                                            <span v-else-if="lang == 'en'" class="fkr-set1 fs-11">{{ member.memberNameEn + (member.memberTitleEn? ' | ' + member.memberTitleEn : '') }}</span>
                                            <i style="margin-left:8px" class="fas fa-external-link-alt"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 임원진소개 (모바일) -->
                <div class="wr1_m py-3 pt-5" style="border-bottom:1px solid #e9e9e9">
                    <div class="">
                        <span class="fkr-set2 fs-16 fc-w10">
                            <strong v-if="lang == 'ko'">임원진소개</strong>
                            <strong v-else-if="lang == 'en'">Executive Team Introduction</strong>
                        </span></div>
                    <div>
                        <div v-for="(bucket, m) in showListFullSpecsMobile" :key="m" class="iw-1 py-2 x_div_center">
                            <div v-for="(member, n) in bucket" :key="n" class="iw-3">
                                <a href="javascript:void(0)" @click="openProfile(member)" width="100%">
                                    <div v-if="member.thumbnailWebPath" class="p-2" width="100%">
                                        <div class="x_div_center" width="100%"><img :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + member.thumbnailWebPath" height="131px"></div>
                                        <div class="x_div_center py-3">
                                            <span v-if="lang == 'ko'" class="fkr-set1 fs-08">{{ member.memberName.split('').join(' ') + (member.memberTitle? ' | ' + member.memberTitle : '') }}</span>
                                            <span v-else-if="lang == 'en'" class="fkr-set1 fs-08">{{ member.memberNameEn + (member.memberTitleEn? ' | ' + member.memberTitleEn : '') }}</span>
                                            <i style="margin-left:8px" class="fas fa-external-link-alt"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div v-for="(bucket, o) in showListShortSpecsMobile" :key="o" class="iw-1 py-2 x_div_center">
                            <div v-for="(member, p) in bucket" :key="p" class="iw-3">
                                <a href="javascript:void(0)" @click="openProfile(member)">
                                    <div v-if="member.memberName" class="p-2">
                                        <div class="x_div_center py-3">
                                            <span v-if="lang == 'ko'" class="fkr-set1 fs-08">{{ member.memberName.split('').join(' ') + (member.memberTitle? ' | ' + member.memberTitle : '') }}</span>
                                            <span v-else-if="lang == 'en'" class="fkr-set1 fs-08">{{ member.memberNameEn + (member.memberTitleEn? ' | ' + member.memberTitleEn : '') }}</span>
                                            <i style="margin-left:8px" class="fas fa-external-link-alt"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Profile Modal { -->
                <div id="profile" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:10%">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="w-100">
                                <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                    <div class="w-100 p-3 ps-4">
                                        <span v-if="lang == 'ko'" class="fkr-set1 fs-11 fc-w1">{{ memberName.split('').join(' ') + (memberTitle? ' | ' + memberTitle : '') }}</span>
                                        <span v-else-if="lang == 'en'" class="fkr-set1 fs-11 fc-w1">{{ memberNameEn + (memberTitleEn? ' | ' + memberTitleEn : '') }}</span>
                                    </div>
                                    <div class="text-end pe-4" style="width:100px"><i style="cursor:pointer" @click="closeModal()" class="fa-solid fa-xmark fs-15 fc-w1"></i></div>
                                </div>

                                <div v-if="picturePath" style="display:flex; justify-content: space-between; align-items: flex-end; background-color:#E7E6E6">
                                    <div :style="'display:grid; margin:' + (isMobile? '20px' : '25px')">
                                        <span v-if="lang == 'ko'" :class="'fkr-set1 fs-' + (isMobile? '10' : '20')">{{ memberName.split('').join(' ') + (memberTitle? ' | ' + memberTitle : '') }}</span>
                                        <span v-else-if="lang == 'en'" :class="'fkr-set1 fs-' + (isMobile? '10' : '20')">{{ memberNameEn + (memberTitleEn? ' | ' + memberTitleEn : '') }}</span>
                                        
                                        <span v-if="lang == 'ko'" :class="'fkr-set1 fs-' + (isMobile? '9' : '13')">{{ memberSummary }}</span>
                                        <span v-else-if="lang == 'en'" :class="'fkr-set1 fs-' + (isMobile? '9' : '13')">{{ memberSummaryEn }}</span>
                                    </div>
                                    <img :src="picturePath" width="50%">
                                </div>

                                <div v-if="memberDesc" class="x_div_center py-2 px-4">
                                    <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                                        <div style="line-height:180%">
                                            <span v-if="lang == 'ko'" class="fkr-set2">{{ memberDesc }}</span>
                                            <span v-else-if="lang == 'en'" class="fkr-set2">{{ memberDescEn }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="careerList.length" class="x_div_center py-2 px-4">
                                    <div class="w-100 py-3" style="border-bottom:1px solid #c9c9c9">
                                        <div style="line-height:180%">
                                            <ul>
                                                <li v-for="(career, index) in (lang == 'ko'? careerList : careerListEn)" :key="index" class="fkr-set2">{{ career.cntnt }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div style="height:20px"></div>

                            </div>

                        </div>
                    </div>
                </div>

                <MenuFooter/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import Api from '@/api'

export default {
    name: 'MenuMembers',
    components: {
        MenuFooter
    },
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    data() {
        return {
            showListFullSpecs: [[]],
            showListShortSpecs: [[]],
            showListFullSpecsMobile: [[]],
            showListShortSpecsMobile: [[]],
            memberName: '',
            memberNameEn: '',
            memberTitle: null,
            memberTitleEn: null,
            memberSummary: null,
            memberSummaryEn: null,
            memberDesc: null,
            memberDescEn: null,
            careerList: [],
            careerListEn: [],
            picturePath: null,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET,
            buildMode: process.env.NODE_ENV,
            isMobile: false
        }
    },
    async mounted() {
        this.isMobile = window.innerWidth < 768;

        const rtnval = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/member/list');
        if(rtnval.result) {
            var fullSpecList = rtnval.data.filter((item) => item.thumbnailWebPath != null).sort((a,b) => a.orderInPage - b.orderInPage);
            var shortSpecList = rtnval.data.filter((item) => item.thumbnailWebPath == null).sort((a,b) => b.orderInPage - a.orderInPage);
            this.showListFullSpecs = this.renderList(fullSpecList, 3);
            this.showListShortSpecs = this.renderList(shortSpecList, 3);
            this.showListFullSpecsMobile = this.renderList(fullSpecList, 2);
            this.showListShortSpecsMobile = this.renderList(shortSpecList, 2);
        }
        else {
            alert(rtnval.errormsg);
        }
    },
    methods: {
        renderList(fetchedList, chunk) {
            var showList = [];
            var row = [];
            for(var j=0; j < fetchedList.length; j++) {
                row.push(fetchedList[j])
                if(j%chunk == chunk-1) {
                    showList.push(row);
                    row = [];
                }
            }
            while(j%chunk != 0) {
                row.push({thumbnailWebPath: null, memberName: null, memberNameEn: null, memberTitle: null, memberTitleEn: null})
                j++
            }
            showList.push(row);
            return showList;
        },
        async openProfile(member) {
            const rtnval = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/member/get?memberId=' + member.memberId);
            if(rtnval.result) {
                this.memberName = rtnval.data.memberName;
                this.memberNameEn = rtnval.data.memberNameEn;
                this.memberTitle = rtnval.data.memberTitle;
                this.memberTitleEn = rtnval.data.memberTitleEn;
                this.memberSummary = rtnval.data.memberSummary;
                this.memberSummaryEn = rtnval.data.memberSummaryEn;
                this.memberDesc = rtnval.data.memberDesc;
                this.memberDescEn = rtnval.data.memberDescEn;
                var careerListRaw = rtnval.data.memberCareer? rtnval.data.memberCareer.split(';') : [];
                this.careerList = [];
                careerListRaw.forEach((item) => {
                    this.careerList.push({cntnt: item});
                })
                var careerListEnRaw = rtnval.data.memberCareerEn? rtnval.data.memberCareerEn.split(';') : [];
                this.careerListEn = [];
                careerListEnRaw.forEach((item) => {
                    this.careerListEn.push({cntnt: item});
                })
                this.picturePath = rtnval.data.thumbnailWebPath? 'https://s3.' + this.s3region + '.amazonaws.com/' + this.s3bucket + '/' + rtnval.data.thumbnailWebPath : null;
                window.$('#profile').modal('show');
            }
            else {
                alert(rtnval.errormsg);
            }
        },
        closeModal() {
            window.$('.modal').modal('hide');
        },
    }
}
</script>