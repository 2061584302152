<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">NEWS</span><br>
                    <span class="dh_p1c">{{ lang == 'ko'? 'M3의 최신 소식을 알려 드립니다.' : 'Latest news of our company'}}</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">
                <div class="py-3 pt-5">
                    <div class="" style="border-bottom:1px solid #e9e9e9"><span class="fkr-set2 fs-16"><strong>{{ lang == 'ko'? '뉴스' : 'NEWS'}}</strong></span></div>
                    <div v-for="(item, index) in showList" :key="index">
                        <div class="iw-1 py-2 w-100">
                            <div class="iw-5" v-for="(news, i) in item" :key="i">
                                <a v-if="news" :href="news.link" target="_blank" style="width: 100%;">
                                <div class="p-4">
                                    <div class="x_div_center" style="overflow-x: hidden;"><img :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + news.thumbnailFlPath" height="250" style="border:1px solid #e9e9e9; width: auto;"></div>
                                    <div class="py-3">
                                        <div><span class="fkr-set1 fs-09">{{ news.title }}&nbsp;&nbsp;({{ news.publisher }}&nbsp;&nbsp;{{ news.publishedDate.split('T')[0] }})</span></div>
                                    </div>
                                </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 페이징 -->
                <div style="border-top:1px solid #e9e9e9">
                    <div class="x_div_center py-3" v-if="!fetching">
                        <a class="px-2" @click="switchPage(-1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-left"></i></a>
                        <a class="px-2" @click="switchPage(0)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-left"></i></a>
                        <span v-if="pageQueue[0] > 1" class="fen-set2" style="cursor:pointer; margin-right: 10px">...</span>
                        <a v-for="index in pageQueue" :key="index" class="px-2" @click="currentPage = index; renderList();" style="margin-right: 10px"><span :class="'fen-set' + (currentPage == index? '1' : '2')" style="cursor:pointer;">{{ index }}</span></a> 
                        <span v-if="pageQueue[pageQueue.length-1] < numOfPage" style="cursor:pointer;" class="fen-set2">...</span>
                        <a class="px-2" @click="switchPage(1)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angle-right"></i></a>
                        <a class="px-2" @click="switchPage(2)" style="cursor:pointer; margin-right: 10px;"><i class="fa-solid fa-angles-right"></i></a>
                    </div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>
            </div>
        </div>
    </div>
</template>

<script>
const PAGESIZE = 9

import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
import Api from '@/api'
export default {
    name: 'MenuNews',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            fetching: true,
            pageQueue: [],
            currentPage: 1,
            numOfPage: 0,
            searchFilter: '',
            filteredList: [],
            showList: [[]],
            newsList: [],
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET
        }
    },
    async mounted() {
        await this.fetchNews();
        this.setPaging();
        this.renderList();
    },
    methods: {
        async fetchNews() {
            this.fetching = true;
            this.newsList = [];
            this.ctgryList = [];
            const returnvalue = await Api.get('https://buildmarket.kr/api/v1/news/news/list');
            if(returnvalue.result) {
                this.newsList = returnvalue.data.sort((a,b) => b.newsId - a.newsId);
            }
            else {
                alert(returnvalue.errormsg);
            }
            this.fetching = false;
        },

        renderList() {
            this.filteredList = [];
            this.newsList.forEach((val) => {
                if(!this.searchFilter || val.title.indexOf(this.searchFilter) >= 0){
                    this.filteredList.push(val);
                }
            });
            this.setPageList();
        },
        setPageList() {
            const start = PAGESIZE * (this.currentPage - 1);
            const end = start + (PAGESIZE-1) < this.filteredList.length ? start + (PAGESIZE-1) : this.filteredList.length - 1;

            this.showList = [[null, null, null], [null, null, null], [null, null, null]];
            for(var i = start, j = 0, k = 0; i <= end; i++, k++) {
                if(k > 0 && k%3 == 0){
                    j++;
                }
                this.showList[j][k%3] = this.filteredList[i];
            }
        },
        setPaging() {
            this.numOfPage = Math.ceil(this.newsList.length / PAGESIZE);
            this.pageQueue = [];
            if(this.currentPage < 11) {
                for(var i = 1; i <= (this.numOfPage > 10 ? 10 : this.numOfPage) ; i++) {
                this.pageQueue.push(i);
                }
            }
            else {
                for(i = this.currentPage - 9; i <= this.currentPage; i++) {
                this.pageQueue.push(i);
                }
            }
        },
        switchPage(mode) {
            var render = false;
            switch(mode) {
                case -1 : if(this.currentPage > 1) {
                            this.currentPage = 1; 
                            render = true;
                        }
                break;
                case 0 : if(this.currentPage > 1) {
                            this.currentPage--;
                            render = true;
                        }
                break;
                case 1 : if(this.currentPage < this.numOfPage) {
                            this.currentPage++;
                            render = true;
                        }
                break;
                case 2 : if(this.currentPage < this.numOfPage) {
                            this.currentPage = this.numOfPage;
                            render = true;
                        } 
            }
            if(render) {
                this.setPaging();
                this.renderList();
            }
        }
    },
}
</script>