<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">M3 소개</span><br>
                    <span class="dh_p1c">ESG (Environmental, Social and Governance) 를 기업경영의 핵심으로 두고,<br>가격보다 고객 가치를 추구하며, 통합 BIM 및 DfMA 핵심 기술을 탑재한 자체 플랫폼으로,<br>보다 편리하고 빠르게 건축물을 구현합니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- (주)엠쓰리시스템즈 -->
                <div class="iw-1 py-3 pt-5">
                    <div class="iw-2"><img src="@/assets/img/s_info/info1.png" width="100%"></div>
                    <div class="iw-4 px-4">
                        <div class="py-4"><img src="@/assets/img/s_info/s_info_logo.png" width="250"></div>
                        <div style="line-height:200%">
                            <p class="fkr-set2 fs-13 fc-w10"><strong>(주)엠쓰리시스템즈</strong>는</p>
                            <p class="fkr-set2 fs-09"><strong>Manufactured Modular via Meta-X Systems</strong> 입니다.</p>
                            <p class="fkr-set2 fs-09">첨단 BIM통합형 DfMA 기술을 보유한  모듈러건축 통합플랫폼 서비스 기업입니다.</p>
                            <p class="fkr-set2 fs-09">ESG(Environmental, Social and Governance)기업경영 윤리기반으로, 소중한 사람들과 함께하는 건축공간을 빠르고 정확하고 편리하게 제공합니다.</p>
                            <p class="fkr-set2 fs-09">연구진의 지난 20여년 간의 BIM 연구개발 경험 및 전문성으로 프리미엄 모듈러 건축물을 만드는 신뢰할 수 있는 기업으로 발전하겠습니다.</p>
                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

                <!-- Characteristics -->
                <div class="py-3">
                    <div class="x_div_center"><span class="fen-set3 fs-16"><strong><h2>Characteristics</h2></strong></span></div>
                    <div class="x_div_center py-3"><span class="fkr-set2 fs-10">M3는 속도, 품질, 기술, 친환경 건축으로 앞서 갑니다.</span></div>
                    <div class="iw-1">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3 px-1 py-2">
                                <div class="text-center"><img src="@/assets/img/s_info/info2.jpg" width="95%"></div>
                                <div class="text-center py-2"><div class="py-2 fen-set3 fs-13"><h5>Speed</h5></div><div class="px-3 fkr-set2 fs-09">고객의 취향과 요구사항에 부합된 최적화된 디자인을 하고 빠르게 실현합니다.</div></div>
                            </div>
                            <div class="iw-3 px-1 py-2">
                                <div class="text-center"><img src="@/assets/img/s_info/info3.png" width="95%"></div>
                                <div class="text-center py-2"><div class="py-2 fen-set3 fs-13"><h5>Higher Quality</h5></div><div class="px-3 fkr-set2 fs-09">디지털화와 제조업화를 통해 가격,가치, 품질 좋은 건물을 보다 빠르게 선사합니다.</div></div>
                            </div>
                        </div>
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3 px-1 py-2">
                                <div class="text-center"><img src="@/assets/img/s_info/info4.jpg" width="95%"></div>
                                <div class="text-center py-2"><div class="py-2 fen-set3 fs-13"><h5>BIM + Platform</h5></div><div class="px-3 fkr-set2 fs-09">축적된 BIM 기술과 DfMA 플랫폼으로 빠르고 품질 좋은 건축서비스를 제공합니다.</div></div>
                            </div>
                            <div class="iw-3 px-1 py-2">
                                <div class="text-center"><img src="@/assets/img/s_info/info5.jpg" width="95%"></div>
                                <div class="text-center py-2"><div class="py-2 fen-set3 fs-13"><h5>Eco-Friendly</h5></div><div class="px-3 fkr-set2 fs-09">모듈러 공법으로 폐자재를 줄이고 친환경 설계로, 냉난방비를 효과적으로 절감합니다.</div></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

                <div class="py-3" style="border-bottom:1px solid #e9e9e9; overflow-x:auto">
                    <div class="x_div_center"><span class="fen-set3 fs-16"><strong><h2>Facilities</h2></strong></span></div>
                    <div class="x_div_center py-3"><span class="fkr-set2 fs-10">당신의 꿈, 당신의 미래를 만드는 곳 입니다.</span></div>
                    <div class="iw-1">
                        <div class="iw-5 px-3 py-2">
                            <div class="w-100" data-toggle="modal" data-target="#v-s1"><img src="@/assets/img/s_info/s_info_m1.jpg" width="100%" height="250"><div class="x_div_center py-2 fkr-set1 fs-10">본사</div></div>
                        </div>
                        <div class="iw-5 px-3 py-2">
                            <div class="w-100" data-toggle="modal" data-target="#v-s2"><img src="@/assets/img/s_info/s_info_m2.png" width="100%" height="250"><div class="x_div_center py-2 fkr-set1 fs-10">부설연구소</div></div>
                        </div>
                        <div class="iw-5 px-3 py-2">
                            <div class="w-100" data-toggle="modal" data-target="#v-s3"><img src="@/assets/img/s_info/s_info_m3.jpg" width="100%" height="250"><div class="x_div_center py-2 fkr-set1 fs-10">모듈러 공장</div></div>
                        </div>
                    </div>
                </div>

                <!-- 본사 -->
                <div id="v-s1" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:7%">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">

                            <div class="w-100">
                                <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w8">본사</span></div>
                                    <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                                </div>

                                <div><img src="@/assets/img/s_info/s_info_m1.jpg" width="100%" style="border-bottom-left-radius:5px; border-bottom-right-radius:5px"></div>

                            </div>

                        </div>
                    </div>
                </div>

                <!-- 부설연구소 -->
                <div id="v-s2" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:7%">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">

                            <div class="w-100">
                                <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w8">부설연구소</span></div>
                                    <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                                </div>

                                <div><img src="@/assets/img/s_info/s_info_m2.png" width="100%" style="border-bottom-left-radius:5px; border-bottom-right-radius:5px"></div>

                            </div>

                        </div>
                    </div>
                </div>

                <!-- 모듈러 공장 -->
                <div id="v-s3" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:7%">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">

                            <div class="w-100">
                                <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w8">모듈러 공장</span></div>
                                    <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                                </div>

                                <div><img src="@/assets/img/s_info/s_info_m3.jpg" width="100%" style="border-bottom-left-radius:5px; border-bottom-right-radius:5px"></div>

                            </div>

                        </div>
                    </div>
                </div>
                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
export default {
    name: 'MenuIntroduction',
    components: {
        MenuFooter
    }
}
</script>