<template>
    <div>
        <div class="pf_hd w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">서산 M House</span><br>
                    <span class="dh_p1c">M3Systems 의 DfMA 모듈러 건축기술을 적용하여,<br>고객님의 생활 패턴에 따라 변화가 가능한 M House를 소개합니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- header info -->
                <div class="py-3">
                    <div class="iw-1">
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">건축 용도</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">단독 주택</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">주요 구조</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">철골조</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">공사 기간</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">제조 8주 / 설치 3일</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">대지 위치</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">충남 서산시 성연면 갈현리</div></div>
                        </div>
                    </div>
                </div>

                <div style="height:30px"></div>

                <div class="py-3">
                    <!-- carousel image -->
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button v-for="(v, i) in carouselImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                        </div>
                        <div class="carousel-inner">
                            <div v-for="(v, i) in carouselImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                <div class="iw-1">
                                    <img :src="'/files/img/portfolio/mhouse/' + v" :style="setStyleCarousel(v)">
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    <!-- main images(4 slots) -->
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[0]? 'mhouse/' + this.mainImg[0] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[0])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[1]? 'mhouse/' + this.mainImg[1] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[1])" width="100%">
                                </div>
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[2]? 'mhouse/' + this.mainImg[2] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[2])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[3]? 'mhouse/' + this.mainImg[3] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[3]) + (carouselImg.length > 4 ? 'filter:brightness(50%);' : '')" width="100%">
                                    <span style="position:absolute; top:40%; left:40%; color:#fff"><h1>{{ carouselImg.length > 4 ? ('+' + (carouselImg.length - 4)) : '' }}</h1></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>집을 나에게 맞추다</strong></p>
                    <p class="fkr-set2 fs-09">더 이상 삶의 변화에 따라 다른 집으로 이사갈 필요가 없습니다. <br>이제는 생활 패턴이 바뀌는 것에 따라 집을 변화시키세요. <br>M House는 고객님에 맞게 바뀔 준비가 되어있습니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(single left + 2 layered right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/2 : pageWidth) + 'px;'">
                                <img :src="'/files/img/portfolio/mhouse/main09_h.jpg'" :style="setStyleMains('main09_h.jpg')" width="100%">
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3" style="align-items:start;">
                            <!-- horizontal images only!! -->
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/mhouse/main07_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                            <div style="height:16px"></div>
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/mhouse/main08_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>추억 저장소</strong></p>
                    <p class="fkr-set2 fs-09">확 트인 거실 천정고로 채광이 풍부하고 개개인의 라이프스타일을 존중할 수 있는 짜임새 있는 실내 평면으로 방 2개를 배치했습니다. <br>편의성을 고려하여 드레스룸과 수납장 등이 구비되도록 설계되었습니다. <br>2층에 다양한 목적으로 활용될 수 있는 테라스를 제공합니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(horizontal single left + vertical single right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-1 d-flex" style="align-items:start;">
                            <!-- horizontal image only!! -->
                            <img :src="'/files/img/portfolio/mhouse/main05_h.jpg'" width="100%">
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-2 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (isMobile? pageWidth*1.33 : pageWidth/3*1.43) +'px'">
                                <img :src="'/files/img/portfolio/mhouse/main06_v.jpg'" :style="setStyleVertical('main06_v.jpg')" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>비용 상승과 공사기간 연장으로부터의 해방</strong></p>
                    <p class="fkr-set2 fs-09">건축자재의 유닛화로 건축 자재비가 늘어날 염려가 없습니다. <br>전문화된 인력들이 공장에서 제작하기 때문에 날씨, 인력수급 등의 영향을 받지 않습니다. <br>알뜰한 가격으로 정확한 기간에 시공됩니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- specifiaction -->
                <div class="table-responsive" style="border-top:3px solid #c9c9c9; border-bottom:3px solid #c9c9c9">
                    <table class="table-borderless w-100">
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">대지위치&nbsp;&nbsp;&nbsp;Location</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">충청남도 서산시 성연면 갈현리</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">용&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;도&nbsp;&nbsp;&nbsp;Usage</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">단독주택</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">구&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조&nbsp;&nbsp;&nbsp;Structure</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">철골조</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">대지면적&nbsp;&nbsp;&nbsp;Site area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">728 ㎡</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">건축면적&nbsp;&nbsp;&nbsp;Building area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">108.70 ㎡</span></td> <!--(33평) -->
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">연 면 적&nbsp;&nbsp;&nbsp;Total floor area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">136.75 ㎡</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">층&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;수&nbsp;&nbsp;&nbsp;Floors</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">1층+다락</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">건 폐 율&nbsp;&nbsp;&nbsp;Building land ratio</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">15.35%</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">용 적 률&nbsp;&nbsp;&nbsp;Floor area ratio</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">18.8%</span></td>
                        </tr>
                    </table>
                </div>
                <div class="x_div_end py-2"><button @click="openModalForSimulation()" class="fkr-set2 fs-095" style="width:120px; height:37px; border:0; background-color:#4e0000; color:#fff; border-radius:4px">시뮬레이션 보기</button></div>

                <div style="height:50px"></div>

                <PortfolioNavigation :isMobile="isMobile"/>
                <MenuFooter/>
            </div>

            <div id="v-view" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:10001;">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="w-100">
                            <div class="x_div_center w-100" style="background-color:#dccccc; border-top-left-radius:8px; border-top-right-radius:8px">
                                <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-13"> 시뮬레이션 </span></div>
                                <div class="text-end pe-4" style="width:100px"><a href="#@" @click="closeModal()" style="color:#4e0000" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15"></i></a></div>
                            </div>

                            <div class="x_div_center py-2 px-4">
                                <div class="w-100 x_div_center py-3">
                                    <iframe id="ifr" ref="ifrm" src=""></iframe>
                                </div>
                            </div>

                            <div class="x_div_center py-4">
                                <div class="px-2"><button @click="closeModal()" class="x_div_center" style="width:90px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
                            </div>

                            <div style="height:20px"></div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import PortfolioNavigation from '@/components/PortfolioNavigation.vue'

var gIfrmMobuilder = null;

export default {
    name: 'PortfolioMHouse',
    components: {
        MenuFooter, PortfolioNavigation
    },
    data() {
        return {
            isMobile: false,
            pageWidth: 0,
            viewWidth: 0,
            carouselImg: ['main01_h.png', 'main02_h.png', 'main10_h.jpg', 'main04_h.jpg'],
            mainImg: []
        }
    },
    created() {
        for(var i=0; (i < this.carouselImg.length && i < 4); i++) {
            this.mainImg[i] = this.carouselImg[i];
        }
        for(; i < 4; i++) {
            this.mainImg[i] = '';
        }
    },
    mounted() {
        // eslint-disable-next-line
        var swiper = new window.Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 20,
            effect: 'fade',
            loop: true,
            speed: 300,
            mousewheel: {
                invert: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        this.isMobile = window.innerWidth < 768;
        this.viewWidth = window.innerWidth;
        this.pageWidth = Number(window.$('.rp_set.px-3').css('width').split('px')[0]);

        this.$refs.ifrm.src = "https://mobuild.cafe24.com/mobuilder/index/public/?ma=builder&mu=modeUserMain";

		const gIframe = document.getElementById("ifr");

		gIframe.addEventListener("load", function() {
			gIfrmMobuilder = (gIframe.contentWindow) ? gIframe.contentWindow :
			(gIframe.contentDocument.document) ? gIframe.contentDocument.document : gIframe.contentDocument;
		});

		window.addEventListener('message', (e) => {
			// console.log('================== post message ====================')
			// console.log(e.data);
			if( e.data.functionName == 'fExpandMobuilderFrame' ) {
				this.expandIframe(e.data.para[0]);
			}
			else if( e.data.functionName == 'fMobuilderCallback' ) {

				const gjMenuSet = {
                    "project": {            // 프로젝트
                        "outline": true,    // - 사업 개요
                        "info": true,       // - 사업 정보
                    },
                    "land": {               // 대지
                        "address": true,    // - 주소 입력
                        "danji": false,     // - 단지 선택 <-- 숨기기
                        "info": true        // - 대지 정보
                    },
                    "model": {              // 모델
                        "outline": true,    // - 모델 개요
                        "info": true,       // - 모델 정보
                        "build": true       // - 모델 수정
                    }
				}

				gIfrmMobuilder.postMessage({
					functionName : "fSidebarSetMenu",
					para : [JSON.stringify(gjMenuSet)] 
				}, "*" );

                gIfrmMobuilder.postMessage({
                    functionName : "fSidebarDisplayMenu",   // 실행할 함수명
                    para : ["hideTopMenu"]                  // 함수에 전달할 인수 배열
                }, "*" );
			}
		});

    },
    methods: {
        setStyleCarousel(img) {
            if(this.isMobile) {
                return 'width:100%';
            }
            else {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'height:' + this.pageWidth/1.33 + 'px'
                }
                else {
                    return 'width:100%'
                }
            }
        },
        setStyleMains(img) {
            if(img) {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
                }
                else {
                    //return 'position: absolute; top: 0; left: -75%; width: auto; height: ' + (this.pageWidth >= 1024 ? this.pageWidth/2 : this.pageWidth) + 'px;'
                    return 'position: absolute; top: 0; left: -25%; width: auto; height: 100%'
                }
            }
            else {
                return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
            }
        },
        setStyleVertical(img) {
            var side = img.split('_')[1];
            if(side.indexOf('v') >= 0) {
                return 'position: absolute; width: 103%; height: auto;'
            }
            else {
                return 'position: absolute; width:auto; ' + (this.isMobile ? 'height:' + this.pageWidth*1.33 : 'top: 0; left: -25%; height:' + (this.pageWidth/3)*1.43) + 'px;'
            }
        },
        expandIframe(mode) {
			if(mode == 'expand') {
				window.$('#ifr').css('z-index', 10002);
				window.$('#ifr').css('position', 'fixed');
                window.$('#ifr').css('top', '0');
				window.$('#ifr').css('height', '100%');
			}
			else if(mode == 'return') {
				window.$('#ifr').css('z-index', 1);
				window.$('#ifr').css('position', 'relative');
                window.$('#ifr').css('top', 'auto');
				window.$('#ifr').css('height', this.isMobile? '600px' : '1000px');
			}
		},
        openModalForSimulation() {
			gIfrmMobuilder.postMessage({
				functionName : "fLoadIfcByParent",
				para : ["02-CWK", "CWK-11-00-00", "CWK-11-00-00", '']
			}, "*" );

			gIfrmMobuilder.postMessage({
				functionName : "fSelectSidebarMenu",
				para : ["model", "", ""]
			}, "*" );

			gIfrmMobuilder.postMessage({
				functionName : "fSidebarClose",
				para : []
			}, "*" );

			this.openModal()
		},
		openModal() {
			window.$('#v-view').modal('show');
		},
		closeModal() {
			window.$('#v-view').modal('hide');
		}
    }
}
</script>

<style scoped>
.pf_hd {
    background-image: url('@/assets/img/portfolio/header/mhouse.jpg');
    background-position: 50% 30%;
}

@media (max-width:1023.98px) {
    #ifr {
        width: 100%;
        height: 600px;
    }
}

@media (min-width:1024px) {
    #ifr {
        width: 100%;
        height: 1000px;
    }
}
</style>