<template>
    <div>
        <div class="pf_hd w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">신갈 M3 Office</span><br>
                    <span class="dh_p1c">M3Systems 의 DfMA 모듈러 기술을 앞장서서 적용하여 자신있게 보여드리는 M3 Office입니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- header info -->
                <div class="py-3">
                    <div class="iw-1">
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">건축 용도</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">오피스</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">주요 구조</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">철골조</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">공사 기간</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">제조 16주 / 설치 7일</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">대지 위치</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">용인시 기흥구 신갈동</div></div>
                        </div>
                    </div>
                </div>

                <div style="height:30px"></div>

                <div class="py-3">
                    <!-- carousel image -->
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button v-for="(v, i) in carouselImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                        </div>
                        <div class="carousel-inner">
                            <div v-for="(v, i) in carouselImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                <div class="iw-1">
                                    <img :src="'/files/img/portfolio/shingaloffice/' + v" :style="setStyleCarousel(v)">
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    <!-- main images(4 slots) -->
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[0]? 'shingaloffice/' + this.mainImg[0] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[0])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[1]? 'shingaloffice/' + this.mainImg[1] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[1])" width="100%">
                                </div>
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[2]? 'shingaloffice/' + this.mainImg[2] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[2])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[3]? 'shingaloffice/' + this.mainImg[3] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[3]) + (carouselImg.length > 4 ? 'filter:brightness(50%);' : '')" width="100%">
                                    <span style="position:absolute; top:40%; left:40%; color:#fff"><h1>{{ carouselImg.length > 4 ? ('+' + (carouselImg.length - 4)) : '' }}</h1></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>Design concept : De-Grid</strong></p>
                    <p class="fkr-set2 fs-09">'De'는 프랑스어로 탈출, 혹은 해체를 뜻하는 접두사입니다. <br>De-Grid는 탈그리드를 의미하며, 신갈동 오피스는 기존 모듈러 건축에서 보이는 그리드 시스템의 디자인으로 부터 벗어난 새로운 디자인 유형을 제안하였습니다. <br>파사드의 곡선 비정형으로 유니크한 개성을 살렸습니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(single left + 2 layered right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/2 : pageWidth) + 'px;'">
                                <img :src="'/files/img/portfolio/shingaloffice/main06_h.png'" :style="setStyleMains('main06_h.png')" width="100%">
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3" style="align-items:start;">
                            <!-- horizontal images only!! -->
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/shingaloffice/main07_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                            <div style="height:16px"></div>
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/shingaloffice/main08_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>세련된 디자인과 현대적 건축의 만남</strong></p>
                    <p class="fkr-set2 fs-09">어떤 지역이라도 자연스럽게 녹아들 수 있는 세련된 외관을 자랑하며, 견고한 철골 구조로 우수한 내진성/내화성을 갖추고 있습니다. <br>모듈과 크레인이 진입 가능한 도로변이라면 어디든 시공 가능합니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(horizontal single left + vertical single right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-1 d-flex" style="align-items:start;">
                            <!-- horizontal image only!! -->
                            <img :src="'/files/img/portfolio/shingaloffice/main09_h.jpg'" width="100%">
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-2 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (isMobile? pageWidth*1.33 : pageWidth/3*1.43) +'px'">
                                <img :src="'/files/img/portfolio/shingaloffice/main10_h.jpg'" :style="setStyleVertical('main10_h.jpg')" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>성공적 비즈니스의 시작</strong></p>
                    <p class="fkr-set2 fs-09">개방형 공용 공간 디자인으로 협업과 창의력, 업무 유연성, 그리고 혁신적인 아이디어를 창출할 수 있는 사무공간을 제공합니다. <br>옥상층의 바이오필릭 디자인(Biophilic Design)은 자연이 제공하는 풍요로움을 통해 사용자들에게 재충전과 여유를 찾을 수 있는 공간을 제공합니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- specifiaction -->
                <div class="table-responsive" style="border-top:3px solid #c9c9c9; border-bottom:3px solid #c9c9c9">
                    <table class="table-borderless w-100">
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">대지위치&nbsp;&nbsp;&nbsp;Location</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">용인시 기흥구 신갈동</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">용&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;도&nbsp;&nbsp;&nbsp;Usage</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">오피스</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">구&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조&nbsp;&nbsp;&nbsp;Structure</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">철골조</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">대지면적&nbsp;&nbsp;&nbsp;Site area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">264.5 ㎡</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">건축면적&nbsp;&nbsp;&nbsp;Building area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">154.76 ㎡</span></td> <!--(47평) -->
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">연 면 적&nbsp;&nbsp;&nbsp;Total floor area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">624.82 ㎡</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">층&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;수&nbsp;&nbsp;&nbsp;Floors</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">5층</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">건 폐 율&nbsp;&nbsp;&nbsp;Building land ratio</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">58.51%</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">용 적 률&nbsp;&nbsp;&nbsp;Floor area ratio</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">236.22%</span></td>
                        </tr>
                    </table>
                </div>

                <div style="height:50px"></div>

                <PortfolioNavigation :isMobile="isMobile"/>
                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import PortfolioNavigation from '@/components/PortfolioNavigation.vue'
export default {
    name: 'PortfolioShingalOffice',
    components: {
        MenuFooter, PortfolioNavigation
    },
    data() {
        return {
            isMobile: false,
            pageWidth: 0,
            viewWidth: 0,
            carouselImg: ['main01_h.jpg', 'main02_h.png', 'main03_h.jpg', 'main04_h.jpg'],
            mainImg: []
        }
    },
    created() {
        for(var i=0; (i < this.carouselImg.length && i < 4); i++) {
            this.mainImg[i] = this.carouselImg[i];
        }
        for(; i < 4; i++) {
            this.mainImg[i] = '';
        }
    },
    mounted() {
        // eslint-disable-next-line
        var swiper = new window.Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 20,
            effect: 'fade',
            loop: true,
            speed: 300,
            mousewheel: {
                invert: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        this.isMobile = window.innerWidth < 768;
        this.viewWidth = window.innerWidth;
        this.pageWidth = Number(window.$('.rp_set.px-3').css('width').split('px')[0]);
    },
    methods: {
        setStyleCarousel(img) {
            if(this.isMobile) {
                return 'width:100%';
            }
            else {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'height:' + this.pageWidth/1.33 + 'px'
                }
                else {
                    return 'width:100%'
                }
            }
        },
        setStyleMains(img) {
            if(img) {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
                }
                else {
                    //return 'position: absolute; top: 0; left: -75%; width: auto; height: ' + (this.pageWidth >= 1024 ? this.pageWidth/2 : this.pageWidth) + 'px;'
                    return 'position: absolute; top: 0; left: -25%; width: auto; height: 100%'
                }
            }
            else {
                return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
            }
        },
        setStyleVertical(img) {
            var side = img.split('_')[1];
            if(side.indexOf('v') >= 0) {
                return 'position: absolute; width: 103%; height: auto;'
            }
            else {
                return 'position: absolute; width:auto; ' + (this.isMobile ? 'height:' + this.pageWidth*1.33 : 'top: 0; left: -25%; height:' + (this.pageWidth/3)*1.43) + 'px;'
            }
        }
    }
}
</script>

<style scoped>
.pf_hd {
    background-image: url('@/assets/img/portfolio/header/shingaloffice.jpg');
    background-position: 50% 20%;
}
</style>