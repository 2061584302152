<template>
  <div class = "container">

    <h1 class = "text-center"> Log On</h1>
    <br/>
    <h3 class = "text-center"> Log-in token: {{$store.state.token}}</h3>
    <br/>
    <table class = "table table-striped">
        <tbody>
          <tr>
            <td><router-link to="/somePage1">Some Page 1</router-link></td>
            <td><router-link to="/somePage2">Some Page 2</router-link></td>
            <td><router-link to="/project/plan">기획하기</router-link></td>
          </tr>
        </tbody>
    </table>
    <div style="align-content:center;">
      <button @click="logout()">Log out</button>
    </div>
  </div>
</template>

<script>
import Auth from "@/api/auth"

export default {
  name: 'LogOn',
  data(){
    return {
    }
  },
  async mounted() {
    if(!await Auth.isLogedIn()){
      this.$router.push('/');
    }
  },
  methods: {
    logout() {
        Auth.logOut();
        this.$router.push('/');
    }
  },
  created() {
  }
}
</script>

<style scoped>

</style>