import Vue from 'vue'
import Router from 'vue-router'
import Test from './test'
import Menu from './menu'
import Menu_En from './menu_en'

import Home from '@/views/Home.vue'
import Home_En from '@/views/HomeEn.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import PageNotFound from "@/components/PageNotFound";
import InternalServerError from "@/components/InternalServerError";
import MySamples from "@/components/test/Samples";


Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    ...Test,
    ...Menu,
    ...Menu_En,
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/home',
      name: 'Home-En',
      component: Home_En
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '*',
      redirect: "/404"
    },
    {
      path: '*',
      redirect: "/500"
    },
    {
      path: '*',
      redirect: "/401"
    },
    {
      path: "/404",
      component : PageNotFound
    },
    {
      path: "/500",
      component : InternalServerError
    },
    {
      path: "/401",
      component : MySamples
    }
  ]
})