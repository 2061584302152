<template>
    <div>
        <div class="pf_hd w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">M School</span><br>
                    <span class="dh_p1c">주거용 및 상업용 건축에서 축적된 기술과 디자인을 스마트학교 모듈러에 적용하였습니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- header info -->
                <div class="py-3">
                    <div class="iw-1">
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">건축 용도</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">학교</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">주요 구조</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">철골조</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">공사 기간</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">90일</div></div>
                        </div>
<!--                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">-->
<!--                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">대지 위치</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">용인시 기흥구 신갈동</div></div>-->
<!--                        </div>-->
                    </div>
                </div>

                <div style="height:30px"></div>

                <div class="py-3">
                    <!-- carousel image -->
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button v-for="(v, i) in carouselImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                        </div>
                        <div class="carousel-inner">
                            <div v-for="(v, i) in carouselImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                <div class="iw-1">
                                    <img :src="'/files/img/portfolio/mschool/' + v" :style="setStyleCarousel(v)">
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    <!-- main images(4 slots) -->
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[0]? 'mschool/' + this.mainImg[0] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[0])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[1]? 'mschool/' + this.mainImg[1] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[1])" width="100%">
                                </div>
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[2]? 'mschool/' + this.mainImg[2] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[2])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[3]? 'mschool/' + this.mainImg[3] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[3]) + (carouselImg.length > 4 ? 'filter:brightness(50%);' : '')" width="100%">
                                    <span style="position:absolute; top:40%; left:40%; color:#fff"><h1>{{ carouselImg.length > 4 ? ('+' + (carouselImg.length - 4)) : '' }}</h1></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>모듈러 노하우가 축적된 프리미엄 모듈러 교사</strong></p>
                    <p class="fkr-set2 fs-09">다양한 공간을 여유있게 제공하여 쾌적한 환경을 자랑하는, <br>학생과 선생님 모두를 위한 밝고 즐거은 학교입니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(single left + 2 layered right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/2 : pageWidth) + 'px;'">
                                <img :src="'/files/img/portfolio/mschool/main06_h.jpg'" :style="setStyleMains('main06_h.jpg')" width="100%">
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3" style="align-items:start;">
                            <!-- horizontal images only!! -->
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/mschool/main07_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                            <div style="height:16px"></div>
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/mschool/main08_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>탁월한 성능과 안전한 건물</strong></p>
                    <p class="fkr-set2 fs-09">접합철물(긴결용 브라켓)을 이용하여 조립, 설치, 해체 및 이전이 용이하고, <br>일반건축물 수준의 내진구조와 내화 기준을 충족하며, <br>맞춤형 디자인과 환기성능이 강화된 모듈러 임시교사를 제공합니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(horizontal single left + vertical single right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-1 d-flex" style="align-items:start;">
                            <!-- horizontal image only!! -->
                            <img :src="'/files/img/portfolio/mschool/main10_h.jpg'" width="100%">
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-2 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (isMobile? pageWidth*1.33 : pageWidth/3*1.43) +'px'">
                                <img :src="'/files/img/portfolio/mschool/main09_h.jpg'" :style="setStyleVertical('main09_h.jpg')" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>모듈러 건축의 상식을 넘는 다양한 용도</strong></p>
                    <p class="fkr-set2 fs-09">다목적 강당, 급식실, 북카페 아트리움, 소그룹실 등 <br>특수 모듈러 공법을 적용한 설계를 통해 다양한 요구에 맞는 공간 제작이 가능합니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- specifiaction -->
                <div class="table-responsive" style="border-top:3px solid #c9c9c9; border-bottom:3px solid #c9c9c9">
                    <table class="table-borderless w-100">
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">구조안전성능</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">「건축물의 구조기준 등에 관한 규칙」에 따른 건축물의 설계기준에 적합한 구조 계산서 확보</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">내화성능</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">내화인정구조 적용(인정기관 : 한국건설기술연구원) = 1시간 내화 도료 인정서 확보</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">내부 마감재 방화성능</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">바닥재 시험성적서 확보/방화석고보드 인증서 확보/천장 마감재 준불연 시험성적서 확보/친환경 건축자재 인증서 확보/준불연성능 시험성적서 확보</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">환기</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">1인당 21.6㎥ 이상</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">채광</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">옥외 수평조도와 실내조도와의 비가 평균 5퍼센트 이상</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">조명</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">300럭스 이상</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">온습도</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">18도 이상 28도 이하</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">공기질</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">실내공기질성능평가 시험성적서 확보</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">소음 및 차음</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">공기전달음차단 성능평가 시험성적서 확보</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">피난 및 방화</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">「건축법」 및 「건축물의 피난 · 방화구조 등의 기준에 관한 규칙」에 준하는 준공검사</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">소방</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">자동화재탐지기, 비상방송설비, 옥내소화전 및 (간이)스프링클러 설치 / 관할소방서 ‘소방시설 완공검사증명서’ 제출</span></td>
                        </tr>
                    </table>
                </div>

                <div style="height:50px"></div>

                <PortfolioNavigation :isMobile="isMobile"/>
                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import PortfolioNavigation from '@/components/PortfolioNavigation.vue'
export default {
    name: 'PortfolioMSchool',
    components: {
        MenuFooter, PortfolioNavigation
    },
    data() {
        return {
            isMobile: false,
            pageWidth: 0,
            viewWidth: 0,
            carouselImg: ['main01_h.jpg', 'main02_h.jpg', 'main03_h.jpg', 'main04_h.jpg'],
            mainImg: []
        }
    },
    created() {
        for(var i=0; (i < this.carouselImg.length && i < 4); i++) {
            this.mainImg[i] = this.carouselImg[i];
        }
        for(; i < 4; i++) {
            this.mainImg[i] = '';
        }
    },
    mounted() {
        // eslint-disable-next-line
        var swiper = new window.Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 20,
            effect: 'fade',
            loop: true,
            speed: 300,
            mousewheel: {
                invert: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        this.isMobile = window.innerWidth < 768;
        this.viewWidth = window.innerWidth;
        this.pageWidth = Number(window.$('.rp_set.px-3').css('width').split('px')[0]);
    },
    methods: {
        setStyleCarousel(img) {
            if(this.isMobile) {
                return 'width:100%';
            }
            else {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'height:' + this.pageWidth/1.33 + 'px'
                }
                else {
                    return 'width:100%'
                }
            }
        },
        setStyleMains(img) {
            if(img) {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
                }
                else {
                    //return 'position: absolute; top: 0; left: -75%; width: auto; height: ' + (this.pageWidth >= 1024 ? this.pageWidth/2 : this.pageWidth) + 'px;'
                    return 'position: absolute; top: 0; left: -25%; width: auto; height: 100%'
                }
            }
            else {
                return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
            }
        },
        setStyleVertical(img) {
            var side = img.split('_')[1];
            if(side.indexOf('v') >= 0) {
                return 'position: absolute; width: 103%; height: auto;'
            }
            else {
                return 'position: absolute; width:auto; ' + (this.isMobile ? 'height:' + this.pageWidth*1.33 : 'top: 0; left: -25%; height:' + (this.pageWidth/3)*1.43) + 'px;'
            }
        }
    }
}
</script>

<style scoped>
.pf_hd {
    background-image: url('@/assets/img/portfolio/header/mschool.jpg');
    background-position: 50% 20%;
}
</style>