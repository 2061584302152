<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-if="lang == 'ko'">
                    <span class="dh_p1b">사업현황</span><br>
                    <span class="dh_p1c">M3가 현재 진행하고있는 프로젝트들을 만나보세요.</span>
                </div>
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-else-if="lang == 'en'">
                    <span class="dh_p1b">Current Business</span><br>
                    <span class="dh_p1c">Get information about M3's recently proceeding projects.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- FAQ -->
                <div class="w-100 py-3 pt-5">
                    <div>
                        <div class="x_div_center" style="border:1px solid #e1e1e1">
                            <div :class="'pa_mbox1' + (!ctgryId? '_on' : '') +' py-3 text-center br_3'" :style="'width:' + 100/(ctgryList.length+1) + '%'" @click="ctgryId = null"><span class="fkr-set2">{{ lang == 'ko'? '전체' : 'All' }}</span></div>
                            <div v-for="(ctgry, index) in ctgryList" :key="index" :class="'pa_mbox1' + (ctgryId == ctgry.id? '_on' : '') +' py-3 text-center' + (index+1 == ctgryList.length? '' : ' br_3')" :style="'width:' + 100/(ctgryList.length+1) + '%'" @click="ctgryId = ctgry.id"><span class="fkr-set2">{{ lang == 'ko'? ctgry.ctgryName : ctgry.ctgryNameEn }}</span></div>
                        </div>
                    </div>

                    <div class="py-3">
                        <div style="border-top:1px solid #e7e7e7">
                            <div v-for="(recent, index) in recentList" :key="index" style="border-bottom:1px solid #e1e1e1">
                                <a :href="'/menu/recentview/' + recent.recentId">
                                    <div class="p-3 ls_bg1" style="display:flex; justify-content: space-between;">
                                        <span class="fkr-set1">{{recent.sbjct}}</span>
                                        <span class="fkr-set2">{{recent.crtDt.split('T')[0]}}</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
import Api from '@/api'

export default {
    name: 'RecentList',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            ctgryId: null,
            ctgryList: [],
            recentList: []
        }
    },
    watch: {
        ctgryId(val) {
            this.fetchRecentList();
            sessionStorage.setItem("recentCtgryTab", val);
        }
    },
    async mounted() {
        this.ctgryList = [];
        var rtnval = await Api.get('https://buildmarket.kr/api/v1/recent/ctgry/list');
        if(rtnval.result) {
            this.ctgryList = rtnval.data;
        }
        else {
            alert(rtnval.errormsg)
        }
        var selectedTab = sessionStorage.getItem("recentCtgryTab");
        this.ctgryId = selectedTab? parseInt(selectedTab, 0) : null;
        if(!this.ctgryId) {
            this.fetchRecentList();
        }
    },
    methods:{
        async fetchRecentList() {
            this.recentList = [];
            var rtnval = await Api.post('https://buildmarket.kr/api/v1/recent/find/recent/list', {ctgryId: this.ctgryId, asc: false});
            if(rtnval.result) {
                this.recentList = rtnval.data.resultList;
            }
            else {
                alert(rtnval.errormsg)
            }
        }
    }
}
</script>