<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-if="lang == 'ko'">
                    <span class="dh_p1b">프로젝트</span><br>
                    <span class="dh_p1c">M3가 현재 진행하고있는 프로젝트들을 만나보세요.</span>
                </div>
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-else-if="lang == 'en'">
                    <span class="dh_p1b">Projects</span><br>
                    <span class="dh_p1c">Get information about M3's recently proceeding projects.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <div class="p-3 pt-5">
                    <div style="border-bottom:1px solid #e9e9e9">
                        <div class="py-2 w-100">

                            <div class="py-2"><span class="fkr-set1 fs-12 fc-w10">{{ article.sbjct }}</span></div>
                            <div class="pb-2" style="border-bottom:1px solid #e9e9e9"><span class="fen-set2 fc-w3">{{ article.crtDt? article.crtDt.split('T')[0] : '' }}</span></div>
                            <div class="py-3">
                                <div class="px-2" style="line-height:180%">
                                    <span id="articleContent" class="fkr-set2 fs-09" v-html="article.cntnt"></span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div>
                        <div v-if="prev" class="d-flex px-4 py-3 ls_bg1" style="border-bottom:1px solid #e9e9e9">
                            <div><i class="fa-solid fa-arrow-up fs-095 fc-w10 pe-3"></i></div>
                            <div><a :href="'/menu/projects/dtl/' + prev.recentId + '?menuId=' + $route.query.menuId"><span class="fkr-set1">{{ prev.sbjct }}</span></a></div>
                        </div>
                        <div v-if="next" class="d-flex px-4 py-3 ls_bg1" style="border-bottom:1px solid #e9e9e9">
                            <div><i class="fa-solid fa-arrow-down fs-095 fc-w10 pe-3"></i></div>
                            <div><a :href="'/menu/projects/dtl/' + next.recentId + '?menuId=' + $route.query.menuId"><span class="fkr-set1">{{ next.sbjct }}</span></a></div>
                        </div>
                    </div>

                </div>

                <div>
                    <div class="x_div_center py-3">
                        <button class="fkr-set1 px-4 py-2" style="width:120px; border:0; background-color: #4e0000; color:#fff" @click="moveToList()" >{{lang == 'ko'? '목록' : 'List'}}</button>
                        <button class="fkr-set1 px-4 py-2" v-if="!isMobile" style="width:120px; border:1px solid #e9e9e9; margin-left: 20px" @click="moveToEdit()">{{lang == 'ko'? '수정' : 'Edit'}}</button>
                    </div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
import Api from '@/api'

export default {
    name: 'ProjectsDetail',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            article: {
                recentId: null,
                sbjct: '',
                cntnt: '',
                projectId: null,
                crtDt: ''
            },
            isMobile: false,
            prev: null,
            next: null,
            buildMode: process.env.NODE_ENV,
            proxyUrl: (process.env.NODE_ENV == 'local'? 'http://localhost:1025' : (process.env.NODE_ENV == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/proxypage'
        }
    },
    async mounted() {
        this.isMobile = window.innerWidth < 768;
        const rtnval = await Api.post((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/recent/find/recent/dtl', {recentId: this.$route.params.recentId});
        if(rtnval.result) {
            this.article = rtnval.data;
            if(this.isMobile) {
                this.article.cntnt = this.article.cntnt.replace(/width: 50%/g, "width: 100%");
                this.article.cntnt = this.article.cntnt.replace(/width: 25%/g, "width: 50%");
                this.article.cntnt = this.article.cntnt.replace(/width:50%/g, "width: 100%");
                this.article.cntnt = this.article.cntnt.replace(/width:25%/g, "width: 50%");
                window.$(document).ready(function(){
                    window.$('#articleContent img').css({'height': 'auto', 'margin-bottom': '20px'});
                });
            }
            else {
                window.$(document).ready(function(){
                    window.$('#articleContent img[style*="float: left"]').css('margin-right', '10px');
                    window.$('#articleContent img[style*="float: right"]').css('margin-left', '10px');
                    window.$('#articleContent img[style*="float:left"]').css('margin-right', '10px');
                    window.$('#articleContent img[style*="float:right"]').css('margin-left', '10px');
                    window.$('#articleContent p:has(img)').css('margin-block-end', 'unset');
                    window.$('#articleContent p.MsoNormal').attr('style', '');
                });
            }
            const rtnvalue = await Api.post((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/recent/find/project/dtl', {recentId: this.article.projectId});
            if(rtnvalue.result) {
                var recentList = rtnvalue.data.recentList;
                recentList.forEach((article, index) => {
                    if(article.recentId == this.$route.params.recentId) {
                        if(index > 0) {
                            this.prev = recentList[index-1];
                        }
                        if(index +1 < recentList.length) {
                            this.next = recentList[index+1];
                        }
                    }
                })
            }
            else {
                alert(rtnvalue.errormsg)
            }
        }
        else {
            alert(rtnval.errormsg)
        }
    },
    methods: {
        moveToList() {
            location.href = '/menu/projects/' + this.$route.query.menuId;
        },
        moveToEdit() {
            window.open('about:blank').location.href = this.proxyUrl + '?redirect=' + encodeURIComponent('/admin/site/recent-edit/' + this.$route.params.recentId + '?projectId=' + this.article.projectId)
        }
    }
}
</script>