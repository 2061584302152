<template>
    <div class = "container">
  
      <h1 class = "text-center">Some Page 2</h1>
      <br/>
      <h3 class = "text-center"> Log-in token: {{$store.state.token}}</h3>
      <br/>
      <table class = "table table-striped">
        <tbody>
            <tr>
                <td><router-link to="/somePage1">Some Page 1</router-link></td>
                <td><router-link to="/logon">Log On</router-link></td>
            </tr>
        </tbody>
        <br/>
        <table class = "table table-striped">
          <thead>
            <th>
                비밀번호 재설정
            </th>
          </thead>
          <tbody>
              <tr>
                <td><input type="text" v-model="emailInput" placeholder="이메일 계정"/></td>
                <td><button @click="validateEmail()">가입 확인</button></td>
              </tr>
              <tr>
                <td><input type="text" v-model="phoneInput" placeholder="핸드폰 번호"/></td>
                <td><button @click="requestAuth()">인증 요청</button></td>
              </tr>
              <tr>
                <td><input type="text" v-model="authInput" placeholder="인증번호"/></td>
                <td><button @click="sendTempPass()">임시비밀번호 발송</button></td>
              </tr>
            </tbody>
        </table>
      </table>
      <div style="align-content:center;">
        <button @click="logout()">Log out</button>
      </div>
    </div>
  </template>
  
  <script>
  import Auth from "@/api/auth"
  import Api from "@/api";
  
  export default {
    name: 'SomePage',
    data(){
      return {
        emailInput: '',
        phoneInput: '',
        authInput: ''
      }
    },
    async mounted() {
      if(!await Auth.isLogedIn()){
        this.$router.push('/');
      }
    },
    methods: {
      logout() {
        Auth.logOut();
        this.$router.push('/');
      },
      async validateEmail() {
        console.log( await Api.post("/api/v1/user/validateEmail", {email: this.emailInput}))
      },
      async requestAuth() {
        console.log( await Api.post("/api/v1/user/requestAuth", {phoneNumber: this.phoneInput}))
      },
      async sendTempPass() {
        console.log( await Api.post("/api/v1/user/sendTempPass", {authNumber: this.authInput}))
      },
    },
    created() {
    }
  }
  </script>
  
  <style scoped>
  
  </style>