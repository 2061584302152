import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import '@/assets/css/bootstrap.min.css'
import '@/assets/css/m3systems.css'
import '@/assets/css/m3_me.css'
import '@/assets/plugin/fontawesome-free-6.2.0-web/css/all.min.css'
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
