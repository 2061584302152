<template>
    <div class="m3_contetns">

        <!-- mypage 시작 { -->
        <div class="m_mem_box">

            <div style="width:90%">

                <div class="">
                    <div class="w-100 x_div_center pb-3"><h3 class="ff-kor1 fw-bold fc-w6">파일 업로드 / 다운로드</h3></div>
                </div>

                <div class="mib_a3" style="text-align: center;"><h3 class="fkr-set1 fc-w6">1개 이상의 프로젝트를 생성한 후 업로드를 진행해주세요.</h3></div>
                <div class="mib_box_b">
                    <div class="mib_set1 py-3">
                        <div class="mib_b3"><input type="file" class="form-control fkr-set2" style="width:100%; height:36px" @change="fileHandling"></div>
                    </div>
                </div>

                <img :src="imgfile" style="max-width: 800px;"/>

                <a id="alink" :href="fileLink" download style="display:none"></a>

                <div class="y_div_blank1rem"></div>

                <div class="x_div_center pt-4">
                    <div class="px-2"><button class="fkr-set1" @click="fileUpload()" style="width:150px; height:37px; border:0; background-color:#4f5962; color:#fff">파일 업로드</button></div>
                    <div class="px-2"><button class="fkr-set1" @click="fileDownload()" style="width:150px; height:37px; border:0; background-color:#4f5962; color:#fff">파일 다운로드</button></div>
                </div>

            </div>

        </div>
        <!-- } mypage 끝 -->

    </div>
</template>

<script>
import Auth from '@/api/auth'
import Api from '@/api'

export default {
    name: 'FileUpload',
    props: {
        user: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            file: '',
            fileLink: '',
            imgfile: ''

        }
    },
    async mounted() {
        if(!await Auth.isLogedIn()){
           location.href = '/member/login'
        }
    },
    methods: {
        fileHandling(e) {
            this.file = e.target.files[0];
        },
        fileDownload() {
            if(this.fileLink) {
                window.$('#alink')[0].click();
            }
        },
        async fileUpload() {
            var req = {}
            req['apOrgNm'] = this.file.name;
            req['dsgnId'] = 1;
            var rtnvalue = await Api.postFiles('/api/v1/dsgn/sm/save/dsgn/rcpt', {request: JSON.stringify(req), apAttchmnt: this.file})
            if(rtnvalue.result){
                var rtnval = await Api.get('/api/v1/dsgn/sm/find/dsgn/expns/list?dsgnId=1');
                if(rtnval.result){
                    var attchImg = rtnval.data.findDsgnExpnsDtoList[3].attchImg;
                    var tokens = rtnval.data.findDsgnExpnsDtoList[3].orgNm.split('.');
                    var mimeType = tokens[tokens.length-1];
                    var fileType = mimeType == 'pdf'? 'application/' + mimeType : 'image/' + mimeType;
                    this.imgfile = "data:"+fileType+";base64,"+attchImg

                    const byteCharacters = atob(attchImg);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], {type: fileType});
                    this.fileLink = URL.createObjectURL(blob);
                    this.imgfile = this.fileLink;
                }
            }
        }
    }
}
</script>