<template>
    <div id="m3_header">
        <div id="top"></div>
        <div class="x_div_center fixed-top" style="width:100%; background-color:#444; opacity:0.8">
            <div style="width:100%">
                <div class="mh_menu d-flex py-3" style="padding:0 1rem">

                    <!-- 반응형 메뉴 -->
                    <!-- <div class="mm1" v-if="showMenu">
                        <div><a href="javascript:void(0)" data-bs-toggle="collapse" :data-bs-target="showMenu? '#navbar_amg1' : '#navbar_amg2'" :aria-controls="showMenu? 'navbar_amg1' : 'navbar_amg2'" aria-expanded="false" aria-label="Toggle navigation"><i class="fa-sharp fa-solid fa-bars fc-w1"></i></a></div>
                    </div> -->
                    <div class="mm1">
                        <div><a href="javascript:void(0)"><i class="fa-sharp fa-solid fa-bars fc-w1"></i></a></div>
                    </div>

                    <!-- 로고 -->
                    <div class="hm1"><a href="/"><img src="@/assets/img/m3systems.png"></a></div>

                    <!-- 메인메뉴 -->
                    <div class="hm2">
                        <div class="d-flex" v-if="showMenu">
                            <div :class="'menu_a' + (isMenuSeleted(0)? ' under_line' : '')" :style="'margin-right:20px; width:' + (lang == 'ko'? '75px' : '143px')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '소개' : 'About Us' }}</span></a></div>
                            <div :class="'menu_a' + (isMenuSeleted(2)? ' under_line' : '')" :style="'margin-right:20px; width:' + (lang == 'ko'? '100px' : '143px')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '프로젝트' : 'Projects' }}</span></a></div>
                            <div :class="'menu_a' + (isMenuSeleted(1)? ' under_line' : '')" :style="'margin-right:20px; width:' + (lang == 'ko'? '90px' : '143px')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '파트너' : 'Partners' }}</span></a></div>
                            <div :class="'menu_a' + (isMenuSeleted(5)? ' under_line' : '')" :style="'margin-right:20px; width:' + (lang == 'ko'? '90px' : '143px')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '포트폴리오' : 'Portfolio' }}</span></a></div>
<!--                            <div :class="'menu_a' + (isMenuSeleted(3)? ' under_line' : '')" :style="'margin-right:20px; width:' + (lang == 'ko'? '100px' : '143px')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '토지추천' : 'Recommend Site' }}</span></a></div>-->
                            <div :class="'menu_a' + (isMenuSeleted(6)? ' under_line' : '')" :style="'margin-right:20px; width:' + (lang == 'ko'? '75px' : '143px')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '성과' : 'Outcome' }}</span></a></div>
                            <div :class="'menu_a' + (isMenuSeleted(4)? ' under_line' : '')"><a class="fc-w1" href="#@"><span class="fkr-set1 fs-095">{{ lang == 'ko'? '고객지원' : 'Support' }}</span></a></div>
                        </div>
                    </div>

                    <!-- 우측메뉴 시작 { -->
                    <div class="hm3 x_div_end">
                        <div class="x_div_end">
                            <div class="x_div_center">
<!--                                <div class="v-container" v-if="isLogedIn">-->
<!--                                    <div class="px-1 hma2"><a class="fc-w1" href="javascript:void(0)"><span class="fkr-set1">{{ user.info.fullName + (lang == 'ko'? '님 환영합니다' : '') }}</span></a></div>-->
<!--                                    <div class="px-1 hma2 dropdown no-arrow">-->
<!--                                        <div class="fc-w1 dropdown-toggle" href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"><i class="fa-regular fa-circle-user fc-w1"></i></div>-->
<!--                                        <div class="dropdown-menu" style="width:120px; margin:18px 0 0 -50px">-->
<!--                                            <a href="/admin" class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9" v-if="adminYn != 'N'"><span class="fen-set1 rc-2">ADMIN</span></a>-->
<!--                                            <a href="/panda" class="dropdown-item x_div_center py-2" style="border-bottom:1px solid #d9d9d9" v-if="user.info.cnslrLv != null"><span class="fen-set1 rc-2">PANDA</span></a>-->
<!--                                            <a href="/member/profile" class="dropdown-item x_div_center py-2"><span class="fen-set1 rc-2">PROFILE</span></a>-->
<!--                                            <a @click="logout()" class="dropdown-item x_div_center py-2" style="border-top:1px solid #d9d9d9"><span class="fen-set1 rc-2">LOGOUT</span></a>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>-->
<!--                                </div>-->
<!--                                <div class="v-container" v-else>-->
<!--                                    <div class="px-1 hma2"><a class="fc-w1" href="#" @click="login();"><span class="fen-set1">LOGIN</span></a></div>-->
<!--                                    <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>-->
<!--                                </div>-->
<!--                                <div class="v-container" v-if="isLogedIn">-->
<!--                                    <div class="px-1 hma2"><a class="fc-w1" href="/project/dashboard"><span class="fen-set1">{{adminYn != 'N'? 'PRJ' : 'MY'}}</span></a></div>-->
<!--                                    <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>-->
<!--                                </div>-->
                                <div class="px-1 hma2"><a class="fc-w1" href="https://buildmarket.kr/cs/inquiry" target="_blank"><i class="fa-regular fa-circle-question"></i></a></div>
                                <div class="px-1 hma2"><span class="fen-set1 fs-075 fc-w3">│</span></div>
                                <div class="px-1 dropdown no-arrow">
                                    <div class="fc-w1 dropdown-toggle" href="#" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"><i class="fa-solid fa-earth-americas"></i></div>
                                    <div class="dropdown-menu" style="width:100px; margin-top:18px">
                                        <div class="dropdown-item x_div_center py-2"><a @click="toggleLanguage('ko')" class="fen-set1 rc-2" href="javascript:void(0)">KOR</a></div>
                                        <div class="dropdown-item x_div_center py-2" style="border-top:1px solid #d9d9d9"><a @click="toggleLanguage('en')" class="fen-set1 rc-2" href="javascript:void(0)">ENG</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- } 우측메뉴 끝 -->
                </div>

                <!-- 레이어메뉴 시작 { -->
                <div class="sub_1" style="background-color:#000">
                    <div class="w-100" style="padding:0 1rem">
                        <div class="d-flex py-4" v-if="lang == 'ko'">
                            <div class="hm1"><a href="/"/></div>
                            <div class="hm2 d-flex">
                                <div class="menu_category" style="width:95px">
                                    <div class="py-1"><a class="fc-w1" href="/menu/introduction"><span class="fkr-set2">M3소개</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/modular"><span class="fkr-set2">M3모듈러</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/members"><span class="fkr-set2">임원진소개</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/organization"><span class="fkr-set2">조직도</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/location"><span class="fkr-set2">오시는길</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/career"><span class="fkr-set2">인재채용</span></a></div>
                                </div>
                                <div class="menu_category" style="width:120px">
                                    <div class="py-1" v-for="(menu, index) in projectsMenuList" :key="index"><a class="fc-w1" :href="'/menu/projects/' + menu.id"><span class="fkr-set2">{{ menu.menuName }}</span></a></div>
                                </div>
                                <div class="menu_category" style="width:110px">
                                    <div class="py-1"><a class="fc-w1" href="/menu/join-partner"><span class="fkr-set2">파트너 지원</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/partners"><span class="fkr-set2">파트너기관 소개</span></a></div>
                                </div>
                                <div class="menu_category" style="width:110px">
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/mstudio"><span class="fkr-set2">M Studio</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/mhouse"><span class="fkr-set2">M House</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/prj01"><span class="fkr-set2">M Commercial</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/shingaloffice"><span class="fkr-set2">M Office</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/mschool"><span class="fkr-set2">M School</span></a></div>
                                </div>
<!--                                <div class="menu_category" style="width:120px">-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/build-service"><span class="fkr-set2">서비스 소개</span></a></div>-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/process"><span class="fkr-set2">프로세스 소개</span></a></div>-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/project/plan"><span class="fkr-set2">기획하기</span></a></div>-->
<!--                                </div>-->
<!--                                <div class="menu_category" style="width:120px">-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/site-service"><span class="fkr-set2">서비스 소개</span></a></div>-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/find-site"><span class="fkr-set2">M3 제공 토지찾기</span></a></div>-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/provide-site"><span class="fkr-set2">토지 제공하기</span></a></div>-->
<!--                                </div>-->
                                <div class="menu_category" style="width:95px">
                                    <div class="py-1"><a class="fc-w1" href="/menu/certificates"><span class="fkr-set2">인증서 및 특허</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/news"><span class="fkr-set2">뉴스</span></a></div>
                                    <!-- <div class="py-1"><a class="fc-w1" href="/menu/recentlist"><span class="fkr-set2">사업현황</span></a></div> -->
                                </div>
                                <div class="menu_category">
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/faq"><span class="fkr-set2">FAQ</span></a></div>-->
<!--                                    <div class="py-1"><a class="fc-w1" href="/menu/question"><span class="fkr-set2">문의하기</span></a></div>-->
                                    <div class="py-1"><a class="fc-w1" href="/menu/download"><span class="fkr-set2">M3 다운로드 자료실</span></a></div>
                                </div>
                            </div>
                            <div class="hm3"></div>
                        </div>
                        <div class="d-flex py-4" v-else-if="lang == 'en'">
                            <div class="hm1"><a href="/"/></div>
                            <div class="hm2 d-flex">
                                <div class="menu_category" style="width:163px">
                                    <div class="py-1"><a class="fc-w1" href="/menu-en/introduction"><span class="fkr-set2">Introduction</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu-en/modular"><span class="fkr-set2">M3 Modular</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/members"><span class="fkr-set2">Executive Team Introduction</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/organization"><span class="fkr-set2">Organization</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu-en/location"><span class="fkr-set2">Location</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/career"><span class="fkr-set2">Recruitment</span></a></div>
                                </div>
                                <div class="menu_category" style="width:163px">
                                    <div class="py-1" v-for="(menu, index) in projectsMenuList" :key="index"><a class="fc-w1" :href="'/menu/projects/' + menu.id"><span class="fkr-set2">{{ menu.menuNameEn }}</span></a></div>
                                </div>
                                <div class="menu_category" style="width:163px">
                                    <div class="py-1"><a class="fc-w1" href="/menu-en/join-partner"><span class="fkr-set2">About Partnership</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/partners"><span class="fkr-set2">Partner Companies</span></a></div>
                                </div>
                                <div class="menu_category" style="width:163px">
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/mstudio"><span class="fkr-set2">M Studio</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/mhouse"><span class="fkr-set2">M House</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/prj01"><span class="fkr-set2">M Commercial</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/shingaloffice"><span class="fkr-set2">M Office</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/portfolio/mschool"><span class="fkr-set2">M School</span></a></div>
                                </div>
                                <div class="menu_category" style="width:163px">
                                    <div class="py-1"><a class="fc-w1" href="/menu/certificates"><span class="fkr-set2">Certificates and Patents</span></a></div>
                                    <div class="py-1"><a class="fc-w1" href="/menu/news"><span class="fkr-set2">News</span></a></div>
                                    <!-- <div class="py-1"><a class="fc-w1" href="/menu/recentlist"><span class="fkr-set2">Recent Business</span></a></div> -->
                                </div>
                                <div class="menu_category">
                                    <div class="py-1"><a class="fc-w1" href="/menu/download"><span class="fkr-set2">M3 Download</span></a></div>
                                </div>
                            </div>
                            <div class="hm3"></div>
                        </div>
                    </div>
                </div>
                <!-- } 레이어메뉴 끝 -->

                <!-- 반응형 레이어메뉴 시작(일반메뉴) { -->
                <div v-if="showMenu" class="m_nav" id="navbar_amg1" style="background-color:#111">
                    <div class="py-2" v-if="lang == 'ko'">

                        <!-- <div class="px-3 py-2" style="border-bottom:1px solid #00">
                            <div class="x_div_center p-1" v-if="isLogedIn" style="display: flex; justify-content: space-between;">
                                <div class=""><div class="fkr-set1 fs-095 fc-w8">{{ user.info.fullName }}</div></div>
                                <div class="x_div_center p-1">
                                    <div class="" style="width:auto"><div class="x_div_center box-type-1" v-if="user.info.cnslrLv != null"><a href="/panda"><span class="fkr-set1 fc-w3"><i class="fas fa-headset"></i></span></a></div></div>
                                    <div class="" style="width:auto"><div class="x_div_center box-type-1"><a href="/project/dashboard"><span class="fkr-set1 fc-w3">MY</span></a></div></div>
                                    <div class="" style="width:auto"><div class="x_div_center box-type-1"><a href="/member/profile"><span class="fkr-set1 fc-w3">내정보관리</span></a></div></div>
                                    <div class="" style="width:auto"><div class="x_div_center box-type-1"><a  @click="logout()"><span class="fkr-set1 fc-w3">로그아웃</span></a></div></div>
                                </div>
                            </div>
                            <div class="x_div_center p-1" v-else>
                                <div class="w-100"><div class="fkr-set1 fs-095 fc-w8">로그인이 필요합니다</div></div>
                                <div class="" style="width:80px"><div class="x_div_center box-type-1"><a href="#" @click="login();"><span class="fkr-set1 fc-w3">로그인</span></a></div></div>
                            </div>
                        </div> -->

                        <div class="px-3 py-2" style="border-top:1px solid #222">
                            <div class="d-felx">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>소개</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:25%"><a class="fc-w1" href="/menu/introduction"><span class="fkr-set2">M3소개</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"><a class="fc-w1" href="/menu/modular"><span class="fkr-set2">M3모듈러</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"><a class="fc-w1" href="/menu/members"><span class="fkr-set2">임원진소개</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"><a class="fc-w1" href="/menu/organization"><span class="fkr-set2">조직도</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"><a class="fc-w1" href="/menu/location"><span class="fkr-set2">오시는길</span></a></div>
                            </div>
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"><a class="fc-w1" href="/menu/career"><span class="fkr-set2">인재채용</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"></div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>프로젝트</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3" v-for="(bucket, index) in menuBuckets" :key="index">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;" v-for="(menu, kintex) in bucket" :key="kintex">
                                    <a class="fc-w1" :href="'/menu/projects/' + menu.id"><span class="fkr-set2">{{ menu.menuName }}</span></a>
                                </div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>파트너</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;"><a class="fc-w1" href="/menu/join-partner"><span class="fkr-set2">파트너 지원</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu/partners"><span class="fkr-set2">파트너기관 소개</span></a></div>
                                <div class="x_div_center p-1" style="width:25%; border:0px solid #222; margin-left:-1px"></div>
                                <div class="x_div_center p-1" style="width:25%; border:0px solid #222; margin-left:-1px"></div>
                            </div>
                        </div>

                        <div>
                            <div class="px-3 py-2">
                                <div class="">
                                    <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>포트폴리오</div></div>
                                </div>
                            </div>

                            <div class="px-3 pb-3">
                                <div class="x_div_center" style="border-bottom:1px solid #222">
                                    <div class="x_div_center p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/mstudio"><span class="fkr-set2">M Studio</span></a></div>
                                    <div class="x_div_center p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/mhouse"><span class="fkr-set2">M House</span></a></div>
                                    <div class="x_div_center p-1 ls_bg2" style="width:29%; padding-left: 0 !important; padding-right: 0 !important;"><a class="fc-w1" href="/menu/portfolio/prj01"><span class="fkr-set2">M Commercial</span></a></div>
                                    <div class="x_div_center p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/shingaloffice"><span class="fkr-set2">M Office</span></a></div>
                                    <div class="x_div_center p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/mschool"><span class="fkr-set2">M School</span></a></div>
                                </div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>성과</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu/certificates"><span class="fkr-set2">인증서 및 특허</span></a></div>
                                <div class="x_div_center p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu/news"><span class="fkr-set2">뉴스</span></a></div>
                                <!-- <div class="x_div_center p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu/recentlist"><span class="fkr-set2">사업현황</span></a></div> -->
                                <div class="x_div_center p-1" style="width:25%; border:0px solid #222; margin-left:-1px"></div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>고객지원</div></div>
                            </div>
                        </div>

                        <div class="px-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:50%; margin-left:-1px"><a class="fc-w1" href="/menu/download"><span class="fkr-set2">M3 다운로드 자료실</span></a></div>
                                <div class="x_div_center p-1" style="width:50%; border:0px solid #222; margin-left:-1px"></div>
                            </div>
                        </div>

                        <div class="x_div_center" style="height:50px"><div class="box-type-1"><a href="#@" data-bs-toggle="collapse" data-bs-target="#navbar_amg1" aria-controls="navbar_amg1" aria-expanded="false" aria-label="Toggle navigation"><i class="fa-solid fa-xmark fc-w9 fs-13 x_div_center"></i></a></div></div>

                    </div>

                    <div class="py-2" v-else-if="lang == 'en'">

                        <div class="px-3 py-2" style="border-top:1px solid #222">
                            <div class="d-felx">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>About Us</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_left p-1 ls_bg2" style="width:25%"><a class="fc-w1" href="/menu-en/introduction"><span class="fkr-set2">Introduction</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu-en/modular"><span class="fkr-set2">M3 Modular</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:50%; margin-left:-1px"><a class="fc-w1" href="/menu/members"><span class="fkr-set2">Executive Team Introduction</span></a></div>
                            </div>
                        </div>
                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_left p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu/organization"><span class="fkr-set2">Organization</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:25%; margin-left:-1px"><a class="fc-w1" href="/menu-en/location"><span class="fkr-set2">Location</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:50%; margin-left:-1px"><a class="fc-w1" href="/menu/career"><span class="fkr-set2">Recruitment</span></a></div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>Projects</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3" v-for="(bucket, index) in menuBuckets" :key="index">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_center p-1 ls_bg2" style="width:25%;" v-for="(menu, kintex) in bucket" :key="kintex">
                                    <a class="fc-w1" :href="'/menu/projects/' + menu.id"><span class="fkr-set2">{{ menu.menuNameEn }}</span></a>
                                </div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>Partners</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_left p-1 ls_bg2" style="width:33.3333%;"><a class="fc-w1" href="/menu-en/join-partner"><span class="fkr-set2">About Partnership</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:33.3333%; margin-left:-1px"><a class="fc-w1" href="/menu/partners"><span class="fkr-set2">Partner Companies</span></a></div>
                                <div class="x_div_left p-1" style="width:33.3333%; border:0px solid #222; margin-left:-1px"></div>
                                <!--div class="x_div_center p-1" style="width:25%; border:0px solid #222; margin-left:-1px"></div-->
                            </div>
                        </div>

                        <div>
                            <div class="px-3 py-2">
                                <div class="">
                                    <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>Portfolio</div></div>
                                </div>
                            </div>

                            <div class="px-3 pb-3">
                                <div class="x_div_center" style="border-bottom:1px solid #222">
                                    <div class="x_div_left p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/mstudio"><span class="fkr-set2">M Studio</span></a></div>
                                    <div class="x_div_left p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/mhouse"><span class="fkr-set2">M House</span></a></div>
                                    <div class="x_div_left p-1 ls_bg2" style="width:29%; padding-left: 0 !important; padding-right: 0 !important;"><a class="fc-w1" href="/menu/portfolio/prj01"><span class="fkr-set2">M Commercial</span></a></div>
                                    <div class="x_div_left p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/shingaloffice"><span class="fkr-set2">M Office</span></a></div>
                                    <div class="x_div_left p-1 ls_bg2" style="width:24%;"><a class="fc-w1" href="/menu/portfolio/mschool"><span class="fkr-set2">M School</span></a></div>
                                </div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>Outcome</div></div>
                            </div>
                        </div>

                        <div class="px-3 pb-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_left p-1 ls_bg2" style="width:50%; margin-left:-1px"><a class="fc-w1" href="/menu/certificates"><span class="fkr-set2">Certificates and Patents</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:30%; margin-left:-1px"><a class="fc-w1" href="/menu/news"><span class="fkr-set2">News</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:30%; margin-left:-1px"><a class="fc-w1" href="/menu/recentlist"><span class="fkr-set2">Recent Business</span></a></div>
                            </div>
                        </div>

                        <div class="px-3 py-2">
                            <div class="">
                                <div class="px-3 py-2" style="background-color:#000"><div class="fkr-set1 fs-095 fc-w3"><i class="fa-solid fa-feather-pointed pe-2"></i>Support</div></div>
                            </div>
                        </div>

                        <div class="px-3">
                            <div class="x_div_center" style="border-bottom:1px solid #222">
                                <div class="x_div_left p-1 ls_bg2" style="width:50%; margin-left:-1px"><a class="fc-w1" href="/menu/download"><span class="fkr-set2">M3 Download</span></a></div>
                                <div class="x_div_left p-1 ls_bg2" style="width:50%; margin-left:-1px"></div>
                            </div>
                        </div>

                        <div class="x_div_center" style="height:50px"><div class="box-type-1"><a href="#@" data-bs-toggle="collapse" data-bs-target="#navbar_amg1" aria-controls="navbar_amg1" aria-expanded="false" aria-label="Toggle navigation"><i class="fa-solid fa-xmark fc-w9 fs-13 x_div_center"></i></a></div></div>

                    </div>
                </div>
                <!-- } 반응형 레이어메뉴 끝(일반메뉴)) -->

                <!-- 반응형 레이어메뉴 시작(프로젝트메뉴) { -->
                <div v-else class="m_nav" id="navbar_amg2">

                    <!-- 로그인 -->
                    <div class="px-3 py-2" style="border-bottom:1px solid #e1e1e1">
                        <div class="x_div_center p-1" v-if="isLogedIn" style="display: flex; justify-content: space-between;">
                            <div class=""><div class="fkr-set1 fc-w10">{{ user.info.fullName }}</div></div>
                            <div class="x_div_center p-1">
                                <div class="" style="width:auto"><div class="x_div_center box-type-2" v-if="user.info.cnslrLv != null"><a href="/panda"><span class="fkr-set1 fc-w3"><i class="fas fa-headset"></i></span></a></div></div>
                                <div class="" style="width:auto"><div class="x_div_center box-type-2"><a href="/member/profile"><span class="fkr-set2">내정보관리</span></a></div></div>
                                <div class="" style="width:auto"><div class="x_div_center box-type-2" v-if="user.info.cnslrLv == null"><a href="/menu/question"><span class="fkr-set2">문의하기</span></a></div></div>
                                <div class="" style="width:auto"><div class="x_div_center box-type-2"><a  @click="logout()"><span class="fkr-set2">로그아웃</span></a></div></div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- 메인메뉴 -->
                    <ul class="accordion p-4">
                        <li class="d-flex px-2 py-3"><img src="@/assets/img/ms_m1_on.png" :class="'pe-2' + (url.indexOf('/project/dashboard') > 0 ? '' : ' off')"> <a href="/project/dashboard"><span class="fen-set1 fs-095" style="color:#4e0000">My Projects</span></a></li>
                        <div :style="dashboard? 'pointer-events: none; opacity: 0.3' : ''">
                            <li class="d-flex px-2 py-3"><img src="@/assets/img/ms_m2_on.png" :class="'pe-2' + (url.indexOf('/project/summary/') > 0 ? '' : ' off')"> <a :href="'/project/summary/' + prjId"><span class="fkr-set1 fs-09" style="color:#4e0000">개요</span></a></li>

                            <div v-if="prcAuth.indexOf(prc0.code) >= 0">
                                <!-- 설계 -->
                                <li class="d-flex px-2 accordion-header" style="cursor:pointer">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#am_3" aria-expanded="true" aria-controls="am_3"><img src="@/assets/img/ms_m3_on.png" :class="'pe-2' + (url.indexOf('/project/' + prc0.code) > 0 ? '' : ' off')"> <span class="fkr-set1 fs-09">{{ prc0.name }}</span></button>
                                </li>
                                <li id="am_3" :class="'accordion-collapse collapse' + (url.indexOf('/project/' + prc0.code) > 0 ? ' show' : '')">
                                    <div class="accordion-body py-3">
                                        <ul>
                                            <li :class="'w-100 p-2 ps-3 ms_bg1' + (url.indexOf('/project/' + prc0.code + '/summary') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc0.code + '/summary'">개요</a></li>
                                            <li v-for="(prcStp, index) in prc0.prcStpList" :key="index"
                                                 :class="'w-100 p-2 ps-3 ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prc0.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc0.code + '/' + prcStp.code + '/dsgn'">{{ prcStp.name }}</a>
                                            </li>
                                            <li :class="'w-100 p-2 ps-3 ms_bg' + (dsgnCompleted? '1' : '2') + (url.indexOf('/project/' + prc0.code + '/design-info') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc0.code + '/dsgn-info'">설계요약</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </div>

                                <div v-if="prc8">
                                    <!-- 제조/시공/인테리어 -->
                                    <li class="d-flex px-2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#am_4" aria-expanded="true" aria-controls="am_4"><img src="@/assets/img/ms_m4_on.png" :class="'pe-2' + (currentPrcCd.indexOf(prcCd) >= 0 ? '' : ' off')"> <span class="fkr-set1 fs-09">{{ prcTitles }}</span></button></li>
                                </div>
                                
                                <li id="am_4" :class="'accordion-collapse collapse' + (currentPrcCd.indexOf(prcCd) >= 0 ? ' show' : '')">
                                    <div class="accordion-body py-3">
                                        <div v-for="(prc, index) in prc" :key="index">
                                            <div v-if="prcAuth.indexOf(prc.code) >= 0">
                                                <div><span class="fkr-set1 fs-09 fc-w10">{{ prc.name }}</span></div>
                                                <ul>
                                                    <li :class="'w-100 p-2 ps-3 ms_bg1' + (url.indexOf('/project/' + prc.code + '/summary') > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc.code + '/summary'">개요</a></li>
                                                    <li v-for="(prcStp, kintex) in prc.prcStpList" :key="kintex"
                                                        :class="'w-100 p-2 ps-3 ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prc.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prc.code + '/' + prcStp.code">{{ prcStp.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <!-- 운송/설치/유지보수 -->
                                <div v-for="(prcs, index) in prcs" :key="index">
                                    <div v-if="prcAuth.indexOf(prcs.code) >= 0">
                                        <li class="d-flex px-2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#am_' + prcs.orderNum+1" aria-expanded="true" :aria-controls="'am_' + prcs.orderNum+1"><img :src="'/./files/img/ms_m' + (prcs.orderNum+1) + '_on.png'" :class="'pe-2' + (url.indexOf('/project/' + prcs.code) > 0 ? '' : ' off')"> <span class="fkr-set1 fs-09">{{ prcs.name }}</span></button></li>
                                        <li :id="'am_' + prcs.orderNum+1" :class="'accordion-collapse collapse' + (url.indexOf('/project/' + prcs.code) > 0 ? ' show' : '')">
                                            <div class="accordion-body py-3">
                                                <ul>
                                                    <li v-for="(prcStp, kintex) in prcs.prcStpList" :key="kintex"
                                                        :class="'w-100 p-2 ps-3 ms_bg' + (prcStp.started? '1' : '2') + (url.indexOf('/project/' + prcs.code + '/' + prcStp.code) > 0 ? '_on' : '')" style="border:0"><a class="ms_fa1" :href="'/project/' + prcs.code + '/' + prcStp.code">{{ prcStp.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </div>
                                </div>
                        </div>
                    </ul>

                </div>
                <!-- 반응형 레이어메뉴 끝(프로젝트메뉴) { -->
            </div>
        </div>
        <div v-if="!showMenu" style="height:50px"></div>
        <a v-if="!home" href="#top" class="scrollToTop" id="click"><img src="@/assets/img/top.png" width="49px" height="50px"></a>
        <span v-else id="infoLink" onclick="window.open('about:blank').location.href = 'https://buildmarket.kr/cs/inquiry'"><i class="fas fa-headset"></i></span>
    </div>
</template>

<script>
// import Auth from '@/api/auth'
import Api from '@/api'
export default {
  name: 'HeaderComponent',
  props: {
    user: {
        type: Object,
        default: null
    },
    lang: {
        type: String,
        default: 'ko'
    },
    isMobileDevice: {
        type: Boolean,
        default: false
    }
  },
  data(){
    return {
        isLogedIn: false,
        naverLogin: null,
        showMenu: true,
        dashboard: false,
        home: false,
        adminYn: 'N',
        prjId: null,
        url: '',
        prc0: {
            code: '',
            name: '',
            orderNum: 0,
            prcStpList: []
        },
        prc: [],
        prcs: [],
        prcCd: [],
        prcAuth: [],
        prc8: false,
        currentPrcCd: '',
        prcTitles: '',
        dsgnCompleted: false,
        projectsMenuList: [],
        menuBuckets: [],
        buildMode: process.env.NODE_ENV
    }
  },
  async created() {
    this.url = location.href;
    if(this.url.indexOf('/home') >= 0 || this.url.split('/')[this.url.split('/').length-1] == '') {
        this.home = true;
    }
    // if(this.url.indexOf('/project') >= 0 || this.url.indexOf('/admin') >= 0){
    //     this.showMenu = false;
    // }
    // if(this.url.indexOf('/project/dashboard') >= 0 || this.url.indexOf('/project/bidding') >= 0){
    //     this.dashboard = true;
    // }

    // if(this.lang == 'en'){
    //     await Api.post('/api/v1/common/change/locale?language=' + this.lang)
    // }

    this.togglePageLang(this.lang);

    const rtnval = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/recent/menu/list')
    if(rtnval.result) {
        var bucket = [];
        for(var i=0; i<rtnval.data.length; i++) {
            this.projectsMenuList.push(rtnval.data[i]);
            bucket.push(rtnval.data[i]);
            if(i % 4 == 3) {
                this.menuBuckets.push(bucket);
                bucket = [];
            }
        }
        for(; i % 4 < 0 && i % 4 <= 3; i++) {
            bucket.push({id: null, menuName: null, menuNameEn: null});
            if(i % 4 == 3) {
                this.menuBuckets.push(bucket);
                bucket = [];
            }
        }
    }
    else {
        alert(rtnval.errormsg);
    }

  },
  mounted() {
    if(!this.showMenu) {
        window.$('.fixed-top').css('opacity', 1);
    }
    window.$('.mm1').click(() => {
        if(this.showMenu) {
            if(window.$('#navbar_amg1').css('height') == '0px'){
                window.$('#navbar_amg1').css('height', '100%');
            }
            else {
                window.$('#navbar_amg1').css('height', 0);
            }
        }
        else {
            if(window.$('#navbar_amg2').css('height') == '0px'){
                window.$('#navbar_amg2').css('height', '100%');
            }
            else {
                window.$('#navbar_amg2').css('height', 0);
            }
        }
    })

    // this.isLogedIn = await Auth.isLogedIn();

    window.$(".menu_a").mouseenter(function() {
		window.$(".sub_1").stop().slideDown(300);
	});

	window.$('.sub_1').mouseleave(function(){
		window.$(".sub_1").stop().slideUp(300);
	});

    // if(this.user){
    //     var rtnval = await Api.get('/api/v1/admin/usr/mng/find/admin/usr?usrId=' + this.user.id);
    //     if(rtnval.result && rtnval.data.adminYn){
    //         this.adminYn = rtnval.data.adminYn;
    //     }
    // }

    this.prjId = sessionStorage.getItem('prjId');
    if(this.prjId && this.prjId != 'null') {
        this.currentPrcCd = this.url.split('/project/')[1].split('/')[0];
        this.prcAuth = JSON.parse(sessionStorage.getItem('accessiblePrc'));
        const prcTree = JSON.parse(sessionStorage.getItem('prcTree'));
        prcTree.processList.forEach((prc, index) => {
            if(index == 0) {
                this.prc0 = prc;
            }
            else {
                this.prcTitles += prc.name + (index+1 < prcTree.processList.length? ' / ' : '');
                this.prc.push(prc);
                this.prcCd.push(prc.code);
                if(!this.prc8) {
                    this.prc8 = this.prcAuth.indexOf(prc.code) >= 0? true : false;
                }
            }
        })
        prcTree.prcScndryList.forEach((prcs) => {
            this.prcs.push(prcs);
        })

        // const rtnval = await Api.get('/api/v1/project/find/prj/dtl/smmry?prjId=' + this.prjId);
        // if(rtnval.result) {
        //     rtnval.data.prcInfoDto.forEach((dto, index) => {
        //         if(index == 0) {
        //             this.prc0.prcStpList.forEach((prcStp) => {
        //                 prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
        //             })
        //             if(dto.prcStpOrdNumMax+1 == this.prc0.prcStpList.length && dto.prcStts.indexOf("완료") >= 0) {
        //                 this.dsgnCompleted = true;
        //             }
        //         }
        //         else {
        //             this.prc[index-1].prcStpList.forEach((prcStp) => {
        //                 prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
        //             })
        //             if(dto.prcTyp == 'MA') {
        //                 this.prcs.forEach((prcs) => {
        //                     prcs.prcStpList.forEach((prcStp) => {
        //                         prcStp.started = prcStp.orderNum <= dto.prcStpOrdNumMax? true : false;
        //                     })
        //                 })
        //             }
        //         }
        //     })
        //     if(rtnval.data.prj.prjCmpltnYn) {
        //         this.prcs[this.prcs.length-1].prcStpList.forEach((prcStp) => {
        //             prcStp.started = true;
        //         })
        //     }
        //     this.$forceUpdate();
        // }
        // else {
        //     alert(rtnval.errormsg)
        // }
    }

    this.naverLogin = new window.naver.LoginWithNaverId(
        {
            clientId: process.env.VUE_APP_NAVER_OAUTH_CLIENT_ID,
            callbackUrl: process.env.VUE_APP_NAVER_OAUTH_REDIRECT_URI,
            isPopup: this.isMobileDevice? false : true,
        }
    );
    
    this.naverLogin.init();
  },
  methods: {
    isMenuSeleted(i) {
        var currentMenu = 'home';
        if(this.url.indexOf('/menu/') > 0) {
            currentMenu = this.url.split('/menu/')[1];
        }
        else if(this.url.indexOf('/menu-en/') > 0) {
            currentMenu = this.url.split('/menu-en/')[1];
        }
        // else if(this.url.indexOf('/project/plan') > 0) {
        //     currentMenu = 'plan';
        // }

        var rtnval = false;
        switch(i) {
            case 0 : if(['introduction','location','modular','members','career'].indexOf(currentMenu) >= 0) rtnval = true;
                break;
            case 1 : if(['join-partner','partners'].indexOf(currentMenu) >= 0) rtnval = true;
                break;
            // case 2 : if(['build-service','process','plan'].indexOf(currentMenu) >= 0) rtnval = true;
            case 2 : if(['projects'].indexOf(currentMenu) >= 0) rtnval = true;
                break;
            case 3 : if(['site-service','find-site','provide-site'].indexOf(currentMenu) >= 0) rtnval = true;
                break;
            case 4 : if(['faq','question','download'].indexOf(currentMenu) >= 0) rtnval = true;
                break;
            case 5 : if(this.url.indexOf('/portfolio') >= 0) rtnval = true;
                break;
            case 6 : if(['certificates','news','recentlist'].indexOf(currentMenu) >= 0) rtnval = true;
                break;
        }
        return rtnval;
    },
    login() {
        this.naverLogin.logout();
        location.href = '/member/login';
    },
    // async logout() {
    //     await Auth.logOut();
    //     this.naverLogin.logout();
    //     location.href = '/';
    // },
    async toggleLanguage(language) {
        this.$emit('toggleLanguage', language);
        //await Api.post('/api/v1/common/change/locale?language=' + language)
        this.togglePageLang(language);
    },
    togglePageLang(lang) {
        if(this.url.indexOf('/menu-en/') >= 0 && lang == 'ko'){
            location.href = this.url.replace('/menu-en/', '/menu/');
        }
        if(this.url.indexOf('/menu/') >= 0 && lang == 'en'){
            var token = this.url.split('/');
            var arr = ['introduction', 'location', 'modular', 'join-partner', 'service', 'process'];
            if(arr.indexOf(token[token.length-1]) >= 0) {
                location.href = this.url.replace('/menu/', '/menu-en/');
            }
        }
    }
  },
}
</script>

<style scoped>
.v-container {
    display: flex;
}

#navbar_amg1 {
    position:fixed;
    width: 100%;
    height: 0;
    overflow:auto;
    transition: 0.2s;
}

#navbar_amg2 {
    background-color:#fff;
    position:fixed;
    width: 100%;
    height: 0;
    overflow:auto;
    transition: 0.2s;
}

.m_nav ul {
  padding:0px;
	margin:0px;
	list-style-type:none;
}

.m_nav li {
  width:100%;
  padding:0px;
	margin:0px;
	list-style-type:none;
	/* display:block; */
	border-bottom:1px solid #e1e1e1;
}

.pe-2.off {
    opacity: 0.3;
}

.scrollToTop {
	position:fixed;
	right:20px;
	bottom:50px;
	display:block;
	z-index:999;
}
@media only screen and (max-width: 768px) {
    [class="scrollToTop"] {
        display: none;
    }
}

.under_line {
    border-bottom: 3px solid #ffff;
}

#infoLink {
    position:fixed;
    right:20px;
    bottom:50px;
    display:block;
    z-index:999;
    color: #ffffff;
    font: 600 34px / 25px "Pretendard";
    border-radius: 50px;
    background: #4e0000;
    width: 60px;
    height: 60px;
    cursor: pointer;
    padding: 13px;
}
</style>
