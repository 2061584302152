<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">{{ lang == 'ko'? 'M3 다운로드 자료실' : 'M3 Download'}}</span><br>
                    <span class="dh_p1c"></span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- M3 다운로드 자료실 -->
                <div class="py-3">
                    <div v-for="(item, i) in showListItems" :key="i" class="iw-1">
                        <div v-for="(jtem, j) in item" :key="j" class="iw-3 x_div_center" style="align-items:start;">
                            <div v-for="(ktem, k) in jtem" :key="k" class="iw-3 px-1 py-2">
                                <div v-if="ktem" class="p-1" style="border:1px solid #e1e1e1">
                                    <a :href="ktem? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + ktem.downloadItemFlPath : ''" target="_blank">
                                        <img :src="ktem? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + ktem.thumbnailFlPath : ''" width="100%">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                <div class="p-2"><span class="fkr-set2 fs-12 fc-w0"><i class="fa fa-download"/>&nbsp;&nbsp;<a href="http://www.inno-lab.co.kr/dfma/" style="color:#4e0000" target="_blank"><b>{{ lang == 'ko'? '모듈편집SW 다운로드' : 'Module Editing SW Download'}}</b></a></span></div>-->
                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api'
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
export default {
    name: 'MenuDownload',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            showListItems: [[[]]],
            buildMode: process.env.NODE_ENV,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET
        }
    },
    async mounted() {
        const rtnval = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/download/download/list');
        if(rtnval.result) {
            var itemList = rtnval.data.sort((a,b) => a.downloadId - b.downloadId);
            this.showListItems = this.renderList(itemList);
        }
        else {
            alert(rtnval.errormsg);
        }
    },
    methods: {
        renderList(fetchedList) {
            var showList = [];
            var how = [];
            var row = [];
            for(var j=0; j < fetchedList.length; j++) {
                row.push(fetchedList[j])
                if(j%4 == 1) {
                    how.push(row);
                    row = [];
                }
                else if(j%4 == 3) {
                    how.push(row);
                    showList.push(how);
                    row = [];
                    how = []
                }
            }
            while(j%4 != 0) {
                row.push(null)
                if(j%4 == 1) {
                    how.push(row);
                    row = [];
                }
                else if(j%4 == 3) {
                    how.push(row);
                    showList.push(how);
                    row = [];
                    how = []
                }
                j++
            }
            return showList;
        }
    }
}
</script>