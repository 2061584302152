<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">{{ lang == 'ko'? '인증서 및 특허' : 'Certificates and Patents'}}</span><br>
                    <span class="dh_p1c"></span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- 인증서 및 특허 -->
                <div class="py-3">

                    <div v-for="(item, i) in showListItems" :key="i" class="iw-1">
                        <div v-for="(jtem, j) in item" :key="j" class="iw-3 x_div_center" style="align-items:start;">
                            <div v-for="(ktem, k) in jtem" :key="k" class="iw-3 px-1 py-2">
                                <div v-if="ktem" class="p-1" style="border:1px solid #e1e1e1; cursor: pointer;" data-toggle="modal" data-target="#v-s1" @click="setModal(ktem)">
                                    <img :src="ktem? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + ktem.thumbnailFlPath : ''" width="100%">
                                </div>
                                <div v-if="ktem" class="text-center py-2">
                                    <div class="fkr-set1 fs-095" style="cursor:pointer" data-toggle="modal" data-target="#v-s1" @click="setModal(ktem)">{{ ktem.title }}</div>
                                    <div class="fkr-set2">{{ ktem.subTitle }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id="v-s1" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001; top:7%">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">

                            <div class="w-100">
                                <div class="x_div_center w-100" style="background-color:#333; border-top-left-radius:4px; border-top-right-radius:4px">
                                    <div class="w-100 p-3 ps-4"><span class="fkr-set1 fs-11 fc-w8">{{ title }}</span></div>
                                    <div class="text-end pe-4" style="width:100px"><a href="#@" data-dismiss="modal"><i class="fa-solid fa-xmark fs-15 fc-w1"></i></a></div>
                                </div>

                                <div><img :src="thumbnailFlPath? 'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + thumbnailFlPath : ''" width="100%" style="border-bottom-left-radius:5px; border-bottom-right-radius:5px"></div>

                            </div>

                        </div>
                    </div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>

            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/api'
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
export default {
    name: 'MenuCertificates',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            showListItems: [[[]]],
            title: '',
            thumbnailFlPath: '',
            buildMode: process.env.NODE_ENV,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET
        }
    },
    async mounted() {
        const rtnval = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/certificates/list');
        if(rtnval.result) {
            var itemList = rtnval.data.sort((a,b) => a.certificateId - b.certificateId);
            this.showListItems = this.renderList(itemList);
        }
        else {
            alert(rtnval.errormsg);
        }
    },
    methods: {
        renderList(fetchedList) {
            var showList = [];
            var how = [];
            var row = [];
            for(var j=0; j < fetchedList.length; j++) {
                row.push(fetchedList[j])
                if(j%4 == 1) {
                    how.push(row);
                    row = [];
                }
                else if(j%4 == 3) {
                    how.push(row);
                    showList.push(how);
                    row = [];
                    how = []
                }
            }
            while(j%4 != 0) {
                row.push(null)
                if(j%4 == 1) {
                    how.push(row);
                    row = [];
                }
                else if(j%4 == 3) {
                    how.push(row);
                    showList.push(how);
                    row = [];
                    how = []
                }
                j++
            }
            return showList;
        },
        setModal(item) {
            this.title = item.title;
            this.thumbnailFlPath = item.thumbnailFlPath;
        }
    }
}
</script>