var pp = `주식회사 M3 Systems(이하 “M3”)는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.<br>
<br>
1. 개인정보 이용 목적<br>
M3는 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다. 만약, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 것을 약속합니다.<br>
1)	서비스 이용을 위한 고객 관리 <br>
2)	광고성 정보 제공 등 마케팅 및 프로모션 활용<br>
3)	파트너기관의 경우 고객 견적 의뢰 정보 제공<br>
<br>
2. 개인정보 수집 항목 및 보유 기간<br>
“이용자”에게 받은 개인정보는 동의 받은 기간 동안만 보유합니다. 개인정보의 수집 및 이용목적 달성, 보유기간 만료, 회원의 수집 및 이용 동의 철회 시 수집된 개인정보는 열람하거나 이용할 수 없도록 파기 처리합니다.<br>
1)	개인회원 수집 항목: 이름, 이메일, 휴대전화번호<br>
2)	법인회원 수집 항목: 이름, 이메일, 휴대전화번호, 회사명, 대표자이름, 사업자등록번호, 서비스 이용과정에서 “이용자”가 입력한 정보 및 작성한 게시물<br>
3)	수집 방법: 홈페이지, 서면양식 전화/팩스<br>
4)	보유기간: 5년<br>
5)	관련법령: 소비자의 불만 또는 분쟁처리에 관한 기록: 3년, 대금결제 및 재화 등의 공급에 관한 기록: 5년, 계약 또는 청양철회 등에 관한 기록: 5년, 표시/광고에 관한 기록: 6개월<br>
<br>
3. 개인정보 제공<br>
“M3”는 “이용자”의 별도 동의가 있는 경우나, 법령에 규정된 경우를 제외하고는 “이용자”의 개인정보를 제3자에게 제공하지 않습니다. <br>
<br>
4. 개인정보 파기<br>
1)	“M3”는 개인정보 보유기간의 경과, 처리목적 달성 후에는 지체없이 해당 개인정보를 파기합니다. <br>
2)	정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 DB로 옮기거나 보관장소를 변경하여 보존합니다.<br>
3)	개인정보 파기절차 및 방법은 다음과 같습니다.<br>
①	파기절차: 개인정보 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우 외에 보관 이외의 다른 목적으로 이용되지 않습니다.<br>
②	파기 방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br>
<br>
5. 개인정보 안전성<br>
1)	“M3”는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.<br>
①	정기적인 자체 감사 실시: 개인정보 취급 관련 안전성 확보를 위해 정기적으로 자체 감사를 실시하고 있습니다.<br>
②	내부관리계획의 수립 및 시행: 개인정보 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.<br>
③	해킹 등에 대비한 기술적 대책: “M3”는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.<br>
④	개인정보 암호화: “이용자”의 개인정보 및 비밀번호는 암호화되어 저장, 관리되고 있습니다. 비밀번호는 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 제공하고 있습니다.<br>
⑤	비인가자에 대한 출입 통제: 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.<br>
<br>
6. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항<br>
1)	“M3”는 “이용자”에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br>
2)	쿠키는 웹사이트를 운영하는데 이용하는데 이용되는 서버(http)가 “이용자”의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.<br>
3)	쿠키 사용 목적: “이용자” 접속 빈도 또는 방문 시간 등을 분석하여 타겟 마케팅 및 개인 맞춤 서비스를 제공하고자 합니다.<br>
4)	“이용자”는 쿠키 설치에 대한 선택권을 가지고 있습니다. <br>
5)	웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 거부할 수 있습니다. 다만, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.<br>
<br>
7. 개인정보 보호책임자<br>
1)	“M3”는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. ※ 개인정보 보호 담당부서로 연결됩니다.<br>
①	개인정보 보호책임자<br>
-	성명: 김인한<br>
-	직급: CEO<br>
-	연락처: 1660-0133<br>
2)	정보주께서는 “M3”의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. “M3”는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.&nbsp;&nbsp;<br>
<br>
8. 개인정보 처리방침 변경<br>
1)	이 개인정보처리방침은 시행일로부터 적용됩니다. <br>
2)	법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정(이하 개정)이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 공지할 것입니다.<br>
3)	“M3”는 정보주체에게 위 개정사항에 대한 동의 여부를 확인하며, 정보주체가 동의하지 않은 경우 “M3”에 대한 개인정보의 수집·이용 및 제3자 제공에 관한 동의를 철회할 수 있습니다.<br>`

export default pp;