<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">{{lang == 'ko'? 'M3 조직도' : 'M3 Organization'}}</span><br>
                    <span class="dh_p1c"></span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <div class="py-3 pt-5">
                    <div class="x_div_center py-3"><img src="@/assets/img/s_info/organization_231226.png" width="100%"></div>
                </div>
                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
export default {
    name: 'OrganizationPage',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    }
}
</script>