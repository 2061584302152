import Introduction from '@/views/menu_en/introduction/Introduction.vue'
import Location from '@/views/menu_en/introduction/Location.vue'
import Modular from '@/views/menu_en/introduction/Modular.vue'
import JoinPartner from '@/views/menu_en/partners/JoinPartner.vue'
import BuildService from '@/views/menu_en/mybuilding/Service.vue'
import Process from '@/views/menu_en/mybuilding/Process.vue'

export default [
    {
        path: '/menu-en/introduction',
        name: 'Introduction-En',
        component: Introduction
    },
    {
        path: '/menu-en/location',
        name: 'Location-En',
        component: Location
    },
    {
        path: '/menu-en/modular',
        name: 'Modular-En',
        component: Modular
    },
    {
        path: '/menu-en/join-partner',
        name: 'JoinPartner-En',
        component: JoinPartner
    },
    {
        path: '/menu-en/build-service',
        name: 'BuildService-En',
        component: BuildService
    },
    {
        path: '/menu-en/process',
        name: 'Process-En',
        component: Process
    }
]