<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">M3 찾아오시는길</span><br>
                    <span class="dh_p1c"></span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- 오시는길 -->
                <div class="py-3" style="border-bottom:1px solid #e9e9e9">
                   <!-- <div class="x_div_center"><span class="fkr-set2 fs-16"><strong>오시는길</strong></span></div> -->
                    <!-- <div class="x_div_center py-3"><span class="fkr-set2 fs-10">당신의 꿈, 당신의 미래를 만드는 곳 입니다.</span></div> -->
                    <div class="x_div">
<!--                        <div class="iw-1 px-2 py-2">-->
                            <img src="@/assets/img/s_info/info_map_ko.jpg" width="100%">
<!--                            <div class="iw-4 py-2 px-4">-->
<!--                                <div>-->
<!--                                    <div class="fen-set1 fs-13 fc-w10">Headquarter</div>-->
<!--                                    <div class="fkr-set2 fs-09 pb-3">경상북도 포항시 북구 청하면 사방공원길 127-16</div>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <div class="fen-set1 fs-13 fc-w10">Research Lab</div>-->
<!--                                    <div class="fkr-set2 fs-09 pb-3">서울특별시 서초구 서초중앙로 188 아크로비스타 L-410호</div>-->
<!--                                    <div class="fkr-set2 fs-09"><strong>[오시는길]</strong></div>-->
<!--                                    <div class="fkr-set2 fs-09">-->
<!--                                        <ol>-->
<!--                                            <li style="line-height:220%">-->
<!--                                                <strong>교대역 6번출구에서 고속버스터미널 방향으로 600미터 위치</strong><br>-->
<!--                                                운전 시 고개넘어 두번째 신호등에서 (왼쪽에 SK주유소 보임)<br>-->
<!--                                                아크로비스타 사무동을 끼고 우회전하여 상가동 지하주차장 진입<br>-->
<!--                                                아크로비스타 정문 좌측 옆 건물 4층 (1층에 우리은행이 있음)<br>-->
<!--                                            </li>-->
<!--                                            <li style="line-height:220%">-->
<!--                                                <strong>고속터미널 옆 삼호가든 사거리에서 교대역 방향으로 600미터 위치</strong><br>-->
<!--                                                운전 시 세번째 신호등에서 (오른쪽에 SK주유소 보임)<br>-->
<!--                                                좌회전하여 아크로비스타 사무동 지하주차장 진입<br>-->
<!--                                            </li>-->
<!--                                            <li style="line-height:220%">-->
<!--                                                <strong>걸어오실 때</strong><br>-->
<!--                                                교대역 6번출구에서 고속터미널 방향으로 600m 걸어오면 우측에 3개의 아크로 비스타 고층 건물이 있음. 첫번째 고층 건물 주거동(C)와 두번째 고층 건물 주거동(B)를 지나 마지막 5층 건물 사무동(A) 4층에 위치 (사무동(A) 1층에 우리은행이 있음)-->
<!--                                            </li>-->
<!--                                        </ol>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div><div class="fen-set1 fs-13 fc-w10">Modular Factory</div><div class="fkr-set2 fs-09">경기도 이천시 마장면</div></div>-->
<!--                            </div>-->
                        </div>
<!--                    </div>-->
                </div>

                <div style="height:50px"></div>

                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
export default {
    name: 'CompanyLocation',
    components: {
        MenuFooter
    }
}
</script>