<template>
    <div>
        <div class="pf_hd w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">M Commercial</span><br>
                    <span class="dh_p1c">M3Systems 의 DfMA 모듈러 건축기술을 적용하여, 공사기간을 단축하고<br>시공비를 최소화한 제주 Skye House 를 소개합니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- header info -->
                <div class="py-3">
                    <div class="iw-1">
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">건축 용도</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">근린 생활 시설</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">주요 구조</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">철골조</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">공사 기간</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">제조 12주 / 설치 3일</div></div>
                        </div>
                        <div class="iw-5 px-1 py-2" style="justify-content:left !important">
                            <div class="py-2" style="width: 95%"><div class="px-3 fkr-set1 fs-10" style="padding-left:0 !important; border-bottom: 1px solid #333">대지 위치</div><div class="px-3 fkr-set2 fs-09" style="padding-left:0 !important;">제주특별자치도 서귀포시 호근동</div></div>
                        </div>
                    </div>
                </div>

                <div style="height:30px"></div>

                <div class="py-3">
                    <!-- carousel image -->
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-indicators">
                            <button v-for="(v, i) in carouselImg" :key="i" type="button" data-bs-target="#carouselExampleCaptions" :data-bs-slide-to="i" :class="i == 0 ? 'active' : ''" :aria-current="i == 0 ? 'true' : ''" :aria-label="'Slide ' + (i+1)"></button>
                        </div>
                        <div class="carousel-inner">
                            <div v-for="(v, i) in carouselImg" :key="i" :class="'carousel-item' + (i == 0 ? ' active' : '')">
                                <div class="iw-1">
                                    <img :src="'/files/img/portfolio/prj01/' + v" :style="setStyleCarousel(v)">
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    <!-- main images(4 slots) -->
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[0]? 'sample/' + this.mainImg[0] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[0])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[1]? 'sample/' + this.mainImg[1] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[1])" width="100%">
                                </div>
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[2]? 'sample/' + this.mainImg[2] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[2])" width="100%">
                                </div>
                            </div>
                            <div class="p-2"></div>
                            <div class="iw-3" style="align-items:start;">
                                <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 : pageWidth/2) + 'px;'">
                                    <img :src="'/files/img/portfolio/' + (this.mainImg[3]? 'sample/' + this.mainImg[3] : 'whitebg.jpg')" :style="setStyleMains(this.mainImg[3]) + (carouselImg.length > 4 ? 'filter:brightness(50%);' : '')" width="100%">
                                    <span style="position:absolute; top:40%; left:40%; color:#fff"><h1>{{ carouselImg.length > 4 ? ('+' + (carouselImg.length - 4)) : '' }}</h1></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>바다의 풍경을 담은 두 개의 액자</strong></p>
                    <p class="fkr-set2 fs-09">제주 서귀포시에 위차하여 바다를 향해 열려있는 매력적인 근생시설입니다. <br>바다와 하늘 그리고 날씨의 변화를 두 개의 액자에 담아내는 듯한 디자인으로 표현했습니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(single left + 2 layered right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/2 : pageWidth) + 'px;'">
                                <img :src="'/files/img/portfolio/prj01/main04_v.jpg'" :style="setStyleMains('main04_v.jpg')" width="100%">
                            </div>
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-3" style="align-items:start;">
                            <!-- horizontal images only!! -->
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/prj01/main05_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                            <div style="height:16px"></div>
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (viewWidth >= 1024 ? pageWidth/4 -8 : pageWidth/2 -8) + 'px;'">
                                <img :src="'/files/img/portfolio/prj01/main03_h.jpg'" style="position: absolute; top: -25%; left: 0; width: 100%; height: auto;" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>주변과 어우러지는 조경, 그리고 전망</strong></p>
                    <p class="fkr-set2 fs-09">지붕 위 공간을 활용하기 위해 별도의 계단을 추가하여 지역의 전망대 역할을 할 수 있게 하였습니다. <br>외부 계단을 통해 제주의 바다와 시간의 변화를 느껴볼 수 있습니다. <br>1동과 2동의 조화로운 배치, 2동 앞의 돌담 위로 조경이 어우러집니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- card images(horizontal single left + vertical single right) -->
                <div class="py-3">
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-1 d-flex" style="align-items:start;">
                            <!-- horizontal image only!! -->
                            <img :src="'/files/img/portfolio/prj01/main02_h.jpg'" width="100%">
                        </div>
                        <div class="p-2"></div>
                        <div class="iw-2 d-flex" style="align-items:start;">
                            <div :style="'width:100%; position: relative; overflow: hidden; height: ' + (isMobile? pageWidth*1.33 : pageWidth/3*1.43) +'px'">
                                <img :src="'/files/img/portfolio/prj01/main09_h.jpg'" :style="setStyleVertical('main09_h.jpg')" width="100%">
                            </div>
                        </div>
                    </div>
                </div>

                <!-- description -->
                <div class="py-3">
                    <p class="fkr-set2 fs-13"><strong>회전율과 재사용율을 극대화시킬 디자인 및 공법</strong></p>
                    <p class="fkr-set2 fs-09">재건축 고려시 상업시설의 중단이 필요한 기간은 단지 철거, 설치 기간 뿐입니다. <br>이동 가능한 설계 시, 다른 토지에서 재사용도 얼마든지 가능합니다.</p>
                </div>

                <div style="height:50px"></div>

                <!-- specifiaction -->
                <div class="table-responsive" style="border-top:3px solid #c9c9c9; border-bottom:3px solid #c9c9c9">
                    <table class="table-borderless w-100">
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">대지위치&nbsp;&nbsp;&nbsp;Location</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">제주특별자치도 서귀포시 호근동</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">용&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;도&nbsp;&nbsp;&nbsp;Usage</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">근린생활시설</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">구&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;조&nbsp;&nbsp;&nbsp;Structure</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">철골조</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">대지면적&nbsp;&nbsp;&nbsp;Site area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">503 ㎡</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">건축면적&nbsp;&nbsp;&nbsp;Building area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">100.35 ㎡</span></td> <!--(30평) -->
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">연 면 적&nbsp;&nbsp;&nbsp;Total floor area</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">197.05 ㎡</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">층&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;수&nbsp;&nbsp;&nbsp;Floors</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">2층</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">건 폐 율&nbsp;&nbsp;&nbsp;Building land ratio</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">19.95%</span></td>   
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">용 적 률&nbsp;&nbsp;&nbsp;Floor area ratio</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">39.18%</span></td>
                        </tr>
                        <tr style="height:55px;">
                            <td :width="isMobile? '50%' : '20%'"><span class="fkr-set1">외부마감&nbsp;&nbsp;&nbsp;Exterior material</span></td>
                            <td :width="isMobile? '50%' : '80%'"><span class="fkr-set2">알루미늄 금속판넬</span></td>
                        </tr>
                    </table>
                </div>

                <div style="height:50px"></div>

                <PortfolioNavigation :isMobile="isMobile"/>
                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import PortfolioNavigation from '@/components/PortfolioNavigation.vue'
export default {
    name: 'PortfolioPrj01',
    components: {
        MenuFooter, PortfolioNavigation
    },
    data() {
        return {
            isMobile: false,
            pageWidth: 0,
            viewWidth: 0,
            carouselImg: ['main01_h.jpg', 'main07_v.jpg', 'main08_v.jpg', 'main06_v.jpg'],
            mainImg: []
        }
    },
    created() {
        for(var i=0; (i < this.carouselImg.length && i < 4); i++) {
            this.mainImg[i] = this.carouselImg[i];
        }
        for(; i < 4; i++) {
            this.mainImg[i] = '';
        }
    },
    mounted() {
        // eslint-disable-next-line
        var swiper = new window.Swiper('.swiper-container', {
            slidesPerView: 1,
            spaceBetween: 20,
            effect: 'fade',
            loop: true,
            speed: 300,
            mousewheel: {
                invert: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            },
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        this.isMobile = window.innerWidth < 768;
        this.viewWidth = window.innerWidth;
        this.pageWidth = Number(window.$('.rp_set.px-3').css('width').split('px')[0]);
    },
    methods: {
        setStyleCarousel(img) {
            if(this.isMobile) {
                return 'width:100%';
            }
            else {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'height:' + this.pageWidth/1.33 + 'px'
                }
                else {
                    return 'width:100%'
                }
            }
        },
        setStyleMains(img) {
            if(img) {
                var side = img.split('_')[1];
                if(side.indexOf('v') >= 0) {
                    return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
                }
                else {
                    return 'position: absolute; top: 0; left: -25%; width: auto; height: ' + (this.pageWidth >= 1024 ? this.pageWidth/2 : this.pageWidth) + 'px;'
                }
            }
            else {
                return 'position: absolute; top: -25%; left: 0; width: 100%; height: auto;'
            }
        },
        setStyleVertical(img) {
            var side = img.split('_')[1];
            if(side.indexOf('v') >= 0) {
                return 'position: absolute; width: 103%; height: auto;'
            }
            else {
                return 'position: absolute; width:auto; ' + (this.isMobile ? 'height:' + this.pageWidth*1.33 : 'top: 0; left: -25%; height:' + (this.pageWidth/3)*1.43) + 'px;'
            }
        }
    }
}
</script>

<style scoped>
.pf_hd {
    background-image: url('@/assets/img/portfolio/header/prj01.jpg');
    background-position: 50% 20%;
}
</style>