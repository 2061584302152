<template>
    <div class="m3_footer" id="footer">
        <div class="x_div_center" style="width:100%; background-color:#383838">

            <div class="m3_f_stage">

                <!-- 하단 좌측 시작 {-->
                <div class="f_stage_left">
                    <div class="pb-2"><img src="@/assets/img/m3systems.png"></div>
                    <div>
                        <span class="ff-kor1 fs-11 fw-bold  fc-w13">{{ companyNameEn }}</span><br>
                        <span class="fkr-set2 fc-w13">Business Registration Number : <span class="fen-set2 fc-w13">{{ businessNumber }}</span> │ Representative : {{ ceoNameEn }}</span><br>
                        <span v-show="addr.titleEn" class="fkr-set2 fc-w13" v-for="(addr, index) in addrList" :key="index">
                            [{{ addr.titleEn }}] {{ addr.addrEn }}
                            <br></span>
                        <span class="fen-set2 fc-w13">TEL ｜ {{ phoneNumberForAbroad }}</span><br>
                        <span class="fen-set2 fc-w13">Copyright ⓒ m3systems. All Rights Reserved.</span>
                    </div>
                </div>
                <!-- } 하단 좌측 끝 -->

                <!-- 하단 우측시작 {-->
                <div class="f_stage_right">
                    <div class="fsr_menu1">
                        <div class="d-flex">
                            <div class="px-3"><a class="fc-w1" href="/menu-en/introduction"><span class="fkr-set1 fs-095 fc-w13">About Us</span></a></div>
                            <div><span class="fen-set1 fc-w3">|</span></div>
                            <div class="px-3"><a class="fc-w1" href="/menu/partners"><span class="fkr-set1 fs-095 fc-w13">Partners</span></a></div>
                            <div><span class="fen-set1 fc-w3">|</span></div>
                            <div class="px-3"><a class="fc-w1" href="https://buildmarket.kr/cs/inquiry" target="_blank"><span class="fkr-set1 fs-095 fc-w13">Support</span></a></div>
                        </div>
                    </div>
                    <div class="fsr_menu2">
                        <div class="d-flex">
                            <div class="px-2"><a class="fc-w1" href="https://www.instagram.com/m3s.korea/" target=_blank><img src="@/assets/img/f_instagram.png"></a></div>
                            <div class="px-2"><a class="fc-w1" href="https://www.youtube.com/channel/UCM6JVuH0hof0OJFq1ivrucA" target=_blank><img src="@/assets/img/f_youtube.png"></a></div>
                            <div class="px-2 pe-3"><a class="fc-w1" href="https://blog.naver.com/m3systems" target=_blank><img src="@/assets/img/f_blog.png"></a></div>
                        </div>
                    </div>
                    <!--div class="x_div_end">
                        <div class="d-flex">
                            <div class="px-3"><a href="#" data-toggle="modal" data-target="#myModal"><span class="fkr-set1 fc-w3 fc-w13">Terms of Use</span></a></div>
                            <div><span class="fen-set1 fc-w5">|</span></div>
                            <div class="px-3"><a href="#" data-toggle="modal" data-target="#myModal2"><span class="fkr-set1 fc-w1">Privacy Policy</span></a></div>
                        </div>
                    </div-->
                </div>
                <!-- } 하단 우측 끝 -->

            </div>

            <!-- 이용약관 -->
            <div id="termsInUse" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div class="text-end"><button type="button" class="btn btn-sm" data-dismiss="modal"><i class="fa-regular fa-circle-xmark fs-13"></i></button></div>
                            <div class="x_div_center pb-3"><span class="fkr-set1 fs-13">서비스 이용 및 회원가입 약관</span></div>
                            <div class="x_div_center">
                                <div class="p-3" style="width:95%; height:500px; border:1px solid #d9d9d9; overflow-x: hidden;">
                                    <div v-html="termsInUse" class="fkr-set1"></div>
                                </div>
                            </div>
                            <div class="x_div_center"><div class="py-3"><button class="fkr-set1" data-dismiss="modal" style="width:150px; height:37px; border:0; background-color:#4f5962; color:#fff">닫기</button></div></div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- 개인정보처리방침 -->
            <div id="privacyPolicy" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div class="text-end"><button type="button" class="btn btn-sm" data-dismiss="modal"><i class="fa-regular fa-circle-xmark fs-13"></i></button></div>
                            <div class="x_div_center pb-3"><span class="fkr-set1 fs-13">개인정보처리방침</span></div>
                            <div class="x_div_center">
                                <div class="p-3" style="width:95%; height:500px; border:1px solid #d9d9d9; overflow-x: hidden;">
                                    <div v-html="privacyPolicy" class="fkr-set1"></div>
                                </div>
                            </div>
                            <div class="x_div_center"><div class="py-3"><button class="fkr-set1" data-dismiss="modal" style="width:150px; height:37px; border:0; background-color:#4f5962; color:#fff">닫기</button></div></div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import tiu from '@/assets/textdata/TermsInUse.js'
import pp from '@/assets/textdata/PrivacyPolicy.js'
import Api from '@/api'
export default {
  name: 'FooterComponent',
  data() {
    return {
        termsInUse: '',
        privacyPolicy: '',
        companyName: '',
        companyNameEn: '',
        ceoName: '',
        ceoNameEn: '',
        businessNumber: '',
        phoneNumber: '',
        phoneNumberForAbroad: '',
        addrList: [],
        buildMode: process.env.NODE_ENV,
    }
  },
  async created() {
    const returnvalue = await Api.get((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/companyinfo/list');
    if(returnvalue.result) {
      this.companyName = returnvalue.data[0].companyName;
      this.companyNameEn = returnvalue.data[0].companyNameEn;
      this.ceoName = returnvalue.data[0].ceoName;
      this.ceoNameEn = returnvalue.data[0].ceoNameEn;
      this.businessNumber = returnvalue.data[0].businessNumber;
      this.phoneNumber = returnvalue.data[0].phoneNumber;
      this.phoneNumberForAbroad = returnvalue.data[0].phoneNumberForAbroad;
      this.addrList = returnvalue.data[0].addrList;
    }
    else {
      alert(returnvalue.errormsg)
    }
  },
  mounted() {
    this.termsInUse = tiu;
    this.privacyPolicy = pp;
  }
}
</script>