<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">M3의 DfMA 모듈러 기술이란?</span><br>
                    <span class="dh_p1c">공장에서 제조하고 현장 설치하는 고객 맞춤형 건설 자동화 기술입니다.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- M3, 통합 플랫폼 소개 -->
                <div class="py-3 pt-5">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>M3, 통합 플랫폼 소개</strong></span></div>
                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">첨단 BIM통합형 DfMA 기술을 기반으로 하는, 프리미엄 모듈러 건축 전체 여정에 최적화된 통합 플랫폼입니다.</span></div>
                    <div class="x_div_center py-3"><img src="@/assets/img/s_info/im1.jpg" width="100%"></div>
                </div>

                <div style="height:50px"></div>

                <!-- WHY M3Systems? -->
                <div class="wr1_p py-3">
                    <div class="x_div_left"><span class="fen-set2 fs-16 fc-w10"><strong>WHY M3Systems?</strong></span></div>
                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">M3 자체 플랫폼 기반으로 국내 최초의 사용자 맞춤형 계획-설계-시공-유지관리-재사용 통합서비스를 제공합니다.</span></div>

                    <div class="tabs">
                        <div class="tab-header">
                            <div class="active"><span class="fkr-set2 fs-10">고객중심 프로세스</span></div>
                            <div><span class="fkr-set2 fs-10">앞선 기술력</span></div>
                            <div><span class="fkr-set2 fs-10">고품질 제조 및 시공</span></div>
                            <div><span class="fkr-set2 fs-10">친환경 건축</span></div>
                        </div>
                        <div class="tab-indicator"></div>
                        <div class="tab-content">
                            
                            <div class="active">
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy1.jpg" width="400"></p>
                                    <p class="ps-4"><span class="fkr-set2 fs-11 fc-w9"><strong>고객 Self-Service</strong></span><br><span class="fkr-set2 fs-16"><strong>프리미엄 모듈러 건물<br>고객 맞춤형으로 만듭니다.</strong></span><br><span class="fkr-set2" style="line-height:180%">M3는 수많은 설계 평면을 분석하여 국내요구에 최적화된 단위공간 모듈을 지속적으로 개발하고 있으며, 이를 체계적으로 조합할 수 있는 첨단 디지털 설계기법을 통하여 사용자의 요구사항에 맞춤형으로 디자인을 제공합니다.</span></p>
                                </span>
                            </div>
                            
                            <div>
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy2.jpg" width="400"></p>
                                    <p class="ps-4"><span class="fkr-set2 fs-11 fc-w9"><strong>디지털 설계기술 선두기업</strong></span><br><span class="fkr-set2 fs-16"><strong>최첨단 디지털 기술기반으로 고객맞춤형 품격 높은 디자인을 제공합니다.</strong></span><br><span class="fkr-set2" style="line-height:180%">M3는 지난 20년간 정부 및 국제 BIM 연구개발을 선도적으로 수행해온 기술력을 바탕으로 수많은 설계 평면을 분석하여, 국내 요구에 최적화된 단위공간 모듈을 지속적으로 개발하고 있으며, 이를 체계적으로 조합할수 있는 첨단 디지털 설계기법을 통하여 사용자의 요구사항에 맞춤형으로 디자인을 제공합니다.</span></p>
                                </span>
                            </div>
                            
                            <div>
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy3.jpg" width="400"></p>
                                    <p class="ps-4"><span class="fkr-set2 fs-11 fc-w9"><strong>디지털화와 제조업화 통한 고품질, 경제성 향상</strong></span><br><span class="fkr-set2 fs-16"><strong>정교하게 제작하고 시공하여 내구성 및 고품질을 확보합니다.</strong></span><br><span class="fkr-set2" style="line-height:180%">M3는 고객의 요구사항과 전문가 협의에 의해 완성된 설계안을, *BIM 과 *DfMA 설계 통합정보와 함께 적용함으로써 정교하고, 만족도 높고, 품질 좋은 고객 맞춤형 건축 원스톱 서비스를 제공합니다.</span></p>
                                </span>
                            </div>
                            
                            <div>
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy4.jpg" width="400"></p>
                                    <p class="ps-4"><span class="fkr-set2 fs-11 fc-w9"><strong>친환경 설계로 저탄소 녹색성장 참여</strong></span><br><span class="fkr-set2 fs-16"><strong>건축물 에너지절약 설계 기준을 적용한 친환경 설계로, 냉난방비를 효과적으로 절감합니다.</strong></span><br><span class="fkr-set2" style="line-height:180%">M3는 ESG 지원 친환경 설계를 지원합니다.<br>모듈러 공법으로 폐자재를 최소화하여 저탄소 녹색성장에 적극 참여하고, 에너지와 자원 소비의 최소화를 위하여 건축물 에너지 절약 설계기준을 적용한 친환경 설계를 합니다. 디자인 단계시 확장, 이전이 가능하도록 디자인,제작,시공이 가능합니다.</span></p>
                                </span>
                            </div>
                            
                        </div>
                    </div>

                    <div style="height:50px"></div>

                </div>

                <!-- 모바일탭 -->
                <div class="wr1_m py-3">
                    <div class="x_div_left"><span class="fen-set2 fs-16 fc-w10"><strong>WHY M3Systems?</strong></span></div>
                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">M3 자체 플랫폼 기반으로 국내 최초의 사용자 맞춤형 계획-설계-시공-유지관리-재사용 통합서비스를 제공합니다.</span></div>

                    <div class="">

                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy1.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:350px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">고객 Self-Service</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">프리미엄 모듈러 건물 고객 맞춤형으로 만듭니다.</span></div>
                                        <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">M3는 수많은 설계 평면을 분석하여 국내요구에 최적화된 단위공간 모듈을 지속적으로 개발하고 있으며, 이를 체계적으로 조합할 수 있는 첨단 디지털 설계기법을 통하여 사용자의 요구사항에 맞춤형으로 디자인을 제공합니다.</span></div>
                                    </div>
                                </div>
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy2.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:350px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">디지털 설계기술 선두기업</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">최첨단 디지털 기술기반으로 고객맞춤형 품격 높은 디자인을 제공합니다.</span></div>
                                        <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">M3는 지난 20년간 정부 및 국제 BIM 연구개발을 선도적으로 수행해온 기술력을 바탕으로 수많은 설계 평면을 분석하여, 국내 요구에 최적화된 단위공간 모듈을 지속적으로 개발하고 있으며, 이를 체계적으로 조합할수 있는 첨단 디지털 설계기법을 통하여 사용자의 요구사항에 맞춤형으로 디자인을 제공합니다.</span></div>
                                    </div>
                                </div>
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy3.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:350px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">디지털화와 제조업화 통한 고품질, 경제성 향상</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">정교하게 제작하고 시공하여 내구성 및 고품질을 확보합니다.</span></div>
                                        <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">M3는 고객의 요구사항과 전문가 협의에 의해 완성된 설계안을, *BIM 과 *DfMA 설계 통합정보와 함께 적용함으로써 정교하고, 만족도 높고, 품질 좋은 고객 맞춤형 건축 원스톱 서비스를 제공합니다.</span></div>
                                    </div>
                                </div>
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy4.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:350px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">친환경 설계로 저탄소 녹색성장 참여</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">건축물 에너지절약 설계 기준을 적용한 친환경 설계로, 냉난방비를 효과적으로 절감합니다.</span></div>
                                        <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">M3는 ESG 지원 친환경 설계를 지원합니다.<br>모듈러 공법으로 폐자재를 최소화하여 저탄소 녹색성장에 적극 참여하고, 에너지와 자원 소비의 최소화를 위하여 건축물 에너지 절약 설계기준을 적용한 친환경 설계를 합니다. 디자인 단계시 확장, 이전이 가능하도록 디자인,제작,시공이 가능합니다.</span></div>
                                    </div>
                                </div>
                            </div>

                            <div style="height:40px"></div>

                            <div class="swiper-pagination"></div>
                        </div>

                    </div>

                    <div style="height:50px"></div>

                </div>

                <!-- 차별화된 완성도 -->
                <div class="wr1_p">

                    <div class="text-center px-3 py-3"><span class="fkr-set1 fs-13">차별화된 완성도, 비용 절감, 기간 단축, 유지비 절감을 위하여, DfMA 기반 공장제작, 이동, 조립, 시공합니다.</span></div>

                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims1.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>높은 완성도</strong></span><br><br><span class="fkr-set2 fs-10">기존 건설방식은 현장에서 작업자의 숙련도와 주변환경 및 날씨 등에 영향을 받기에 문제가 발생할 수 있습니다. 모듈러 건설 방식은 안정적인 공장 실내 환경에서 전문가의 엄격한 품질 관리를 통해 규격화된 자재로 생산된 모듈을 사용하기 때문에 높은 완성도를 보장합니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>건축비 절감</strong></span><br><br><span class="fkr-set2 fs-10">건물을 구성하는 요소들이 모듈 단위로 이미 설계되어 있기 때문에 건축주 요구사항에 맞추어 빠르고 효율적으로 건축설계 서비스를 제공합니다. 이로 인하여 상대적으로 단순 반복적인 설계 업무를 줄이고 그 만큼 인건비를 절감할 수 있습니다. 또한 공장 규격화된 모듈러 자재 덕분에 일률적인 제작이 가능하여 불필요한 자재 사용을 줄이고, 세부적인 시공 절차를 간소화하여 전반적인 건축비용을 줄여줍니다.</span></div>
                        </div>
                        <div class="sw-2"><img src="@/assets/img/s_info/ims2.jpg" width="100%"></div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims3.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>공사기간 단축</strong></span><br><br><span class="fkr-set2 fs-10">공장에서 제작되는 모듈은 정해진 프로세스에 따라 일괄적이고 체계적으로 진행되므로 제작 기간이 짧습니다. 규격화된 모듈을 사용함으로써 현장에서 전문가들이 신속하게 조립하므로 재래식 공사에서 요구되는 공사 과정을 줄이고, 안전사고의 위험성을 최소화 할 수 있습니다. 모듈러 건설방식을 통하여 전체 공사 기간을 획기적으로 줄여, 빠른 투자 회수가 가능하고 새로운 비지니스 착수를 앞당길 수 있습니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>나만의 디자인</strong></span><br><br><span class="fkr-set2 fs-10">건축물은 건물 배치와 방향에 따라 다양한 설계가 가능합니다. M3 통합플랫폼을 사용하는 건축주는 플랫폼에서 제공하는 서비스에서 제공하는 설계 서비스를 사용함으로써, 원하는 디자인을 완성할 수 있으며 가상의 주택도 미리 체험해 볼 수 있습니다. 뿐만 아니라 건축주는 플랫폼에서 자재를 선택하면서 총 비용을 조정 할 수도 있습니다.</span></div>
                        </div>
                        <div class="sw-2"><img src="@/assets/img/s_info/ims4.jpg" width="100%"></div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims5.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>유지비 절감</strong></span><br><br><span class="fkr-set2 fs-10">공장에서 제작되는 각 모듈러 유닛은 에너지 고효율 자재로 제작합니다. 그래서 에너지 절감 효과가 우수하고, 건물 유지비용 절감에 도움이 됩니다. 특히 모듈 조립방식 특성상 모듈간 간격이 확보되어 있어 열차단에 유리합니다. 그리고 필요시 모듈을 이동하고 제거 또는 확장가능하여 건축물 변경에 따른 추가 비용을 획기적으로 절감할 수 있습니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>모듈별 제작 및 이동</strong></span><br><br><span class="fkr-set2 fs-10">해체 및 이동이 가능하도록 디자인된 모듈러 건물은 여러 모듈을 조립하여 건축되었기 때문에 필요시 손상없이 모듈 단위로 해체가 가능하며 건축 주가 원하는 장소로 주택을 옮길 수도 있습니다. 이에 따라 철거해야 하는 경우, 철거 비용 뿐 아니라 재시공 비용을 크게 절감 할 수 있습니다. 이러한 이동성은 모듈러 주택만이 가지는 장점이기도 합니다.</span></div>
                        </div>
                        <div class="sw-2"><img src="@/assets/img/s_info/ims6.jpg" width="100%"></div>
                    </div>

                </div>

                <!-- 차별화된 완성도 -->
                <div class="wr1_m">

                    <div class="text-center px-3 py-3"><span class="fkr-set1 fs-13">차별화된 완성도, 비용 절감, 기간 단축, 유지비 절감을 위하여, DfMA 기반 공장제작, 이동, 조립, 시공합니다.</span></div>

                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims1.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>높은 완성도</strong></span><br><br><span class="fkr-set2 fs-10">기존 건설방식은 현장에서 작업자의 숙련도와 주변환경 및 날씨 등에 영향을 받기에 문제가 발생할 수 있습니다. 모듈러 건설 방식은 안정적인 공장 실내 환경에서 전문가의 엄격한 품질 관리를 통해 규격화된 자재로 생산된 모듈을 사용하기 때문에 높은 완성도를 보장합니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims2.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>건축비 절감</strong></span><br><br><span class="fkr-set2 fs-10">건물을 구성하는 요소들이 모듈 단위로 이미 설계되어 있기 때문에 건축주 요구사항에 맞추어 빠르고 효율적으로 건축설계 서비스를 제공합니다. 이로 인하여 상대적으로 단순 반복적인 설계 업무를 줄이고 그 만큼 인건비를 절감할 수 있습니다. 또한 공장 규격화된 모듈러 자재 덕분에 일률적인 제작이 가능하여 불필요한 자재 사용을 줄이고, 세부적인 시공 절차를 간소화하여 전반적인 건축비용을 줄여줍니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims3.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>공사기간 단축</strong></span><br><br><span class="fkr-set2 fs-10">공장에서 제작되는 모듈은 정해진 프로세스에 따라 일괄적이고 체계적으로 진행되므로 제작 기간이 짧습니다. 규격화된 모듈을 사용함으로써 현장에서 전문가들이 신속하게 조립하므로 재래식 공사에서 요구되는 공사 과정을 줄이고, 안전사고의 위험성을 최소화 할 수 있습니다. 모듈러 건설방식을 통하여 전체 공사 기간을 획기적으로 줄여, 빠른 투자 회수가 가능하고 새로운 비지니스 착수를 앞당길 수 있습니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims4.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>나만의 디자인</strong></span><br><br><span class="fkr-set2 fs-10">건축물은 건물 배치와 방향에 따라 다양한 설계가 가능합니다. M3 통합플랫폼을 사용하는 건축주는 플랫폼에서 제공하는 서비스에서 제공하는 설계 서비스를 사용함으로써, 원하는 디자인을 완성할 수 있으며 가상의 주택도 미리 체험해 볼 수 있습니다. 뿐만 아니라 건축주는 플랫폼에서 자재를 선택하면서 총 비용을 조정 할 수도 있습니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims5.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>유지비 절감</strong></span><br><br><span class="fkr-set2 fs-10">공장에서 제작되는 각 모듈러 유닛은 에너지 고효율 자재로 제작합니다. 그래서 에너지 절감 효과가 우수하고, 건물 유지비용 절감에 도움이 됩니다. 특히 모듈 조립방식 특성상 모듈간 간격이 확보되어 있어 열차단에 유리합니다. 그리고 필요시 모듈을 이동하고 제거 또는 확장가능하여 건축물 변경에 따른 추가 비용을 획기적으로 절감할 수 있습니다.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims6.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-center"><span class="fkr-set2 fs-16"><strong>모듈별 제작 및 이동</strong></span><br><br><span class="fkr-set2 fs-10">해체 및 이동이 가능하도록 디자인된 모듈러 건물은 여러 모듈을 조립하여 건축되었기 때문에 필요시 손상없이 모듈 단위로 해체가 가능하며 건축 주가 원하는 장소로 주택을 옮길 수도 있습니다. 이에 따라 철거해야 하는 경우, 철거 비용 뿐 아니라 재시공 비용을 크게 절감 할 수 있습니다. 이러한 이동성은 모듈러 주택만이 가지는 장점이기도 합니다.</span></div>
                        </div>
                    </div>

                </div>

                <MenuFooter/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
export default {
    name: 'MenuModular',
    components: {
        MenuFooter
    },
    mounted() {
        function _class(name){
            return document.getElementsByClassName(name);
        }

        let tabPanes = _class("tab-header")[0].getElementsByTagName("div");

        for(let i=0;i<tabPanes.length;i++){
        tabPanes[i].addEventListener("click",function(){
            _class("tab-header")[0].getElementsByClassName("active")[0].classList.remove("active");
            tabPanes[i].classList.add("active");
            
            _class("tab-indicator")[0].style.top = `calc(80px + ${i*50}px)`;
            
            _class("tab-content")[0].getElementsByClassName("active")[0].classList.remove("active");
            _class("tab-content")[0].getElementsByTagName("div")[i].classList.add("active");
            
        });
        }

        var swiper = new window.Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 20,
        effect: 'fade',
        loop: true,
        speed: 300,
        mousewheel: {
            invert: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
        });

        console.log(swiper)
    }
}
</script>