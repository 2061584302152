import Sample from '@/views/menu/portfolio/Sample.vue'
import Prj01Skye from '@/views/menu/portfolio/Prj01Skye.vue'
import MHouse from '@/views/menu/portfolio/MHouse.vue'
import MStudio from '@/views/menu/portfolio/MStudio.vue'
import ShingalOffice from '@/views/menu/portfolio/ShingalOffice.vue'
import MSchool from "@/views/menu/portfolio/MSchool.vue";

export default [
    {
        path: '/menu/portfolio/sample',
        name: 'Sample',
        component: Sample
    },
    {
        path: '/menu/portfolio/prj01',
        name: 'Prj01Skye',
        component: Prj01Skye
    },
    {
        path: '/menu/portfolio/mhouse',
        name: 'MHouse',
        component: MHouse
    },
    {
        path: '/menu/portfolio/mstudio',
        name: 'MStudio',
        component: MStudio
    },
    {
        path: '/menu/portfolio/shingaloffice',
        name: 'ShingalOffice',
        component: ShingalOffice
    },
    {
        path: '/menu/portfolio/mschool',
        name: 'MSchool',
        component: MSchool
    },
]