<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">What is M3's DfMA modular technology?</span><br>
                    <span class="dh_p1c">It is a customized construction automation technology manufactured in the factory and installed on site.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- M3, 통합 플랫폼 소개 -->
                <div class="py-3 pt-5">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>M3, introducing an integrated platform</strong></span></div>
                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">It is an integrated platform, based on BIM-DfMA technology and optimized for the entire premium modular architecture journey.</span></div>
                    <div class="x_div_center py-3"><img src="@/assets/img/s_info/im1_en.jpg" width="100%"></div>
                </div>

                <div style="height:50px"></div>

                <!-- WHY M3Systems? -->
                <div class="wr1_p py-3">
                    <div class="x_div_left"><span class="fen-set2 fs-16 fc-w10"><strong>WHY M3Systems?</strong></span></div>
                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">Based on its proprietary digital plateform, M3 provides Korea's first customized plan-design-construction-maintenance-reuse integrated service.</span></div>

                    <div class="tabs">
                        <div class="tab-header">
                            <div class="active"><span class="fkr-set2 fs-08">Customer-centered process</span></div>
                            <div><span class="fkr-set2 fs-08">Advanced technology</span></div>
                            <div style="line-height:120%"><span class="fkr-set2 fs-08">High-quality manufacturing and construction</span></div>
                            <div><span class="fkr-set2 fs-08">Eco-friendly architecture</span></div>
                        </div>
                        <div class="tab-indicator"></div>
                        <div class="tab-content">
                            
                            <div class="active">
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy1.jpg" width="350"></p>
                                    <p class="ps-4"><span class="fkr-set2  fc-w9"><strong>Customer Self Service</strong></span><br><span class="fkr-set2 fs-13"><strong>Premium Modular buildings<br>Make it user customized</strong></span><br><span class="fkr-set2" style="line-height:180%">M3 analyzes numerous design plans and optimizes unit spaces for domestic needs.We are constantly developing modules, through advanced digital design techniques that can be systematically combined tailor your design to your requirments.</span></p>
                                </span>
                            </div>
                            
                            <div>
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy2.jpg" width="350"></p>
                                    <p class="ps-4"><span class="fkr-set2  fc-w9"><strong>Leading Digital Design Technology Company</strong></span><br><span class="fkr-set2 fs-13"><strong>Based on state-of-the-art digital technology, we provide a high-quality design personalized to our customers.</strong></span><br><span class="fkr-set2" style="line-height:180%">Based on the technology of government and international BIM R&D for the past 20 years, M3 continues to develop unit space modules optimized for domestic needs and provides designs customized to user requirements through advanced digital design techniques that can be systematically combined.</span></p>
                                </span>
                            </div>
                            
                            <div>
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy3.jpg" width="350"></p>
                                    <p class="ps-4"><span class="fkr-set2  fc-w9"><strong>High-quality, economical improvement through digitalization and manufacturing</strong></span><br><span class="fkr-set2 fs-13"><strong>
        Sophisticated fabrication and construction to ensure durability and high quality.</strong></span><br><span class="fkr-set2" style="line-height:180%">M3 will provide a design proposal completed by consultation with the customer's
        requirements and experts
        * By applying BIM and DfMA design integration information together,
        we provide a one-stop service tailored to customers with sophisticated, high satisfaction,
        and high quality.
        * Building Information Modeling (BIM): A key information modeling technology that can
        manage planning, design, engineering, construction, maintenance, and quality control
        in a multi-dimensional virtual space.
        * Design for Manufacturing and Assembly (DfMA): A process of integrated management from
        design to construction, in which pre-assemblies are manufactured at the factory and
        constructed at the construction site. This reduces the construction period by 60%.</span></p>
                                </span>
                            </div>
                            
                            <div>
                                <span class="x_div_center ps-4">
                                    <p><img src="@/assets/img/s_info/imy4.jpg" width="350"></p>
                                    <p class="ps-4"><span class="fkr-set2  fc-w9"><strong>Participate in low-carbon green growth through eco-friendly design</strong></span><br><span class="fkr-set2 fs-13"><strong>
        It is an eco-friendly design that applies
        energy-saving design standards for
        buildings, effectively reducing air
        conditioning and heating costs.</strong></span><br><span class="fkr-set2" style="line-height:180%">The M3 supports ESG-enabled eco-design. We actively participate in low-carbon green
        growth by minimizing waste materials through modular construction, and eco-friendly
        design using energy-saving design standards for buildings to minimize energy and
        resource consumption. Design, production, and construction are possible to enable
        expansion and relocation during the design stage.</span></p>
                                </span>
                            </div>
                            
                        </div>
                    </div>

                    <div style="height:50px"></div>

                </div>

                <!-- 모바일탭 -->
                <div class="wr1_m py-3">
                    <div class="x_div_left"><span class="fen-set2 fs-16 fc-w10"><strong>WHY M3Systems?</strong></span></div>
                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">Based on its proprietary digital plateform, M3 provides Korea's first customized plan-design-construction-maintenance-reuse integrated service.</span></div>

                    <div class="">

                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy1.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:390px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-09 fc-w0">Customer Self Service</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-09 fc-w12">Premium Modular buildings<br>Make it user customized</span></div>
                                        <div class="px-4" style="line-height:150%"><span class="fkr-set2 fs-08 fc-w0">M3 analyzes numerous design plans and optimizes unit spaces for domestic needs.We are constantly developing modules, through advanced digital design techniques that can be systematically combined tailor your design to your requirments.</span></div>
                                    </div>
                                </div>
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy2.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:390px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-09 fc-w0">Leading Digital Design Technology Company</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-09 fc-w12">Based on state-of-the-art digital technology, we provide a high-quality design personalized to our customers.</span></div>
                                        <div class="px-4" style="line-height:150%"><span class="fkr-set2 fs-08 fc-w0">Based on the technology of government and international BIM R&D for the past 20 years, M3 continues to develop unit space modules optimized for domestic needs and provides designs customized to user requirements through advanced digital design techniques that can be systematically combined.</span></div>
                                    </div>
                                </div>
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy3.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:390px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-09 fc-w0">High-quality, economical improvement through digitalization and manufacturing</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-09 fc-w12">Sophisticated fabrication and construction to ensure durability and high quality.</span></div>
                                        <div class="px-4" style="line-height:140%"><span class="fkr-set2 fs-08 fc-w0">M3 will provide a design proposal completed by consultation with the customer's
        requirements and experts
        * By applying BIM and DfMA design integration information together,
        we provide a one-stop service tailored to customers with sophisticated, high satisfaction,
        and high quality.
        * Building Information Modeling (BIM): A key information modeling technology that can
        manage planning, design, engineering, construction, maintenance, and quality control
        in a multi-dimensional virtual space.
        * Design for Manufacturing and Assembly (DfMA): A process of integrated management from
        design to construction, in which pre-assemblies are manufactured at the factory and
        constructed at the construction site. This reduces the construction period by 60%.</span></div>
                                    </div>
                                </div>
                                <div class="slider-item swiper-slide">
                                    <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/s_info/imy4.jpg" alt="SliderImg"></div>
                                    <div class="slider-item-content" style="height:390px">
                                        <div class="px-4 pt-3"><span class="fkr-set1 fs-09 fc-w0">Participate in low-carbon green growth through eco-friendly design</span></div>
                                        <div class="px-4 py-2"><span class="fkr-set1 fs-09 fc-w12">It is an eco-friendly design that applies
        energy-saving design standards for
        buildings, effectively reducing air
        conditioning and heating costs.</span></div>
                                        <div class="px-4" style="line-height:150%"><span class="fkr-set2 fs-08 fc-w0">The M3 supports ESG-enabled eco-design. We actively participate in low-carbon green
        growth by minimizing waste materials through modular construction, and eco-friendly
        design using energy-saving design standards for buildings to minimize energy and
        resource consumption. Design, production, and construction are possible to enable
        expansion and relocation during the design stage.</span></div>
                                    </div>
                                </div>
                            </div>

                            <div style="height:40px"></div>

                            <div class="swiper-pagination"></div>
                        </div>

                    </div>

                    <div style="height:50px"></div>

                </div>

                <!-- 차별화된 완성도 -->
                <div class="wr1_p">

                    <div class="text-left px-3 py-3"><span class="fkr-set1 fs-13">To reduce differentiated completeness, cost reduction, period reduction, and maintenance cost reduction, we manufacture, move, assemble, and construct in DfMA-based factories.</span></div>

                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims1.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>High degree of completeness</strong></span><br><br><span class="fkr-set2 fs-10">Existing construction methods can cause problems because they are affected by workers' proficiency in the field and the surrounding environment and weather. The modular construction method ensures high completeness because it uses modules produced with standardized materials through strict quality control by experts in a stable factory indoor environment.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Construction cost reduction</strong></span><br><br><span class="fkr-set2 fs-10">Since the elements that make up the building are already designed on a modular basis, it provides architectural design services quickly and efficiently according to the requirements of the owner. This can reduce relatively simple and repetitive design work and reduce labor costs. In addition, thanks to factory standardized modular materials, uniform production is possible, reducing unnecessary material use and simplifying detailed construction procedures to reduce overall construction costs.</span></div>
                        </div>
                        <div class="sw-2"><img src="@/assets/img/s_info/ims2.jpg" width="100%"></div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims3.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Reduction of construction period</strong></span><br><br><span class="fkr-set2 fs-10">Modules manufactured in the factory are carried out collectively and systematically according to the specified process, so the production period is short. With standardized modules, experts can quickly assemble them in the field, reducing the construction process required for conventional construction and minimizing the risk of safety accidents. The modular construction method can drastically reduce the overall construction period, enable quick investment recovery, and speed up the launch of new business.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Your own design</strong></span><br><br><span class="fkr-set2 fs-10">Buildings can be designed in various ways depending on the layout and direction of the building Architects using the M3 integrated platform can use the design services provided by the platform to complete the design they want and experience virtual houses in advance. In addition, builders can adjust the total cost while choosing materials on the platform.</span></div>
                        </div>
                        <div class="sw-2"><img src="@/assets/img/s_info/ims4.jpg" width="100%"></div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims5.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Reduction in maintenance costs</strong></span><br><br><span class="fkr-set2 fs-10">Each modular unit manufactured in the factory is made of energy efficient materials. Therefore, it has excellent energy saving effects and helps reduce building maintenance costs. In particular, due to the nature of the module assembly method, the gap between modules is secured, which is advantageous for heat shielding. In addition, modules can be moved, removed, or expanded if necessary, drastically reducing additional costs due to building changes.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Module-specific production and movement</strong></span><br><br><span class="fkr-set2 fs-10">The modular building, designed to be dismantled and moved, is built by assembling several modules, so if necessary, it can be dismantled on a module-by module basis without damage and can be moved to a place that the owner wants Accordingly, if it is necessary to demolish it, not only the cost of demolition but also the cost of rebuilding can be greatly reduced. This mobility is also an advantage that only modular houses have.</span></div>
                        </div>
                        <div class="sw-2"><img src="@/assets/img/s_info/ims6.jpg" width="100%"></div>
                    </div>

                </div>

                <!-- 차별화된 완성도 -->
                <div class="wr1_m">

                    <div class="text-center px-3 py-3"><span class="fkr-set1 fs-13">To reduce differentiated completeness, cost reduction, period reduction, and maintenance cost reduction, we manufacture, move, assemble, and construct in DfMA-based factories.</span></div>

                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims1.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>High degree of completeness</strong></span><br><br><span class="fkr-set2 fs-10">Existing construction methods can cause problems because they are affected by workers' proficiency in the field and the surrounding environment and weather. The modular construction method ensures high completeness because it uses modules produced with standardized materials through strict quality control by experts in a stable factory indoor environment.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims2.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Construction cost reduction</strong></span><br><br><span class="fkr-set2 fs-10">Since the elements that make up the building are already designed on a modular basis, it provides architectural design services quickly and efficiently according to the requirements of the owner. This can reduce relatively simple and repetitive design work and reduce labor costs. In addition, thanks to factory standardized modular materials, uniform production is possible, reducing unnecessary material use and simplifying detailed construction procedures to reduce overall construction costs.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims3.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Reduction of construction period</strong></span><br><br><span class="fkr-set2 fs-10">Modules manufactured in the factory are carried out collectively and systematically according to the specified process, so the production period is short. With standardized modules, experts can quickly assemble them in the field, reducing the construction process required for conventional construction and minimizing the risk of safety accidents. The modular construction method can drastically reduce the overall construction period, enable quick investment recovery, and speed up the launch of new business.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims4.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Your own design</strong></span><br><br><span class="fkr-set2 fs-10">Buildings can be designed in various ways depending on the layout and direction of the building Architects using the M3 integrated platform can use the design services provided by the platform to complete the design they want and experience virtual houses in advance. In addition, builders can adjust the total cost while choosing materials on the platform.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims5.png" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Reduction in maintenance costs</strong></span><br><br><span class="fkr-set2 fs-10">Each modular unit manufactured in the factory is made of energy efficient materials. Therefore, it has excellent energy saving effects and helps reduce building maintenance costs. In particular, due to the nature of the module assembly method, the gap between modules is secured, which is advantageous for heat shielding. In addition, modules can be moved, removed, or expanded if necessary, drastically reducing additional costs due to building changes.</span></div>
                        </div>
                    </div>
                    <div class="py-3"></div>
                    <div class="sw-1 sw-bx">
                        <div class="sw-2"><img src="@/assets/img/s_info/ims6.jpg" width="100%"></div>
                        <div class="sw-2 p-3">
                            <div class="py-3 text-left"><span class="fkr-set2 fs-16"><strong>Module-specific production and movement</strong></span><br><br><span class="fkr-set2 fs-10">The modular building, designed to be dismantled and moved, is built by assembling several modules, so if necessary, it can be dismantled on a module-by module basis without damage and can be moved to a place that the owner wants Accordingly, if it is necessary to demolish it, not only the cost of demolition but also the cost of rebuilding can be greatly reduced. This mobility is also an advantage that only modular houses have.</span></div>
                        </div>
                    </div>

                </div>

                <MenuFooter/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooterEn.vue'
export default {
    name: 'MenuModular',
    components: {
        MenuFooter
    },
    mounted() {
        function _class(name){
            return document.getElementsByClassName(name);
        }

        let tabPanes = _class("tab-header")[0].getElementsByTagName("div");

        for(let i=0;i<tabPanes.length;i++){
        tabPanes[i].addEventListener("click",function(){
            _class("tab-header")[0].getElementsByClassName("active")[0].classList.remove("active");
            tabPanes[i].classList.add("active");
            
            _class("tab-indicator")[0].style.top = `calc(80px + ${i*50}px)`;
            
            _class("tab-content")[0].getElementsByClassName("active")[0].classList.remove("active");
            _class("tab-content")[0].getElementsByTagName("div")[i].classList.add("active");
            
        });
        }

        var swiper = new window.Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 20,
        effect: 'fade',
        loop: true,
        speed: 300,
        mousewheel: {
            invert: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
        });

        console.log(swiper)
    }
}
</script>