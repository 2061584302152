<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-if="lang == 'ko'">
                    <span class="dh_p1b">사업현황</span><br>
                    <span class="dh_p1c">M3가 현재 진행하고있는 프로젝트들을 만나보세요.</span>
                </div>
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-else-if="lang == 'en'">
                    <span class="dh_p1b">Current Business</span><br>
                    <span class="dh_p1c">Get information about M3's recently proceeding projects.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <div class="p-3 pt-5">
                    <div style="border-bottom:1px solid #e9e9e9">
                        <div class="py-2 w-100">

                            <div class="py-2"><span class="fkr-set1 fs-12 fc-w10">{{ article.sbjct }}</span></div>
                            <div class="pb-2" style="border-bottom:1px solid #e9e9e9"><span class="fen-set2 fc-w3">{{ article.crtDt? article.crtDt.split('T')[0] : '' }}</span></div>
                            <div class="py-3">
                                <div class="px-2" style="line-height:180%">
                                    <img v-for="(path, index) in article.attchList" :key="index" :src="'https://s3.' + s3region + '.amazonaws.com/' + s3bucket + '/' + path" width="100%" style="margin-bottom: 10px;">
                                    <span class="fkr-set2 fs-09" v-html="article.cntnt"></span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div>
                        <div v-if="prev" class="d-flex px-4 py-3 ls_bg1" style="border-bottom:1px solid #e9e9e9">
                            <div><i class="fa-solid fa-arrow-up fs-095 fc-w10 pe-3"></i></div>
                            <div><a :href="'/menu/recentview/' + prev.recentId"><span class="fkr-set1">{{ prev.sbjct }}</span></a></div>
                        </div>
                        <div v-if="next" class="d-flex px-4 py-3 ls_bg1" style="border-bottom:1px solid #e9e9e9">
                            <div><i class="fa-solid fa-arrow-down fs-095 fc-w10 pe-3"></i></div>
                            <div><a :href="'/menu/recentview/' + next.recentId"><span class="fkr-set1">{{ next.sbjct }}</span></a></div>
                        </div>
                    </div>

                </div>

                <div>
                    <div class="x_div_center py-3"><button class="fkr-set1 px-4 py-2" style="width:120px; border:1px solid #e9e9e9" onclick="location.href='/menu/recentlist'">{{lang == 'ko'? '목록' : 'List'}}</button></div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
import Api from '@/api'

export default {
    name: 'RecentView',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            article: {
                recentId: 0,
                sbjct: '',
                cntnt: '',
                crtDt: '',
                attchList: []
            },
            prev: null,
            next: null,
            s3region: process.env.VUE_APP_S3_REGION,
            s3bucket: process.env.VUE_APP_S3_BUCKET
        }
    },
    async mounted() {
        var rtnval = await Api.post('https://buildmarket.kr/api/v1/recent/find/recent/dtl', {recentId: this.$route.params.recentId});
        if(rtnval.result) {
            this.article = rtnval.data;
        }
        else {
            alert(rtnval.errormsg)
        }

        var selectedTab = sessionStorage.getItem("recentCtgryTab");
        var tab = selectedTab? parseInt(selectedTab, 0) : null;
        rtnval = await Api.post('https://buildmarket.kr/api/v1/recent/find/recent/list', {ctgryId: tab, asc: false});
        if(rtnval.result) {
            var recentList = rtnval.data.resultList;
            recentList.forEach((article, index) => {
                if(article.recentId == this.$route.params.recentId) {
                    if(index > 0) {
                        this.prev = recentList[index-1];
                    }
                    if(index +1 < recentList.length) {
                        this.next = recentList[index+1];
                    }
                }
            })
        }
        else {
            alert(rtnval.errormsg)
        }
}
}
</script>