<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">Location of M3</span><br>
                    <span class="dh_p1c"></span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set" style="border:0px solid red">

                <!-- 오시는길 -->
                <div class="py-3" style="border-bottom:1px solid #e9e9e9">
<!--                    <div class="x_div_center"><span class="fkr-set2 fs-16"><strong>Directions</strong></span></div>-->
                    <!-- <div class="x_div_center py-3"><span class="fkr-set2 fs-10">A place to create your dreams and future</span></div> -->
                    <div class="x_div_center">
                        <div class="iw-1 px-2 py-2">
                            <img src="@/assets/img/s_info/info_map_en.jpg" width="100%">
<!--                            <div class="iw-4 py-2 px-4">-->
<!--                                <div>-->
<!--                                    <div class="fen-set1 fs-13 fc-w10">Headquarter</div>-->
<!--                                    <div class="fkr-set2 fs-09 pb-3">127-16, Sabanggongwon-gil, Cheongha-myeon, Buk-gu, Pohang-si, Gyeongsangbuk-do</div>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <div class="fen-set1 fs-13 fc-w10">Research Lab</div>-->
<!--                                    <div class="fkr-set2 fs-09 pb-3">Acrovisa L-410, 188 Jungang-ro, Seocho-gu, Seoul</div>-->
<!--                                    <div class="fkr-set2 fs-09">[Way to come]</div>-->
<!--                                    <div class="fkr-set2 fs-09">-->
<!--                                        <ol>-->
<!--                                            <li style="line-height:220%">-->
<!--                                                600 meters from Exit 6 of Gyodae station to Express Bus Terminal<br>-->
<!--                                                At the second traffic light (you can see SK Gas Station on the left)<br>-->
<!--                                                Turn right along Acrovisa Office Building and enter the underground<br>-->
<!--                                                parking lot in Sangga-dong<br>-->
<!--                                                4th floor (Woori Bank is located on the 1st floor) of the building on the left<br>-->
<!--                                                side of the main gate of Acrovista.-->
<!--                                            </li>-->
<!--                                            <li style="line-height:220%">-->
<!--                                                600 meters from Samho Garden Intersection next to Express Bus Terminal toward Kyodae Station<br>-->
<!--                                                At the third traffic light when driving (you can see SK Gas Station on the right)<br>-->
<!--                                            </li>-->
<!--                                            <li style="line-height:220%">-->
<!--                                                When you walk in,<br>-->
<!--                                                If you walk 600m from Exit 6 of the Kyodae Station toward Express Bus Terminal, you will find three acrobatic stars on your right. There is a tall building.It is located on the fourth floor of the building (A) after passing through the first high-rise residential building (C) and the second high rise residential building (B). (Woori Bank is located on the first floor of office building (A)).-->
<!--                                            </li>-->
<!--                                        </ol>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div><div class="fen-set1 fs-13 fc-w10">Modular Factory</div><div class="fkr-set2 fs-09">Majang-myeon, Icheon-si, Gyeonggi-do</div></div>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooterEn.vue'
export default {
    name: 'CompanyLocation',
    components: {
        MenuFooter
    }
}
</script>