<template>
    <div class="py-3" style="border-bottom:1px solid #e9e9e9; overflow-x:auto">
        <p class="fkr-set2 fs-13" style="padding-left:45px"><strong>다른 프로젝트 보기</strong></p>
        <div class="iw-1">
            <a v-if="isMobile" href="javascript:void(0)" @click="moveNav(1)" class="px-2" style="display: flex; justify-content: center;">
                <img src="@/assets/img/s_arrow_t.png" width="50px" height="50px">
            </a>
            <a v-else href="javascript:void(0)" @click="moveNav(0)" class="px-2" style="padding-top:120px">
                <img src="@/assets/img/s_arrow_l.png" width="50px" height="50px">
            </a>
            <div class="iw-5 px-3 py-2" v-for="(v, i) in navSlot" :key="i">
                <a class="w-100" :href="v.pageLink">
                    <img :src="v.imgSrc" width="100%" height="250">
                    <div class="x_div py-2 fkr-set1 fs-11">{{ v.title }}</div>
                    <div class="fkr-set2 fs-09">{{ v.description }}</div>
                </a>
            </div>
            <a v-if="isMobile" href="javascript:void(0)" @click="moveNav(0)" class="px-2" style="display: flex; justify-content: center;">
                <img src="@/assets/img/s_arrow_d.png" width="50px" height="50px">
            </a>
            <a v-else href="javascript:void(0)" @click="moveNav(1)" class="px-2" style="padding-top:120px">
                <img src="@/assets/img/s_arrow_r.png" width="50px" height="50px">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PortfolioNavigation',
    props: {
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            projects: [
                {
                    imgSrc: '/files/img/portfolio/thumbnails/thumb_sample6.jpg',
                    title: 'M Studio',
                    description: '정제된 모던함과 견고한 품질을 갖춘 농막입니다.',
                    pageLink: '/menu/portfolio/mstudio'
                },
                {
                    imgSrc: '/files/img/portfolio/thumbnails/thumb_sample4.png',
                    title: 'M House',
                    description: '생애주기 맞춤형 유니버셜 모듈 조합 단독주택입니다.',
                    pageLink: '/menu/portfolio/mhouse'
                },
                {
                    imgSrc: '/files/img/portfolio/thumbnails/thumb_sample1.jpg',
                    title: 'M Commercial',
                    description: '바다를 담은 감각적인 근린생활시설입니다.',
                    pageLink: '/menu/portfolio/prj01'
                },
                {
                    imgSrc: '/files/img/portfolio/thumbnails/thumb_sample2.jpg',
                    title: 'M Office',
                    description: '감각적인 전면 파사드 디자인의 모듈러 상업시설입니다.',
                    pageLink: '/menu/portfolio/shingaloffice'
                },
                {
                    imgSrc: '/files/img/portfolio/thumbnails/thumb_sample3.png',
                    title: 'M School',
                    description: '재조립, 재사용이 가능한 친환경 모듈러 학교입니다.',
                    pageLink: '/menu/portfolio/mschool'
                },
            ],
            navSlot: [],
            index: 0
        }
    },
    mounted() {
        this.setSlot();
    },
    methods: {
        setSlot() {
            this.navSlot = [];
            for(var i=0; i < 3; i++) {
                this.navSlot.push(this.projects[(this.index+i) % this.projects.length]);
            }
        },
        moveNav(direction) {
            if(direction) {
                this.index = (this.index + 1) % this.projects.length;
                this.setSlot();
            }
            else {
                if(this.index == 0) {
                    this.index = this.projects.length -1;
                }
                else {
                    this.index--;
                }
                this.setSlot();
            }
        }
    }
}
</script>