<template>
    <div>
        <div class="dh_p3a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">Process introduction</span><br>
                    <span class="dh_p1c">Introducing the M3 premium modular construction process.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- 프로세스 소개 -->
                <div class="py-3 pt-5">
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w11"><strong>STEP 1. Planning Process</strong></span></div>
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-13 fc-w10"><strong>This is how your realistic architecture proceeds.</strong></span></div>
                    <div class=" px-3 py-2"><span class="fkr-set1 fs-095">The steps for client to select the desired building which will be recommended by AI based on M3 Universal modular unit.</span></div>

                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">

                            <!-- 대지입력 -->
                            <div class="iw-3">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps-1.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Land input</span></div>
                                    <div class=""><span class="fkr-set2">Enter the Land address that the owner owns or is considering in the future.</span></div>
                                </div>
                            </div>
                            <!-- 건축설계 요건선택 -->
                            <div class="iw-3">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps-2.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Architectural design requirements selection</span></div>
                                    <div class="text-left w-100" style="word-break:break-all"><span class="fkr-set2 pb-2">Select the requirements necessary for building design, such as building use, exclusive area, actual requirement, building type, priority, and additional considerations.</span></div>
                                </div>
                            </div>

                        </div>
                        <div class="iw-3 d-flex" style="align-items:start;">

                            <!-- M3 플랫폼, 설계안 추천 -->
                            <div class="iw-3">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps-3.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">M3 platform, design recommendation</span></div>
                                    <div class="text-left w-100" style="word-break:break-all"><span class="fkr-set2 pb-2">Based on the requirements selected by the owner, we recommend the most appropriate plan by combining universal module units on the M3 platform.</span></div>
                                </div>
                            </div>
                            <!-- M3 플랫폼 담당자 지정 -->
                            <div class="iw-3">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps-4.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Project registration</span></div>
                                    <div class="text-left w-100" style="word-break:break-all"><span class="fkr-set2 pb-2">Based on the plan that owner chose, a new construction project is registered.</span></div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

        <div class="x_div_center py-3"><img src="@/assets/img/s_plan/ps_arrow_d.png" width="50"></div>

        <div class="x_div_center py-4" style="background-color:#f2f2f2">
            <!-- 설계 프로세스 -->
            <div class="rp_set">
                <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w11"><strong>STEP 2. Design Process</strong></span></div>
                <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13 fc-w10"><strong>Architectural design with experts goes like this.</strong></span></div>
                <div class="text-left px-3 py-2"><span class="fkr-set1 fs-095">It will be conducted step by step from planning and design review to designer selection.</span></div>
                <div class="iw-1 py-2 w-100">
                    <div class="iw-3 d-flex" style="align-items:start;">

                        <!-- 설계안 등록 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps2-1.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Design registration</span></div>
                                <div class="text-left" style="word-break:break-all"><span class="fkr-set2">We start the construction business by registering the contents decided by the owner during the planning stage.</span></div>
                            </div>
                        </div>
                        <!-- 건축사 입찰 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps2-2.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Bidding for an architect</span></div>
                                <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">The final design plan decided by the builder will be disclosed to the architectural designer who wants to participate in the bidding.</span></div>
                            </div>
                        </div>

                    </div>
                    <div class="iw-3 d-flex" style="align-items:start;">

                        <!-- 건축사 선정 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps2-3.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Selection of architects</span></div>
                                <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">The owner checks the portfolio of the architects who participated in the bidding, reviews the design costs and contents, seeds the architect, and proceeds with the contract.</span></div>
                            </div>
                        </div>
                        <!-- 본설계 진행 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps2-4.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Progress of the main design</span></div>
                                <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">The architectural designer faithfully reflects the architect's requirements and proceeds with the design. The details to be decided in detail are decided after consultation with the owner.</span></div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="x_div_center py-3"><img src="@/assets/img/s_plan/ps_arrow_d.png" width="50"></div>

        <div class="x_div_center">
            <div class="rp_set">

                <!--시공 프로세스 -->
                <div class="py-3 pt-5">
                    <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w11"><strong>STEP 3. Construction Process</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13 fc-w10"><strong>This is how M3 platform-based factory production and installation will proceed.</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set1 fs-095">It will be carried out step by step from open bidding for construction work to completion of construction work.</span></div>
                    <div class="iw-1 py-2 w-100">
                        <div class="iw-3 d-flex" style="align-items:start;">

                            <!-- 시공사 입찰 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps3-1.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Construction<br>Contractor bidding</span></div>
                                    <div class="text-left" style="word-break:break-all"><span class="fkr-set2">The contractor reviews the owner's requirements and the architect's design proposal and decides whether to participate in the bidding.</span></div>
                                </div>
                            </div>
                            <!-- 시공사 선정 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps3-2.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Selection<br>of contractors</span></div>
                                    <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">The owner refers to the contents of the corporate evaluation and portfolio of the contractors who participated in the bidding.M3 analyzes the estimate and helps you select a contractor and proceed with the contract.</span></div>
                                </div>
                            </div>

                        </div>
                        <div class="iw-3 d-flex" style="align-items:start;">

                            <!-- 공사 진행 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps3-3.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Construction<br>progress</span></div>
                                    <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">Owners can monitor construction delays and increased construction costs. The expert guide of M3 visits the construction site to review the progress and report it to the builder.</span></div>
                                </div>
                            </div>
                            <!-- 하자보수 -->
                            <div class="iw-3" style="align-items:start;">
                                <div class="p-4">
                                    <div class="x_div_center"><img src="@/assets/img/s_plan/ps3-4.png" width="90%"></div>
                                    <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center" >Defect<br>repair</span></div>
                                    <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">M3 provides prompt support to legally cope with disputes over defects on failure to repair defects according to the contract.</span></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>

        <div class="x_div_center py-3"><img src="@/assets/img/s_plan/ps_arrow_d.png" width="50"></div>

        <div class="x_div_center py-4" style="background-color:#f2f2f2">
            <!-- STEP 4. 유지보수 프로세스 -->
            <div class="rp_set">
                <div class="x_div_left px-3 py-2"><span class="fkr-set2 fs-16 fc-w11"><strong>STEP 4. Maintenance Process</strong></span></div>
                <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13 fc-w10"><strong>This is how the maintenance process proceeds.</strong></span></div>
                <div class="text-left px-3 py-2"><span class="fkr-set1 fs-095">If a defect occurs within a certain period of time after construction, the contractor will guide the repair company to proceed quickly.</span></div>
                <div class="iw-1 py-2 w-100">
                    <div class="iw-3 d-flex" style="align-items:start;">

                        <!-- 서비스 신청 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps4-1.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Application for service</span></div>
                                <div class="text-left" style="word-break:break-all"><span class="fkr-set2">If a defect occurs within a certain period of time after construction, please apply for the service on My Page of M3.</span></div>
                            </div>
                        </div>
                        <!-- 담당자 지정 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps4-2.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Appointment of person in charge</span></div>
                                <div class="text-left"><span class="fkr-set2 pb-2">M3 introduces a modular home repair specialist.</span></div>
                            </div>
                        </div>

                    </div>
                    <div class="iw-3 d-flex" style="align-items:start;">

                        <!-- 수리완료 -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"><img src="@/assets/img/s_plan/ps4-3.png" width="90%"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6 center">Completion of repair</span></div>
                                <div class="text-left" style="word-break:break-all"><span class="fkr-set2 pb-2">Once the repair is completed, the history will be recorded in the M3 system, so that it can be prevented in advance so that it will not expand into an issue in the event of future repair.</span></div>
                            </div>
                        </div>
                        <!--  -->
                        <div class="iw-3" style="align-items:start;">
                            <div class="p-4">
                                <div class="x_div_center"></div>
                                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w6"></span></div>
                                <div class="text-center"><span class="fkr-set2 pb-2"></span></div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="x_div_center">
            <div class="rp_set">
                <MenuFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooterEn.vue'
export default {
    name: 'MenuProcess',
    components: {
        MenuFooter
    }
}
</script>