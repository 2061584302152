import Introduction from '@/views/menu/introduction/Introduction.vue'
import Location from '@/views/menu/introduction/Location.vue'
import Modular from '@/views/menu/introduction/Modular.vue'
import Certificates from '@/views/menu/introduction/Certificates.vue'
import News from '@/views/menu/introduction/News.vue'
import RecentList from '@/views/menu/introduction/RecentList.vue'
import RecentView from '@/views/menu/introduction/RecentView.vue'
import ProjectsList from '@/views/menu/projects/ProjectsList.vue'
import ProjectsDetail from '@/views/menu/projects/ProjectsDetail.vue'
import Members from '@/views/menu/introduction/Members.vue'
import Organization from '@/views/menu/introduction/Organization.vue'
import Career from '@/views/menu/introduction/Career.vue'
import JoinPartner from '@/views/menu/partners/JoinPartner.vue'
import Partners from '@/views/menu/partners/Partners.vue'
// import BuildService from '@/views/menu/mybuilding/Service.vue'
// import Process from '@/views/menu/mybuilding/Process.vue'
// import SiteService from '@/views/menu/recommendsite/Service.vue'
// import FindSite from '@/views/menu/recommendsite/FindSite.vue'
// import ProvideSite from '@/views/menu/recommendsite/ProvideSite.vue'
// import FAQ from '@/views/menu/cs/FAQ.vue'
// import Question from '@/views/menu/cs/Question.vue'
import Download from '@/views/menu/cs/Download.vue'
import Portfolio from './portfolio'

export default [
    ...Portfolio,
    {
        path: '/menu/introduction',
        name: 'Introduction',
        component: Introduction
    },
    {
        path: '/menu/location',
        name: 'Location',
        component: Location
    },
    {
        path: '/menu/modular',
        name: 'Modular',
        component: Modular
    },
    {
        path: '/menu/certificates',
        name: 'Certificates',
        component: Certificates
    },
    {
        path: '/menu/news',
        name: 'News',
        component: News
    },
    {
        path: '/menu/recentlist',
        name: 'RecentList',
        component: RecentList
    },
    {
        path: '/menu/recentview/:recentId',
        name: 'RecentView',
        component: RecentView
    },
    {
        path: '/menu/projects/:menuId',
        name: 'ProjectsList',
        component: ProjectsList
    },
    {
        path: '/menu/projects/dtl/:recentId',
        name: 'ProjectsDetail',
        component: ProjectsDetail
    },
    {
        path: '/menu/members',
        name: 'Members',
        component: Members
    },
    {
        path: '/menu/organization',
        name: 'Organization',
        component: Organization
    },
    {
        path: '/menu/career',
        name: 'Career',
        component: Career
    },
    {
        path: '/menu/join-partner',
        name: 'JoinPartner',
        component: JoinPartner
    },
    {
        path: '/menu/partners',
        name: 'Partners',
        component: Partners
    },
    // {
    //     path: '/menu/build-service',
    //     name: 'Service',
    //     component: BuildService
    // },
    // {
    //     path: '/menu/process',
    //     name: 'Process',
    //     component: Process
    // },
    // {
    //     path: '/menu/site-service',
    //     name: 'SiteService',
    //     component: SiteService
    // },
    // {
    //     path: '/menu/find-site',
    //     name: 'FindSite',
    //     component: FindSite
    // },
    // {
    //     path: '/menu/provide-site',
    //     name: 'ProvideSite',
    //     component: ProvideSite
    // },
    // {
    //     path: '/menu/faq',
    //     name: 'FAQ',
    //     component: FAQ
    // },
    // {
    //     path: '/menu/question',
    //     name: 'Question',
    //     component: Question
    // },
    {
        path: '/menu/download',
        name: 'Download',
        component: Download
    },
]