<header>
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
</header>
<template>
    <div style="height: 1000px">
        <h2>Iframe Embed Test</h2>
        <br/>
        <iframe ref="ifrm" style="width:100%; height:100%; border:0px; border:1px solid #eee"
                src=""></iframe>
    </div>
</template>

<script>

export default {
    name: 'IframeTest',
    components: {},
    metaInfo: {
        title: 'IframeTest',
        meta: [
        {
            name: 'description',
            content: 'Welcome to Vue.js frontend.'
        }
        ]
    },
    data() {
        return {};  // <-- 오류방지 [Vue warn]: data functions should return an object
    },
    computed: {
    },
    mounted() {
        //------------ iframe 로딩 ---------------------------------------------------
        this.$refs.ifrm.src = "https://mobuild.cafe24.com/mobuild/m3systems/";
    },
    created() {

    },
    methods: {

    }
};
</script>