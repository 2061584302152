import MySamples from "@/components/test/Samples";
import KakaoLogIn from "@/components/test/KakaoLogin"
import LogOn from "@/components/test/LogOn";
import SomePage1 from "@/components/test/SomePage1";
import SomePage2 from "@/components/test/SomePage2";
import IframeTest from "@/components/test/IframeTest";
import FileUpload from "@/components/test/FileUpload";
import AddNum from "@/components/AddNum";

export default [
    {
    path: '/sample',
    name: 'MySample',
    component: MySamples
    },
    {
    path: '/kakao',
    name: 'KakaoLogIn',
    component: KakaoLogIn
    },
    {
    path: '/logon',
    name: 'LogOn',
    component: LogOn
    },
    {
    path: '/somePage1',
    name: 'SomePage1',
    component: SomePage1
    },
    {
    path: '/somePage2',
    name: 'SomePage2',
    component: SomePage2
    },
    {
    path: '/iframeTest',
    name: 'IframeTest',
    component: IframeTest
    },
    {
    path: '/fileupload',
    name: 'FileUpload',
    component: FileUpload
    },
    {
    path: '/add',
    name: 'Add Numbers',
    component: AddNum
    }
]