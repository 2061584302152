<template>
  <div class = "container">

    <h1 class = "text-center"> Kakao Log In</h1>
    <br/>
    <img src="@/assets/img/member/kakao_login_medium_narrow.png" @click="openKakaoLogin()"/>
    <br/><br/>
    <router-link to="/">뒤로 가기</router-link>
  </div>
</template>

<script>
import Auth from '@/api/auth'

export default {
  name: 'Kakao-Login',
  data(){
    return {
      email: '',
      password: '',
      samples: [],
      popup: null
    }
  },
  computed: {
  },
  watch: {
    popup: {
      handler: function() {
        console.log(this.popup);
      },
      deep: true
    }
  },
  methods: {
    openKakaoLogin(){
      var url = "https://kauth.kakao.com/oauth/authorize?client_id="+process.env.VUE_APP_KAKAO_OAUTH_CLIENT_ID+"&redirect_uri="+process.env.VUE_APP_KAKAO_OAUTH_REDIRECT_URI+"&response_type=code";
      var popup = window.open(url, 'Kakao Log In', 'width=400, height=600, scrollbars=yes');
      window.setInterval(function() {
        if(popup.closed){
          location.reload();
        }
      }, 1000)
    }
  },
  async created() {
    if(await Auth.isLogedIn()){
      this.$router.push('/LogOn');
    }
    console.log('created');
  }
}
</script>

<style scoped>

</style>