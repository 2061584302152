<template>
  <div>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="mh_p1"></div>
        <div class="carousel-caption d-md-block">
          <div class="mh_p1a" style="">
            <div class="text-start">
              <p class="fks-set1 fs-15 fc-w8"><strong>Fast but Reliable</strong></p>
              <!--p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>프리미엄 모듈러</strong></p-->
              <p class="mh_p1b">Premium modular architecture</p>
              <p class="mh_p1c">Your idea turns into a masterpiece.</p>
              <div class=""><div class="box-type-2 p-2 px-3 plan-shortcut" style="background-color: rgba(78, 0, 0, 0.3); position:relative" onclick="location.href='https://buildmarket.kr/plan'"><div style="display:flex"><h1 style="margin-top: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong style="position:absolute; right: 15px; bottom: 40%; font-size:large;">Learn more ></strong></div></div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
      <div class="mh_p2"></div>
        <div class="carousel-caption d-md-block">
          <div class="mh_p1a text-start">
            <p class="fks-set1 fs-15 fc-w8"><strong>State-of-the-art-technology</strong></p>
            <!--p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>사람중심 미래건축</strong></p-->
            <p class="mh_p1b">With human oriented architecture</p>
            <p class="mh_p1d">M3 leads the architectural paradigm.</p>
            <div class=""><div class="box-type-2 p-2 px-3 plan-shortcut" style="background-color: rgba(78, 0, 0, 0.3); position:relative" onclick="location.href='https://buildmarket.kr/plan'"><div style="display:flex"><h1 style="margin-top: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong style="position:absolute; right: 15px; bottom: 40%; font-size:large;">Learn more ></strong></div></div></div>
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <div class="mh_p3"></div>
        <div class="carousel-caption d-md-block">
          <div class="mh_p1a text-start">
            <p class="fks-set1 fs-15 fc-w8"><strong>Best Time-to-Market</strong></p>
            <!--p class="fks-set1" style="font-size:3rem; color:#fff; -webkit-text-stroke:1px #e7e7e7; text-shadow:1px 1px 1px #000;"><strong>M3 원스톱 서비스와 독보적인 기술로</strong></p-->
            <p class="mh_p1e">With M3's one-stop service and leading-edge technology,</p>
            <p class="mh_p1f">we support faster return on investment and an agile business of yours.</p>
            <div class=""><div class="box-type-2 p-2 px-3 plan-shortcut" style="background-color: rgba(78, 0, 0, 0.3); position:relative" onclick="location.href='https://buildmarket.kr/plan'"><div style="display:flex"><h1 style="margin-top: 15px"><i class="fa-solid fa-compass-drafting"></i></h1><strong style="position:absolute; right: 15px; bottom: 40%; font-size:large;">Learn more ></strong></div></div></div>
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>


  <div class="x_div_center">
    <div class="rp_set" style="border:0px solid red">

      <!-- M3 주요 서비스 -->
      <div class="py-3 pt-5">
        <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>M3 Main Services</strong></span></div>
        <div class="text-left px-3 py-3" style="padding-left: 0rem !important;"><span class="fkr-set1 fs-13">We provide high-quailty premium buildings quickly.</span></div>
        <div class="iw-1 py-2 w-100">
          <div class="iw-3 d-flex" style="align-items:start;">

            <!-- 특화 알고리즘, 나만의 디자인 -->
            <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:390px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma1-1.png" width="100%"></div>
                <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">Unique algorithms,<br>Your own design</span></div>
                <div class="text-center"><span class="fkr-set2 fc-w12">We propose an architectural model that responds to the client's needs with our own modular based algorithms.</span></div>
              </div>
            </div>
            <div class="p-2"></div>
            <!-- 전문가와 함께하는 설계 -->
            <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:390px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma1-2.png" width="100%"></div>
                <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">Design with<br>a specialist</span></div>
                <div class="text-center"><span class="fkr-set2 pb-2 fc-w12">Our professional support will save your efforts and time for your building design.</span></div>
              </div>
            </div>

          </div>
          <div class="p-2"></div>
          <div class="iw-3 d-flex" style="align-items:start;">

            <!-- 빠르고 정확한 모듈러 시공 -->
            <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:390px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma1-3.png" width="100%"></div>
                <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">Fast and accurate<br>modular construction</span></div>
                <div class="text-center"><span class="fkr-set2 pb-2 fc-w12">Once the design is completed, we will proceed the construction safely at a reasonable cost.</span></div>
              </div>
            </div>
            <div class="p-2"></div>
            <!-- 유지보수 지원 -->
            <div class="iw-3" style="align-items:start; border:1px solid #e1e1e1; border-radius:8px; height:390px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma1-4.png" width="100%"></div>
                <div class="text-center py-3"><span class="fkr-set1 fs-11 fc-w0">Maintenance<br>support</span></div>
                <div class="text-center"><span class="fkr-set2 pb-2 fc-w12">After the construction, we provide maintenance support for your convenience.</span></div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

  <div id="v2" class="x_div_center py-4" style="background-color:#000137">

    <!-- M3 소개영상 -->
    <div class="rp_set">
      <div class="iw-1 py-2 w-100 align-items-center">
        <div class="iw-3 align-items-center">
          <div class="p-3">
            <div>
              <div>
              <div class="py-3 text-left"><span class="fkr-set2 fs-13 fc-w1"><strong>M3 introductory video</strong></span></div>
              <div class="py-3 text-left"><span class="fs-20 fc-w1"><strong>Customized design through<br/>the AI-based modules combination technology.</strong></span></div>
                <div class="py-3 text-left"><span class="fs-10 fc-w1">M3 analyzes many designs and continuously develops unit level modules optimized for domestic needs. We offer the design that meets various needs through advanced digital design techniques that can be systematically combined on our own platform.</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="iw-3 align-items-center">
          <div class="p-2">
            <div>

              <iframe class="_img" id="img_w2022070500e900bfa62e1" src="https://www.youtube.com/embed/?loop=0&amp;rel=0&amp;playlist=ZA5bvTkEbgA&amp;showinfo=1&amp;controls=0&rel=0&amp;mute=0&amp;autoplay=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fm3systems.co.kr&amp;widgetid=1" style="display: block;  margin: 0px;" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="M3systems, 당신의 생각이 작품이 됩니다." width="100%" height="400" sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox" data-gtm-yt-inspected-4="true"></iframe>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div class="x_div_center">
    <div class="rp_set" style="border:0px solid red">

      <!-- M3 모듈러 건축 특장점 -->
      <div class="py-3" style="border-bottom:1px solid #e1e1e1">
        <div class="x_div_left py-3"><span class="fkr-set2 fs-16 fc-w10"><strong>M3 Modular Architecture Features</strong></span></div>
        <div>
          <div class="iw-1 py-2 w-100">
            <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px; background-color:#e8edf1">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma3-1.png" height="120"></div>
                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">High degree of completeness</span></div>
                <div class="text-center"><span class="fs-09 fc-w0">Produced with standardized materials through a strict quality control in an indoor environment of the factory, resulting in a high degree of perfection.</span></div>
              </div>
            </div>
            <div class="p-2"></div>
            <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma3-2.png" height="120"></div>
                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">Reduce construction cost</span></div>
                <div class="text-center"><span class="fs-09 fc-w0">Reduce design details and labor costs with rapid design and automated fabrication</span></div>
              </div>
            </div>
            <div class="p-2"></div>
            <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px; background-color:#e8edf1">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma3-3.png" height="120"></div>
                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">Shorten Construction Period</span></div>
                <div class="text-center"><span class="fs-09 fc-w0">Factory-fabricated modules are quickly assembled on site, dramatically reducing construction time</span></div>
              </div>
            </div>
          </div>
          <div class="iw-1 py-2 w-100">
            <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma3-4.png" height="120"></div>
                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">Your own design</span></div>
                <div class="text-center"><span class="fs-09 fc-w0">Various designs are possible at the initial design stage,and easy adjustment can be made to complete the design.</span></div>
              </div>
            </div>
            <div class="p-2"></div>
            <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px; background-color:#e8edf1">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma3-5.png" height="120"></div>
                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">Reduce maintenance costs</span></div>
                <div class="text-center"><span class="fs-09 fc-w0">With the use of energy efficient material and inter modules heat shielding, the cost of cooling and heating is reduced.</span></div>
              </div>
            </div>
            <div class="p-2"></div>
            <div class="iw-5" style="border:1px solid #e1e1e1; border-radius:8px">
              <div class="p-4">
                <div class="x_div_center"><img src="@/assets/img/main/ma3-6.png" height="120"></div>
                <div class="x_div_center py-3"><span class="fkr-set1 fs-12 fc-w0">Moveable</span></div>
                <div class="text-center"><span class="fs-09 fc-w0">The constructed building can be disassembled in units of modules to be moved and expanded later.</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 고객 사례 -->
      <div class="py-3">
        <div class="x_div_left py-3"><span class="fkr-set2 fs-16 fc-w10"><strong>Customer cases</strong></span></div>

        <div class="">

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="slider-item swiper-slide">
                <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/main/mc-1.png" alt="SliderImg"></div>
                <div class="slider-item-content">
                  <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">Hogeun-dong Neighborhood Living Facility </span></div>
                  <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">ModuleX Series A - "Capture the Sea"</span></div>
                  <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">Introducing Modulex Commercial, Series A, which reduces construction time and minimizes construction costs by applying M3 Systems' DfMA modular construction technology. It is designed with the concept of sea, sky, and weather in two frames.  It opens between office and café to consider the building behind it and connects the movement lines to the rooftop space with open stairs, holding an ocean view, and providing a space to communicate with the surrounding context.</span></div>
                </div>
              </div>
              <div class="slider-item swiper-slide">
                <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/main/mc-2.png" alt="SliderImg"></div>
                <div class="slider-item-content">
                  <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">Neighborhood Living Facilities in Singal-dong, Gyeonggi-do</span></div>
                  <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">ModuleX Series B - "Out of the Box" </span></div>
                  <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">Introducing the ModuleX Commercial Series B designed by M3 Systems' DfMA modular architecture technology. The facade is curved atypical, creating unique facades and an open public space design that provides office space for collaboration, creativity, work flexibility, and innovative ideas. The roof floors' biophillic design provides users with a spce to recharge and unwind through the abundance of the nature.</span></div>
                </div>
              </div>
              <div class="slider-item swiper-slide">
                <div class="slider-image-wrapper"><img class="slider-image" src="@/assets/img/main/mc-3.png" alt="SliderImg"></div>
                <div class="slider-item-content">
                  <div class="px-4 pt-3"><span class="fkr-set1 fs-15 fc-w0">A detached house in Seosan, Chungnam</span></div>
                  <div class="px-4 py-2"><span class="fkr-set1 fs-12 fc-w12">ModuleX Series H - "Memories Storage"</span></div>
                  <div class="px-4" style="line-height:170%"><span class="fkr-set2 fs-09 fc-w0">There are two types of detached houses based on DfMA, consisting of houses 1 and 2.   House No. 1 is a wide open ceiling high-lighted, well-organized indoor floor plan that respects individual lifestyles, offering three independent rooms. It is designed in consideration of convenience with a dress room and storage cabinet.  House No. 2 offers an open living room and dining room as a green space so that you can recharge and enjoy to the fullest. we provide an alpha room that can be used for various purposes on the 1.5 floor space.</span></div>
                </div>
              </div>
            </div>

            <div style="height:40px"></div>

            <div class="swiper-pagination"></div>
          </div>

        </div>

      </div>

      <MenuFooter/>

      <div id="announcePop" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <img src="@/assets/img/popup_logo.png" style="float:right; width: 30%">
              <br><br/><br/>
              <div class="text-center">
                <span class="fw-bold" style="color:#4e0000; font-size: large;">M3 Systems 모듈러 교실<br/>'M스쿨' 조달청 혁신시제품 선정</span>
                <br><br/>
                설계단계부터 BIM 기술이 적용된<br/>
                모듈러 교실 브랜드 <b>'M스쿨'</b><br/>
                사람중심 미래건축 M3가<br/>
                건축패러다임을 앞당깁니다.
                <br><br/>
                <a href="http://www.ikld.kr/news/articleView.html?idxno=267328" target="_blank"><u>관련기사 바로가기</u></a>
                <br><br/>
              </div>
            </div>
            <div class="modal-footer" style="background-color:#4e0000">
              <input type="checkbox" name="oneDayCheck" id="oneDayCheck" v-model="oneDayCheck" style="display: block"><label for="oneDayCheck" style="color: #fff">하루동안 보지 않기</label>
              <a href="javascript:void(0)" @click="closeModal()" style="color: #fff" data-dismiss="modal">&times;</a>
            </div>
          </div>
        </div>
      </div>

      <div id="eventPop" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index:100000001;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <img src="@/assets/img/popup_logo.png" style="float:right; width: 30%">
              <br><br/><br/>
              <div class="text-center event-body" style="height:350px; padding-top: 100px">
                <span class="fw-bold" style="color:#4e0000; font-size: large;">M3 구독/팔로우 이벤트<br/>참여에 감사드립니다!</span>
                <br><br/>
                고객님의 성원에 최고의 제품과<br/>
                최선의 서비스로 보답하는<br/>
                모듈러 건축 전문 기업<br/>
                M3시스템즈가 될 것을 약속드립니다.
                <br><br/>
                <span class="fkr-set2 fs-10">
                  <b>아래 입력란에 휴대폰 번호를 입력 후<br><span style="color:#4e0000;">커피쿠폰 받기</span> 버튼을 눌러주세요!</b>
                </span>
                <br><br/>
              </div>
            </div>
            <div style="background-color:#4e0000">
              <div class="x_div_center py-4">
                <input type="number" class="form-control fkr-set2 fc-w5" name="eventPhoneHead" id="eventPhoneHead" v-model="eventPhoneHeadInput" style="display: block; width:100px" disabled><span style="color:#fff; padding:10px">-</span>
                <input type="number" class="form-control fkr-set2 fc-w5" name="eventPhoneMid" id="eventPhoneMid" v-model="eventPhoneMidInput" style="display: block; width:100px"><span style="color:#fff; padding:10px">-</span>
                <input type="number" class="form-control fkr-set2 fc-w5" name="eventPhoneTail" id="eventPhoneTail" v-model="eventPhoneTailInput" style="display: block; width:100px">
              </div>
              <div class="x_div_center py-4">
                <div class="px-2"><button class="x_div_center" @click="closeEvent()" style="width:120px; height:30px; border:0; background-color:#fff; border:1px solid #4e0000; border-radius:4px; color:#4e0000"><span class="fkr-set1">커피쿠폰 받기</span></button></div>
                <div class="px-2"><button class="x_div_center" style="width:120px; height:30px; border:0; background-color:#4e0000; border:1px solid #fff; border-radius:4px; color:#fff" data-dismiss="modal"><span class="fkr-set1">닫기</span></button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Api from '@/api'
import MenuFooter from '@/components/MenuFooterEn.vue'

export default {
  name: 'HomePage',
  props: {
    lang: {
        type: String,
        default: 'ko'
    }
  },
  components: {
    MenuFooter
  },
  data() {
    return {
      oneDayCheck: false,
      eventPhoneHeadInput: '010',
      eventPhoneMidInput: '',
      eventPhoneTailInput: ''
    }
  },
  watch: {
    lang(val) {
      if(val == 'ko') {
        location.href = '/'
      }
    }
  },
  async created() {
    if(this.lang == 'ko') {
      location.href = '/'
    }
  },
  async mounted() {
    // eslint-disable-next-line
    var swiper = new window.Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 20,
      effect: 'fade',
      loop: true,
      speed: 300,
      mousewheel: {
        invert: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
      },
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });

    if(this.$route.query.event) {
      window.$('#eventPop').modal('show');
    }
    
    const timestamp = localStorage.getItem('modalCheck');
    const date = new Date();
    if(!timestamp || (date.getTime() - Number(timestamp)) > 24*60*60*1000){
      if(!this.loading) {
        // window.$('#announcePop').modal('show');
      }
    }
  },
  methods: {
    async closeEvent() {
      if(!this.eventPhoneMidInput || !this.eventPhoneTailInput) {
        alert('휴대폰 번호를 정확히 입력하세요.');
        return;
      }

      var returnVal = await Api.post('/api/v1/common/send/mms/event', {phone: this.eventPhoneHeadInput + this.eventPhoneMidInput + this.eventPhoneTailInput, eventCode: 1});
      if(returnVal.result) {
        alert('쿠폰 ' + returnVal.data.numOfMms + '장이 정상적으로 지급되었습니다.\n휴대폰을 확인해주세요.');
      }
      else {
        alert(returnVal.errormsg);
      }
    },
    closeModal() {
      if(this.oneDayCheck){
        var date = new Date();
        localStorage.setItem('modalCheck', date.getTime());
      }
      // window.$('#announcePop').modal('hide');
    }
  }
}
</script>

<style>
.event-body {
    background-image: url('@/assets/img/event/230517_eventPopBg.jpg');
    background-size: 100%;
}

@media (max-width:1023.98px) {
  .plan-shortcut {
    width:180px;
    height:80px;
  }
}

@media (min-width:1024px) {
  .plan-shortcut {
    width:200px;
    height:100px;
  }
}
</style>