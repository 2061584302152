<template>
  <div id="app">
    <Header :user="user" :lang="lang" :isMobileDevice="isMobileDevice" @toggleLanguage="toggleLanguage"/>
    <router-view :user="user" :lang="lang" :isMobileDevice="isMobileDevice"/>
    <Footer v-if="lang == 'ko'"/>
    <Footer_En v-else-if="lang == 'en'"/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Footer_En from '@/components/FooterEn.vue'
import { mapState } from 'vuex'
//
export default {
  name: 'App',
  components: {
    Header, Footer, Footer_En
  },
  data() {
    return {
      lang: 'ko',
      isMobileDevice: false
    }
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    document.title = (process.env.NODE_ENV == 'production'? '' : '[개발용]') + '주식회사 엠쓰리시스템즈';
    this.lang = navigator.language.indexOf('ko') >= 0? 'ko' : 'en';
    var filter = "win16|win32|win64|mac|macintel";
    if (navigator.platform) {
        // mobile 접속
        if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
            this.isMobileDevice = true;
        }
        // pc 접속
        else {
            this.isMobileDevice = false;
        }
    }


    const lang = localStorage.getItem('lang');
    if(lang) {
        this.lang = lang;
    }
  },

  
  methods: {
    toggleLanguage(language) {
      this.lang = language;
      localStorage.setItem('lang', language);
    }
  }
}
</script>

<style>
:root {
  --main-color: #000137;
  --sub-color: #B1DBF1;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
