<template>
    <div>
        <div class="dh_p2a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;">
                    <span class="dh_p1b">About M3 Partners</span><br>
                    <span class="dh_p1c">A New Perspective on Modular Architecture Business
        M3 supports the growth of partners.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- 파트너 혜택 -->
                <div class="py-3 pt-5" style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>Partner Benefit</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13"><strong>M3 is designed to help partners succeed in their business.
        We offer a variety of support programs.</strong></span></div>
                    <div>
                        <div class="iw-1 py-2 w-100">
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 사업지원 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa1.png" height="100"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">01.</span><span class="fkr-set1 fs-12">Business support</span></div>
                                        <div class="x_div_center">
                                            <ul>
                                                <li class="fkr-set2 fc-w4 pb-2">Discovery of joint projects</li>
                                                <li class="fen-set2 pb-2">M3 Platform Service</li>
                                                <li class="fkr-set2">Discounts and incentives</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 마케팅지원 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa2.png" height="100"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">02.</span><span class="fkr-set1 fs-12">Marketing support</span></div>
                                        <div class="x_div_center">
                                            <ul style="padding-bottom:30px">
                                                <li class="fkr-set2 pb-2">Customer marketing support</li>
                                                <li class="fkr-set2">Partner companies promotion</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 기술지원신청 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa3.png" height="100"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">03.</span><span class="fkr-set1 fs-12">Application for technical support</span></div>
                                        <div class="x_div_center">
                                            <ul>
                                                <li class="fkr-set2 pb-2">Partner technical support</li>
                                                <li class="fkr-set2 pb-2">Technical seminar</li>
                                                <li class="fkr-set2">Provide technical manual assets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 교육지원 -->
                                <div class="iw-3" style="align-items:start;">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pa4.png" height="100"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">04.</span><span class="fkr-set1 fs-12">Educational support</span></div>
                                        <div class="x_div_center">
                                            <ul style="padding-bottom:30px">
                                                <li class="fe-set2 pb-2">M3 Platform Training and  Education</li>
                                                <li class="fkr-set2">Hands-on education</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div style="height:80px"></div>

                <!-- 파트너 유형 -->
                <div style="border-bottom:1px solid #e1e1e1">
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>Partner Type</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13"><strong>M3 is based on BIM and DfMA platforms.<br>
        We will create modular architecture with partners in each industry.</strong></span></div>
                    <div class="">
                        <div class="iw-1 py-2">
                            <!-- 건축설계사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk1.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>Architect</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">Partners who plan and design various buildings based on modular units for customers who need modular architecture services.</span></div>
                                    </div>
                                </div>

                            </div>
                            <div class="sw-b"></div>
                            <!-- 모듈러 제작사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk2.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>Modular manufacturer</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">Partners who construct 70-80% of the entire process, including structural modules, floors, ceilings windows, electricity, and equipment in factory and assemble them on site.</span></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="iw-1 py-2">
                            <!-- 시공사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk3.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>Contractor</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">Partners who manage new construction projects based on a wide range of knowledge and skills in manufacturing production management methods as well as construction business management methods.</span></div>
                                    </div>
                                </div>

                            </div>
                            <div class="sw-b"></div>
                            <!-- 인테리어사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk4.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>Interior Designer Decorator</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">Partners who design buildings according to the customer's requirements for interior service and budget.</span></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="iw-1 py-2">
                            <!-- 자재사 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk5.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>Material Providers</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">Partners who provide various materials suitable for modular architecture.</span></div>
                                    </div>
                                </div>

                            </div>
                            <div class="sw-b"></div>
                            <!-- 운송 및 중장비업체 -->
                            <div class="iw-3 x_div_center">

                                <div class="iw-3 x_div_center" style="background-color:#ededed; height:220px">
                                    <div class="x_div_center"><img src="@/assets/img/s_partner/pk6.png" width="100%"></div>
                                </div>
                                <div class="iw-3">
                                    <div class="p-4">
                                        <div><span class="fkr-set2 fs-14"><strong>Transportation and heavy equipment manufacturers</strong></span></div>
                                        <div class="py-3"><span class="fkr-set2">Partners who transports the manufactured modular from the factory to the site and places the modular transported to the site at the correct location without error.</span></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div style="height:80px"></div>

                <!-- 파트너 등록절차 -->
                <div>
                    <div class="x_div_left"><span class="fkr-set2 fs-16 fc-w10"><strong>Partner Registration Procedure</strong></span></div>
                    <div class="text-left px-3 py-2"><span class="fkr-set2 fs-13"><strong>Welcome to M3 partners<br>
        The procedure for registering as a partner is as follows.</strong></span></div>
                    <div>
                        <div class="iw-1 py-2 w-100">
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 신청 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr1.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">01.</span><span class="fkr-set1 fs-13">Application</span></div>
                                        <div>
                                            <ul>
                                                <li class="fkr-set2 pb-3">If you apply for partner participation, we will send you a confirmation email to the email address you entered. </li>
                                                <li class="fkr-set2">When applying for participation, please fill out only the contents that can be disclosed, excluding content that pertains to your company's trade secrets, confidential matters and various intellectual property rights.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 양사협의 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr2.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">02.</span><span class="fkr-set1 fs-13">Agreement between the two Companies</span></div>
                                        <div>
                                            <ul class="pb-4">
                                                <li class="fkr-set2 pb-3">Based on your application, we will discuss partnership cooperation between the two companies. </li>
                                                <li class="fkr-set2 pb-3">The materials shared for consultation between the two companies are used only for operation purposes. </li>
                                                <li class="fkr-set2">We proceed with mutual consultation on all matters for partner alliance.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="iw-3 d-flex" style="align-items:start;">

                                <!-- 계약 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr3.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">03.</span><span class="fkr-set1 fs-13">Contract</span></div>
                                        <div>
                                            <ul>
                                                <li class="fkr-set2 pb-3">Based on your application, we will discuss partnership cooperation between the two companies.</li>
                                                <li class="fkr-set2">Contracts are concluded online/offline.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- 등록 -->
                                <div class="iw-3 pb-4">
                                    <div class="p-4">
                                        <div class="x_div_center"><img src="@/assets/img/s_partner/pr4.png" height="70"></div>
                                        <div class="x_div_center py-3"><span class="fen-set1 fs-13 fc-w10">04.</span><span class="fkr-set1 fs-13">Registration</span></div>
                                        <div>
                                            <ul class="pb-4">
                                                <li class="fkr-set2 pb-3">After signing the contract, the partner introduction content and other materials will be posted on the portal.</li>
                                                <li class="fkr-set2 pb-3">A representative will be assinged by M3 to provide technical and marketing support</li>
                                                <li class="fkr-set2">A "Partner Portal" for Partners is available.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div style="height:50px"></div>

            </div>
        </div>

        <div class="x_div_center" style="background-color:#000137; height:200px">

            <div>
                <div class="px-2"><span class="fkr-set1 fs-13 fc-w1">We look forward to your participation<br>
        who wants to grow with M3.</span></div>
                <div class="text-center"><br/><i class="fa-solid fa-envelope fs-12 fc-w1 pe-2"></i> <span class="fkr-set1 fs-10 fc-w1"> Partner participation inquiry <br/>[ <span class="fen-set1 fs-10" style="color:#e5ab11">info@m3systems.co.kr</span> ]</span></div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuJoinPartner'
}
</script>