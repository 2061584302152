<template>
    <div style="margin-top:100px;padding:10px;">
        <h1>Add</h1>

        <label for="num1">a : </label>
        <input type="number" id="num1" v-model="a">
        <br>
        <label for="num2">b : </label>
        <input type="number" id="num2" v-model="b">
        <br>
        <button style="background-color: #4b0909; color: white; margin:10px; padding: 10px 20px; font-size: 16px; border-radius: 5px; border: none; cursor: pointer; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); transition: background-color 0.3s ease;" @click="onClick">Add</button>
        <h2 >Result: {{ res }}</h2>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            a: 0,
            b: 0,
            res: 0
        }
    },
    computed: {
        result() {
            return parseInt(this.a) + parseInt(this.b);
        }
    },
  
    methods: {
        
        onClick() {
            this.updateResults()

        },
        updateResults() {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "a": this.a,
                "b": this.b
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            var self = this;
            fetch("/api/v1/addapi/add", requestOptions)
                .then(response => response.json())
                .then(result => {self.res = result.sum
                    this.$forceUpdate();    
                })
                .catch(error => console.log('error', error));

        }

    }
}
</script>
  
<style scoped>
h1 {
    color: blue;
}

h2 {
    color: green;
}
</style>
  