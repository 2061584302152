import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        token: ''
    },
    mutations: {
        setUserInfo(state, user) {
            state.user = user;
        },
        setToken(state, token) {
            state.token = token;
        }
    },
    plugins: [
        createPersistedState()
    ]
});