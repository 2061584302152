<template>
  <div class="ca_main">
      <div class="x_div_center">
          <div class="pg_stage">
            <div style="height:50px"></div>        
            <div class="x_div_center py-5">
                <div class="text-center">
                    <span class="fkr-set2 fs-10">
                      <span class="fw-bold" style="color:#4e0000; font-size: larger;">내부 서버 오류입니다.</span><br><br/>
                      <a href="/">Home으로 이동</a><br>
                    </span>
                </div>
            </div>
            <div style="height:50px"></div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "InternalServerError"
}
</script>

<style scoped>

</style>