<template>
    <div>
        <div class="dh_p1a w-100">
            <div class="x_div_center" style="height:100%; background-color:rgba(0, 0, 0, 0.3);">
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-if="lang == 'ko'">
                    <span class="dh_p1b">프로젝트</span><br>
                    <span class="dh_p1c">M3가 현재 진행하고있는 프로젝트들을 만나보세요.</span>
                </div>
                <div class="rp_set px-3" style="padding:120px 0 0 0;" v-else-if="lang == 'en'">
                    <span class="dh_p1b">Projects</span><br>
                    <span class="dh_p1c">Get information about M3's recently proceeding projects.</span>
                </div>
            </div>
        </div>

        <div class="x_div_center">
            <div class="rp_set">

                <!-- FAQ -->
                <div class="w-100 py-3 pt-5">
                    <div class="x_div_center pb-3"><span class="fkr-set2 fs-16 fc-w10"><strong>{{ lang == 'ko'? menuName : menuNameEn }}</strong></span></div>

                    <div class="x_div_end" v-if="!isMobile">
                        <a v-if="!isMobile" :href="proxyUrl + '?redirect=' + encodeURIComponent('/admin/site/recent-list?projectId=0&menuId=' + $route.params.menuId)" target="_blank">
                            <span class="fkr-set1" style="margin-right: 15px;">
                                <i class="fas fa-pen"></i>
                            </span>
                        </a>
                    </div>

                    <div class="pg_m_set" v-if="fetching" style="padding: 20px">
                        <div class="x_div_center"><h3><i class='fa fa-spinner fa-spin'></i></h3></div>
                    </div>
                    <div v-else>
                        <div class="py-3">
                            <div style="border-top:1px solid #e7e7e7">
                                <div v-for="(project, index) in projectList" :key="index">
                                    <a :href="'#mfaq'+index" data-bs-toggle="collapse" role="button" aria-expanded="false" :aria-controls="'mfaq'+index">
                                        <div class="p-3 ls_bg1" style="display:flex; justify-content: space-between;">
                                            <span class="fkr-set1"><button class="fkr-set1 fc-w1" :style="'width:100px; height:26px; border:0; padding-top:0; margin-right:10px; background-color:hsl(' + (project.ctgry.id * 110)%360 + ', 100%, 30%)'" disabled>{{ lang == 'ko'? project.ctgry.ctgryName : project.ctgry.ctgryNameEn }}</button>{{project.sbjct}}</span>
                                            <span class="fkr-set2" style="padding-top: 2px;">{{project.crtDt.split('T')[0]}}
                                                <i class="fas fa-pen" v-if="!isMobile" style="margin-left: 15px" @click="redirectLink(proxyUrl + '?redirect=' + encodeURIComponent('/admin/site/recent-edit?projectId=' + project.recentPackageId))"/>
                                                <i class="fa fa-edit" v-if="!isMobile" style="margin-left: 15px" @click="redirectLink(proxyUrl + '?redirect=' + encodeURIComponent('/admin/site/recent-list?projectId=' + project.recentPackageId))"/>
                                            </span>
                                        </div>
                                    </a>
                                    <div :id="'mfaq'+index" class="collapse p-3" v-for="(recent, kintex) in project.recentList" :key="kintex" style="background-color:#f2f2f2; border-bottom:1px solid #e1e1e1">
                                        <a :href="'/menu/projects/dtl/' + recent.recentId + '?menuId=' + $route.params.menuId"><span class="fkr-set2">{{ recent.sbjct }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MenuFooter v-if="lang == 'ko'"/>
                <MenuFooter_En v-else-if="lang == 'en'"/>

            </div>
        </div>
    </div>
</template>

<script>
import MenuFooter from '@/components/MenuFooter.vue'
import MenuFooter_En from '@/components/MenuFooterEn.vue'
import Api from '@/api'

export default {
    name: 'ProjectsList',
    props: {
        lang: {
            type: String,
            default: 'ko'
        }
    },
    components: {
        MenuFooter, MenuFooter_En
    },
    data() {
        return {
            fetching: true,
            isMobile: false,
            projectList: [],
            menuName: '',
            menuNameEn: '',
            buildMode: process.env.NODE_ENV,
            proxyUrl: (process.env.NODE_ENV == 'local'? 'http://localhost:1025' : (process.env.NODE_ENV == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/proxypage'
        }
    },
    async created() {
        this.fetchProjectList();
        this.fetching = false;
    },
    mounted() {
        this.isMobile = window.innerWidth < 768;
    },
    methods:{
        async fetchProjectList() {
            this.fetching = true;
            this.projectList = [];
            const rtnval = await Api.post((this.buildMode == 'local'? '' : (this.buildMode == 'development'? 'https://platform.buildmarket.kr' : 'https://buildmarket.kr')) + '/api/v1/recent/find/project/list', {recentMenuId: this.$route.params.menuId, asc: false});
            if(rtnval.result) {
                this.projectList = rtnval.data.resultList;
                if(this.projectList.length >0) {
                    this.menuName = this.projectList[0].menu.menuName;
                    this.menuNameEn = this.projectList[0].menu.menuNameEn; 
                }
            }
            else {
                alert(rtnval.errormsg)
            }
            this.fetching = false;
        },
        redirectLink(link) {
            window.open('about:blank').location.href = link;
        }
    }
}
</script>